import React from 'react';
import ReactDOM from 'react-dom';
import { PropTypes } from 'prop-types';
import styled from 'styled-components';
import Constants from '../../submodules/logictry_config/constants';
import Navigation from '../../services/Navigation';
import WindowSize from '../../services/WindowSize';
import UserCache from '../../services/cache/UserCache';
import UserProfileHeader from '../UserProfileHeader';
import Popup from '../../services/Popup';
import { ArrowDownIcon, ArrowUpIcon, DeleteIcon } from '../../styledhtml/Icon';
import ConfirmDialog from '../ConfirmDialog';

const Wrapper = styled.div`
  display: flex;
`;

const App = styled.div`
  cursor: pointer;
  > div:last-child {
    width: 100%;
    > button {
      width: 3rem;
      color: white;
      text-align: center;
      margin-right: 1rem;
      font-size: ${Constants.SmallFontSize};
      i {
        padding-right: 0.25rem;
      }
    }
  }
`;
const AppSort = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;
  > div {
    width: 16px;
    height: 16px;
  }
`;
const AppImage = styled.div`
  width: 100%;
  padding-bottom: 56%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  overflow: hidden;
`;
const Information = styled.div`
  > h3 {
    -webkit-line-clamp: 2;
    display: box;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: normal;
  }
  > p {
    -webkit-line-clamp: 4;
    display: box;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: normal;
  }
`;

export default class LogicBaseAppList extends React.PureComponent {
  static propTypes = {
    apps: PropTypes.array,
    listView: PropTypes.bool,
    small: PropTypes.bool,
    collection: PropTypes.string,
    allowSorting: PropTypes.bool,
    horizontal: PropTypes.bool,
    wrap: PropTypes.bool,
    showCreateNew: PropTypes.bool,
    showHoverInformation: PropTypes.bool,
    onCreateNew: PropTypes.func,
  }
  state = {
    menuOpen: false,
    appId: Navigation.currentLocation[2],
    q: Navigation.currentLocation[1] === 'search' && Navigation.currentLocation[2] && unescape(Navigation.currentLocation[2]) || Navigation.currentSearch.q || '',
    collectionParam: Navigation.currentSearch.collection,
    refs: {},
    confirmRemoveApp: false,
  }
  componentDidMount() {
    UserCache.onStateUpdate(this);
    WindowSize.onStateUpdate(this);
    Popup.onStateUpdate(this);
  }
  componentWillUnmount() {
    UserCache.offStateUpdate(this);
    WindowSize.offStateUpdate(this);
    Popup.offStateUpdate(this);
  }
  appCard = (app, i, showInformation) => {
    const { mobile, tablet } = WindowSize;
    const { apps, listView, small, collection, allowSorting, showHoverInformation } = this.props;
    const { collectionParam, appId } = this.state;
    const outerStyle = { display: 'flex', flexDirection: 'column', flex: 0 };
    const { _id, title, description, background, owner } = app;
    const isCurrentApp = appId === _id;
    const onclick = () => {
      if (Constants.isWidget) {
        const params = new URLSearchParams(window.location.search);
        if (collectionParam) params.set('collection', collectionParam);
        Navigation.navigate(`${Navigation.apps}/${_id}?${params.toString()}`);
      } else {
        const relativeUrl = collectionParam && `${Navigation.apps}/${_id}?collection=${collectionParam}`
          || collection && `${Navigation.apps}/${_id}?collection=${collection}`
          || `${Navigation.apps}/${_id}`;
        if (!Constants.isLogicWiki) {
          Navigation.site(`https://logictry.com${Navigation.apps}/${_id}`);
        } else {
          if (!isCurrentApp) Navigation.push(relativeUrl, collectionParam && {
            'logicbaseapp_related_vertical': window.history.state[`logicbaseapp_related_vertical`] || 0,
            'logicbaseapp_related_horizontal': window.history.state[`logicbaseapp_related_horizontal`] || 0
          });
        }
      }
    }
    const backgroundImage = background && background.image && `url("${background.image}") center / cover no-repeat` || null;
    const innerStyle = {
      color: 'white', fontWeight: 500, position: 'relative',
      background: backgroundImage || background && background.color || 'linear-gradient(45deg, #1ebd53, #4edc82)' || null,
      backgroundPosition: 'cover',
      height: small && (mobile && 90 || 100) || (tablet && 135 || 157.5),
      width: ((small && (mobile && 160 || 178) || (tablet && 240 || 280)) * (showInformation && 1.2 || 1)),
      paddingBottom: listView && 'unset',
      flexShrink: listView && 0,
      borderRadius: !showInformation && '0.5rem'
    }
    const users = showInformation && UserCache.getUsersByIds([owner]);
    return (
      <App
        key={_id}
        style={{
          ...outerStyle,
          flexDirection: allowSorting ? 'row' : 'column',
          filter: isCurrentApp && 'brightness(0.5)',
          gap: allowSorting && '1rem' || null
        }}
        ref={(e) => {
          this.state.refs[_id] = e;
        }}
        onMouseEnter={() => {
          if (!showHoverInformation) return;
          if (mobile) return;
          Popup.setApp(_id, collection);
        }}
        onMouseLeave={() => {
          Popup.clearApp(_id, collection);
        }}
      >
        {allowSorting && <AppSort>
          {i > 0 && <ArrowUpIcon
            onClick={() => { apps.splice(i, 1); apps.splice(i - 1, 0, app); this.forceUpdate(); }}
          />}
          {i < apps.length - 1 && <ArrowDownIcon
            onClick={() => { apps.splice(i, 1); apps.splice(i + 1, 0, app); this.forceUpdate(); }}
          />}
        </AppSort>}
        <AppImage
          style={innerStyle}
          onClick={onclick}
        >
          {!(background && background.image) && <div style={{
            position: 'absolute',
            inset: small && '0.5rem' || '1rem',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            overflow: 'hidden',
            textAlign: 'center'
          }}>
            <div style={{
              fontSize: (small && (showInformation ? '0.9rem' : '0.75rem') || (showInformation ? '1.2rem' : '1rem')),
              WebkitLineClamp: tablet && '4' || '5',
              WebkitBoxOrient: 'vertical',
              textOverflow: 'ellipsis',
              display: '-webkit-box',
              overflow: 'hidden',
            }}>{title && (title.text.charAt(0).toUpperCase() + title.text.slice(1))}</div>
          </div>}
        </AppImage>
        {allowSorting && <AppSort>
          <DeleteIcon
            onClick={() => { this.setState({ confirmRemoveApp: app }) }}
          />
        </AppSort>}
        {showInformation && <Information
          style={{ padding: '1rem' }}
        >
          {users && users[0] && <UserProfileHeader
            key={users && users[0] && users[0]._id}
            user={users && users[0]}
            small
            hideFollow
          /> || <div style={{ height: '2rem' }}></div>}
          <h3>{title && (title.text.charAt(0).toUpperCase() + title.text.slice(1))}</h3>
          <p>{description && description.text}</p>
        </Information>}
        {!showInformation && this.renderClone(app, null, true)}
      </App>
    )
  }

  renderClone = (app) => {
    const { collection } = this.props;
    const { _id } = app;
    if (Popup.app !== _id || Popup.collection !== collection) return null;
    const hoveringRef = this.state.refs[_id];
    if (!hoveringRef) return null;
    const { left, top, width } = hoveringRef.getBoundingClientRect();
    const delta = width * 0.2;
    return ReactDOM.createPortal(
      <div
        className="card-clone"
        style={{
          position: 'absolute',
          top: top - delta/2,
          left: left - delta/2,
          width: width + delta,
          // height: 360,
          boxShadow: '0 4px 32px rgba(0, 0, 0, 0.3)',
          background: 'white',
          borderRadius: '0.5rem',
          overflow: 'hidden',
          pointerEvents: 'none'
        }}
      >
        {this.appCard(app, null, true)}
      </div>,
      document.body
    );
  };

  render() {
    const { mobile, tablet } = WindowSize;
    const { apps, listView, small, wrap, showCreateNew, onCreateNew } = this.props;
    const { confirmRemoveApp } = this.state;
    return (
      <Wrapper style={{ flexWrap: wrap && 'wrap', flexDirection: listView && 'column', gap: small && (wrap && '1rem 0.75rem' || '0.75rem') || (wrap && '1.5rem 1rem' || '1rem') }}>
        {showCreateNew && <App
          onClick={onCreateNew}
          style={{
            position: 'relative',
            border: '1px dashed black',
            backgroundPosition: 'cover',
            height: small && (mobile && 90 || 100) || (tablet && 135 || 157.5),
            width: small && (mobile && 160 || 178) || (tablet && 240 || 280),
            paddingBottom: listView && 'unset',
            flexShrink: 0,
            borderRadius: '0.5rem',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <div style={{
            fontSize: small && Constants.SmallFontSize || Constants.NormalFontSize,
            textAlign: 'center'
          }}>+ New App</div>
        </App>}
        {apps.map((app, i) => this.appCard(app, i))}
        {confirmRemoveApp && <ConfirmDialog
          text="Are you sure you wish to remove?"
          open
          onNo={() => this.setState({ confirmRemoveApp: false })}
          onYes={() => {
            const appIndex = apps.findIndex(({ _id }) => _id === confirmRemoveApp._id);
            if (appIndex < 0) return;
            apps.splice(appIndex, 1);
            this.setState({ confirmRemoveApp: false });
          }}
        />}
      </Wrapper>
    );
  }
}
