/*
 * FeaturePage
 *
 * List all the features
 */
import React from 'react';
import styled from 'styled-components';
import LoadingIndicator from '../../components/LoadingIndicator';
import Navigation from '../../services/Navigation';
import UserAccount from '../../services/UserAccount';
import StatefulTextField from '../../components/StatefulTextField';
import StatefulButton from '../../components/StatefulButton/index';
import FullScreenVerticalCenterContent from '../../styledhtml/FullScreenVerticalCenterContent';
import Constants from '../../submodules/logictry_config/constants';
import Button from '../../components/Button';

const Wrapper = styled.div`
  a {
    text-decoration: none;
  }
`;
const Form = styled.form`
  display: flex;
  align-items: center;
  flex-direction: column;
  h1 {
    margin-bottom: 2rem;
  }
`;
const SocialLogins = styled.div`
  margin-top: 2rem;
  display: flex;
  height: 60px;
  > div {
    color: ${Constants.PrimaryColor};
    cursor: pointer;
    padding: 0.5rem 1rem;
    margin: 0.5rem;
    font-size: 1rem;
    border-radius: 0.5rem;
  }
`;
const { innerHeight } = window;

export default class LoginPage extends React.PureComponent {
  constructor() {
    super();
    UserAccount.account.token = '';
  }
  state = {
    page: UserAccount.account.password ? 'PASSWORD' : ''
  }
  componentDidMount() {
    UserAccount.onStateUpdate(this.accountUpdate);
    this.accountUpdate();
    this.getFragment();
  }
  componentWillUnmount() {
    UserAccount.offStateUpdate(this.accountUpdate);
  }
  accountUpdate = () => {
    const { page } = this.state;
    if (UserAccount.account.error === 'IncorrectPassword') {
      if (page === 'PASSWORD' && UserAccount.account.password) {
        this.state.warning =  'Password incorrect';
        UserAccount.account.password = '';
      }
      this.setState({ page: 'PASSWORD' });
      return this.forceUpdate();
    }
    if (UserAccount.account.error === 'UserPasswordMissing') {
      return setTimeout(() => {
        return Navigation.push(Navigation.register);
      }, 250)
    }
    if (UserAccount.account.error === 'MFAVerificationFailed') {
      this.state.warning =  'Invalid Code';
      return this.setState({ page: 'MFA' });
    }
    if (UserAccount.account.error === 'MFAVerificationRequired') {
      return this.setState({ page: 'MFA' });
    }
    return this.forceUpdate();
  }

  login = () => {
    const email = UserAccount.account && UserAccount.account.email;
    if (!email) {
      this.state.warning = 'Email missing';
      return this.forceUpdate();
    }
    this.state.warning = '';
    return UserAccount.loginAccount({ email, password: UserAccount.account.password, token: UserAccount.account.token, google: UserAccount.account.google });
  }

  getFragment = () => {
    const hash = window.location.hash.split('#')[1];
    if (!hash) return;
    const values = {};
    hash.split('&').forEach((pair) => {
      const keyVals = pair.split('=');
      values[keyVals[0]] = keyVals[1];
    });
    if (!values.access_token) return;
    if (values.state !== window.location.host.split('.')[0]) window.location = `https://${values.state}.logictry.com/login${window.location.hash}`;
    if (values.scope === 'https://www.googleapis.com/auth/drive.metadata.readonly') UserAccount.loginAccount({ google: { accessToken: values.access_token } });
  }
  render() {
    const { page } = this.state;
    if (UserAccount.isLoggingIn || UserAccount.account.error === 'UserNotFound') return <FullScreenVerticalCenterContent><LoadingIndicator /></FullScreenVerticalCenterContent>;
    if (page === 'MFA') {
      return (
        <Wrapper style={{ position: 'absolute', top: 0, right: 0, left: 0, height: Constants.isIphone ? `${innerHeight - 124}px` : '100%' }}>
          <FullScreenVerticalCenterContent>
            <Form>
              <h1>Multi-Factor Authentication</h1>
              <StatefulTextField
                value={UserAccount.account.token}
                onChange={(_token) => { UserAccount.account.token = _token; this.forceUpdate(); }}
                label="Verification Code"
                maxLength={256}
                onKeyDown={(e) => { if (e.key === 'Enter') { this.login(); } }}
              />
              <div style={{ marginTop: 50, marginBottom: 50 }}>
                <StatefulButton
                  onClick={this.login}
                  text="Login"
                  warning={this.state.warning}
                />
              </div>
            </Form>
          </FullScreenVerticalCenterContent>
        </Wrapper>
      );
    }
    const { googleSignIn } = UserAccount;
    return (
      <Wrapper style={{ position: 'absolute', top: 0, right: 0, left: 0, height: Constants.isIphone ? `${innerHeight - 124}px` : '100%' }}>
        <FullScreenVerticalCenterContent>
          <Form>
            <h1>{page === 'PASSWORD' ? 'Enter Password' : 'Login'}</h1>
            <StatefulTextField
              value={UserAccount.account.email}
              onChange={(_email) => { UserAccount.account.email = _email; this.forceUpdate(); }}
              label="Email or Username"
              type="email"
              name="email"
              maxLength={256}
              autoComplete
              onKeyDown={(e) => { if (e.key === 'Enter') { this.login(); } }}
              autoFocusOnLoad={!Constants.isIphone && page !== 'PASSWORD'}
            />
            {page === 'PASSWORD' && <StatefulTextField
              value={UserAccount.account.password}
              onChange={(_password) => { UserAccount.account.password = _password; this.forceUpdate(); }}
              name="password"
              label="Password"
              type="password"
              maxLength={30}
              onKeyDown={(e) => { if (e.key === 'Enter') { this.login(); } }}
              autoFocusOnLoad={!Constants.isIphone && page === 'PASSWORD'}
            />}
            <div style={{ margin: '2rem 0' }}>
              <StatefulButton
                onClick={this.login}
                text="Submit"
                warning={this.state.warning}
              />
            </div>
            <Button url={Navigation.register} tabIndex={-1}>
              <span style={{ fontSize: Constants.SemiSmallFontSize }}>Don't have an account?</span>
            </Button>
            <Button url={Navigation.resetPassword} tabIndex={-1}>
              <span style={{ fontSize: Constants.SemiSmallFontSize }}>Forgot password?</span>
            </Button>
            {googleSignIn && <SocialLogins>
              <div ref={(e) => { if (e && googleSignIn && googleSignIn.parentNode !== e) { e.appendChild(googleSignIn); googleSignIn.style.display = null; } }}></div>
            </SocialLogins>}
          </Form>
        </FullScreenVerticalCenterContent>
      </Wrapper>
    );
  }
}
