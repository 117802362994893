/* eslint-disable no-nested-ternary */
import React from 'react';
import styled from 'styled-components';
import { PropTypes } from 'prop-types';
import GrayOut from '../../services/TreeDisplay';
import TreeCreateNode from '../../services/TreeCreateNode';
import { NoteIcon } from '../../styledhtml/Icon';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 40px;
  > div {
    width: 16px;
    height: 16px;
  }
`;

export default class AddNote extends React.PureComponent {
  static propTypes = {
    node: PropTypes.object,
  }
  componentDidMount() {
    const { node } = this.props;
    node.onStateUpdate(this);
  }
  componentWillUnmount() {
    const { node } = this.props;
    node.offStateUpdate(this);
  }
  createNote = (e) => {
    const { node } = this.props;
    e.preventDefault();
    e.stopPropagation();
    GrayOut.addActiveObject(node);
    TreeCreateNode.createNote(node);
  }
  render() {
    const { primaryColor } = GrayOut.tree.root;
    return (
      <Wrapper>
        <NoteIcon onClick={this.createNote} style={{ backgroundColor: primaryColor }} />
      </Wrapper>
    );
  }
}
