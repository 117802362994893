/* eslint-disable react/no-danger */
import React from 'react';
import styled from 'styled-components';
import { PropTypes } from 'prop-types';
import Constants from '../../submodules/logictry_config/constants';
import InfoPopup from './InfoPopup';
import GrayOut from '../../services/TreeDisplay';
import WindowSize from '../../services/WindowSize';
// eslint-disable-next-line import/no-cycle
import CreateChildren from './CreateChildren';
import RenderedHtmlToTextArea from '../../components/RenderedHtmlToTextArea/index';
import { CATEGORY } from '../../models/nodetypes';
import RawHtml from '../../components/RawHtml/index';
import IconChildrenWrapper from './IconChildrenWrapper';
import KeywordCount from './KeywordCount';
import CategoryAnalytics from './CategoryAnalytics';
import { CaretDownIcon, CaretRightIcon } from '../../styledhtml/Icon';


const Wrapper = styled.div`
  > div:first-child {
    display: flex;
    align-items: center;
    &:hover {
      opacity: 1 !Important;
    }
    > i {
      cursor: pointer;
    }
  }
`;
const TextWrapper = styled.div`
  padding-top: ${Constants.defaultLinePadding};
  padding-bottom: ${Constants.defaultLinePadding};
`;

export default class CategoryBox extends React.PureComponent {
  static propTypes = {
    category: PropTypes.object,
  }
  componentDidMount() {
    const { category } = this.props;
    category.onStateUpdate(this);
    if (category.displayText) category.displayText.onStateUpdate(this);
    GrayOut.onStateUpdate(this);
    WindowSize.onStateUpdate(this);
  }
  componentWillUnmount() {
    const { category } = this.props;
    category.offStateUpdate(this);
    if (category.displayText) category.displayText.offStateUpdate(this);
    GrayOut.offStateUpdate(this);
    WindowSize.offStateUpdate(this);
  }
  toggleExpanded = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const { category } = this.props;
    const expanded = GrayOut.isExpanded(category);
    GrayOut.setExpanded(category, !expanded);
  }
  render() {
    const { mobile } = WindowSize;
    const { category } = this.props;
    const { editing, tree } = GrayOut;
    const { multipleProjects } = tree;
    const expanded = GrayOut.isExpanded(category);
    const { info, card, keywordsFoundBySearch, formattedProjectText, isTextHidden, isNodeHidden } = category;
    const showCard = card && !mobile;
    const cardMargin = `${Constants.defaultLinePadding} 0 ${Constants.defaultLinePadding} ${Constants.TreeContentIconIndent}`;
    const padding = showCard ? '8px 20px 8px 8px' : null;
    const border = showCard ? '1px solid rgb(193, 193, 193)' : null;
    const backgroundColor = showCard ? 'white' : null;
    const borderRadius = Constants.BoxRadius;

    if (!editing && isNodeHidden) return null;

    if (!editing && isTextHidden) {
      const margin = showCard ? cardMargin : `0px`;
      return (
        <div style={{ margin, padding, border, borderRadius, backgroundColor }}>
          <div style={{ marginLeft: showCard ? '-14px' : '0px' }}>
            <CreateChildren node={category} />
          </div>
        </div>
      );
    }

    const { primaryColor } = GrayOut.tree.root;
    const margin = showCard ? cardMargin : `0px 0px 0px 14px`;
    const iconStyle = { width: 12, height: 12, margin: 14, backgroundColor: primaryColor };
    return (
      <div style={{ padding: margin }}>
        <Wrapper style={{ padding, border, borderRadius, backgroundColor }}>
          <div>
            <div onMouseDown={this.toggleExpanded} style={{ cursor: 'pointer' }}>
              {expanded ? (
                <CaretDownIcon style={iconStyle} />
              ) : (
                <CaretRightIcon style={iconStyle} />
              )}
            </div>
            {multipleProjects && <CategoryAnalytics category={category} />}
            {editing ? (
              <RenderedHtmlToTextArea node={category} placeholderText={CATEGORY} />
            ) : (
              <TextWrapper>
                <RawHtml className="logictry-category__text" onMouseDown={this.toggleExpanded} html={formattedProjectText} />
              </TextWrapper>
            )}
            {keywordsFoundBySearch && keywordsFoundBySearch.size > 0 && <InfoPopup text={`<h3 style="text-decoration: underline;">Keywords Found</h3><p>${([...keywordsFoundBySearch]).join(', ')}</p>`}><KeywordCount style={{ backgroundColor: primaryColor }}>{keywordsFoundBySearch.size}</KeywordCount></InfoPopup>}
            {info && <InfoPopup text={info.text} />}
          </div>
          {expanded && <IconChildrenWrapper><CreateChildren node={category} /></IconChildrenWrapper>}
        </Wrapper>
      </div>
    );
  }
}
