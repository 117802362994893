import React from 'react';
import styled from 'styled-components';
// import { PropTypes } from 'prop-types';
import Constants from '../../submodules/logictry_config/constants';
import CompanyService from '../../services/Company';
import FullScreenVerticalCenterContent from '../../styledhtml/FullScreenVerticalCenterContent/index';
import LoadingIndicator from '../../components/LoadingIndicator';
import FullHeightDiv from '../FullHeightDiv/index';
import Button from '../Button/index';
import SelectMenu from '../SelectMenu';
import Popup from '../Popup/index';
import BoxPopup from '../../containers/TreePage/BoxPopup';
import Scrollable from '../Scrollable/index';
import { UsersIcon } from '../../styledhtml/Icon';

const Wrapper = styled.div`
  ${Constants.PopupHeight}
  width: ${Constants.ListPopupWidth};
  max-width: 90vw;
  overflow: hidden;
  position: relative;

  input {
    padding: 20px;
    width: 100%;
    flex-shrink: 0;
  }
`;
const UserWrapper = styled.div`
  height: 46px;
  display: flex;
  align-items: center;
  > i:nth-child(1) {
    margin: 15px;
  }
  > div:nth-child(2) {
    flex: 1;
  }
`;
const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0px;
  height: 44px;
`;

export default class TeamsBox extends BoxPopup {
  componentDidMount() {
    CompanyService.onStateUpdate(this);
  }
  componentWillUnmount() {
    CompanyService.offStateUpdate(this);
  }
  onKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      e.stopPropagation();
      const { searchInput } = this;
      searchInput.blur();
    }
  }
  // inviteUser = (_user, _invite) => {
  //   const { domainObject } = this.props;
  //   if (_invite) domainObject.addUser(_user, );
  //   else domainObject.removeUser(_user);
  //   this.forceUpdate();
  // }
  searchText = () => {
    this.forceUpdate();
  }
  updateState = (_selectType, user) => {
    const { domainObject } = this.props;
    const foundUser = domainObject.teams.find((_user) => _user._id === user._id);
    // eslint-disable-next-line no-param-reassign
    if (foundUser) domainObject.removeTeam(user._id);
    else domainObject.addTeam(user._id, _selectType);
    this.forceUpdate();
  }
  render() {
    const { domainObject } = this.props;
    const { isOwnerOrAdmin } = domainObject;
    const teams = CompanyService.accountTeams.currentPage;
    if (!teams) return <FullScreenVerticalCenterContent><LoadingIndicator /></FullScreenVerticalCenterContent>;
    return (
      <Popup
        allowBackgroundClose
        onClose={this.close}
      >
        <Wrapper>
          <Scrollable
            vertical
            style={{ position: 'absolute', top: 0, right: 0, bottom: 44, left: 0 }}
          >
            <FullHeightDiv>
              {teams && (teams.length > 0 ? (teams.map((team) => (
                <UserWrapper key={team._id}>
                  <UsersIcon style={{ width: 16, height: 16, margin: 16 }} />
                  <div>{team.longname || team.shortname}</div>
                  <div style={{ margin: '0px 16px' }}>
                    {isOwnerOrAdmin && (domainObject.teams.find((_team) => _team._id === team._id) ? (
                      <SelectMenu value="Added" onChange={(val) => this.updateState(val, team)} options={['Added', 'Remove'].map((_role) => ({ value: _role, name: _role }))} />
                    ) : (
                      <SelectMenu value="Choose" onChange={(val) => this.updateState(val, team)} options={['Choose', 'Add'].map((_role) => ({ value: _role, name: _role }))} />
                    ))}
                    {!isOwnerOrAdmin && <div style={{ width: 80, textAlign: 'right', marginRight: 8, fontSize: Constants.SemiSmallFontSize }}>{(domainObject.teams.find((_team) => _team._id === team._id)) ? 'Added' : ''}</div>}
                  </div>
                </UserWrapper>
              ))) : (
                <div style={{ textAlign: 'center', marginTop: 20 }}>No Teams found</div>
              ))}
            </FullHeightDiv>
          </Scrollable>
          <ButtonsWrapper>
            <Button onClick={this.close} color="primary" autoFocus>
              Cancel
            </Button>
            <Button onClick={this.saveAndClose} color="primary" autoFocus>
              Save
            </Button>
          </ButtonsWrapper>
        </Wrapper>
      </Popup>
    );
  }
}
