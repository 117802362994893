import Service from './Service';

class Popup extends Service {
  setApp(app, collection) {
    if (this.hoveringTimeout) clearTimeout(this.hoveringTimeout);
    this.hoveringTimeout = setTimeout(() => {
      clearTimeout(this.clearHoveringTimeout);
      this.app = app;
      this.collection = collection;
      this.emitStateUpdate();
    }, this.app ? 100 : 500);
  }
  clearApp(app, collection) {
    if (this.hoveringTimeout) clearTimeout(this.hoveringTimeout);
    if (!this.app) return;
    this.clearHoveringTimeout = setTimeout(() => {
      if (app && app !== this.app) return;
      if (collection && collection !== this.collection) return;
      this.app = null;
      this.collection = null;
      this.emitStateUpdate();
    }, app ? 300 : 0);    
  }
}

const singleton = new Popup();
export default singleton;
