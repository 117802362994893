import React from 'react';
import styled from 'styled-components';
import { PropTypes } from 'prop-types';
import WindowSize from '../../services/WindowSize';
import Constants from '../../submodules/logictry_config/constants';
import TreeCache from '../../services/cache/TreeCache';
import LogicBaseUserCache from '../../services/cache/LogicBaseUserCache';
import UserAccount from '../../services/UserAccount';
import UserCache from '../../services/cache/UserCache';
import LogicBaseAppList from '../../components/LogicBaseAppList';
import FullScreenVerticalCenterContent from '../../styledhtml/FullScreenVerticalCenterContent';
import LoadingIndicator from '../LoadingIndicator';
import Navigation from '../../services/Navigation';
import Scrollable from '../Scrollable';
import CollectionSubscribeButton from '../CollectionSubscribeButton';
import CollectionNewAppPopup from '../CollectionNewAppPopup';
import Popup from '../../services/Popup';
import ScrollIt from '../../utils/Scroll';
import { AngleLeftIcon, AngleRightIcon } from '../../styledhtml/Icon';

const Wrapper = styled.div`
  position: relative;
  overflow: hidden;
  h2 {
    font-size: ${Constants.LargeFontSize};
    ${Constants.MediaMobile} {
      font-size: ${Constants.SemiLargeFontSize};
    }
    font-weight: 500;
    cursor: pointer;
    overflow: hidden;
    -webkit-line-clamp: 1;
    display: box;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    white-space: normal;
  }
  p {
    margin: 0 0 0.5rem;
  }
  > div:last-child {
    transition: 0.5s all;
    opacity: 0;
  }
  :hover {
    > div:last-child {
      ${Constants.MediaDesktop} {
        opacity: 1;
      }
    }
  }
`;
const Arrows = styled.div`
  > div {
    position: absolute;
    top: 70px;
    ${Constants.MediaMobile} {
      top: 55px;
    }
    bottom: 0px;
    width: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    > div {
      border-radius: 50%;
      height: 1rem;
      width: 1rem;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 16px;
    }
  }
  > div:first-child {
    left: 0;
    background: linear-gradient(270deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 50%, rgba(255,255,255,1) 100%);
    justify-content: flex-start;
  }
  > div:last-child {
    right: 0;
    background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 50%, rgba(255,255,255,1) 100%);
    justify-content: flex-end;
  }
`;

export default class LogicBaseCollectionRow extends React.PureComponent {
  static propTypes = {
    collection: PropTypes.object,
    wrap: PropTypes.bool,
    showCreateNew: PropTypes.bool,
  }
  state = {
    createNewPopup: false,
    showLeft: false,
    showRight: false,
  }
  componentDidMount() {
    TreeCache.onStateUpdate(this);
    LogicBaseUserCache.onStateUpdate(this);
    UserCache.onStateUpdate(this);
    UserAccount.onStateUpdate(this);
    WindowSize.onStateUpdate(this);
  }
  componentWillUnmount() {
    TreeCache.offStateUpdate(this);
    LogicBaseUserCache.offStateUpdate(this);
    UserCache.offStateUpdate(this);
    UserAccount.offStateUpdate(this);
    WindowSize.offStateUpdate(this);
  }
  scroll = (forward) => {
    if (!this.topicsRef) return;
    const destination = Math.max(this.topicsRef.scrollLeft + (forward ? this.topicsRef.offsetWidth : -this.topicsRef.offsetWidth), 0);
    ScrollIt(destination, this.topicsRef, 500, 'easeInOutQuad', undefined, true);
  }
  onScroll = () => {
    if (!this.topicsRef) return;
    const showLeft = this.topicsRef.scrollLeft > 0;
    const showRight = Math.ceil(this.topicsRef.scrollLeft + this.topicsRef.offsetWidth) < this.topicsRef.scrollWidth;
    this.setState({ showLeft, showRight });
  }
  render() {
    const { collection, wrap, showCreateNew } = this.props;
    const { createNewPopup, showLeft, showRight } = this.state;
    const { mobile, tablet } = WindowSize;
    const collectionStyle = { height: !wrap && (mobile && 190 || tablet && 206 || 228) || null };
    const loading = <Wrapper key={collection._id} style={collectionStyle}><FullScreenVerticalCenterContent key={collection._id}><LoadingIndicator /></FullScreenVerticalCenterContent></Wrapper>;
    const notFound = <Wrapper key={collection._id} style={collectionStyle}><FullScreenVerticalCenterContent key={collection._id}><h1>Page Not Found</h1></FullScreenVerticalCenterContent></Wrapper>;
    const apps = TreeCache.getByIds(collection.apps);
    if (!apps) return loading;
    const publicUser = LogicBaseUserCache.get(collection.owner);
    if (!publicUser) return loading;
    const users = UserCache.getUsersByIds([collection.owner]);
    if (!users) return loading;
    const user = users[0];
    if (!user || !user._id) return notFound;
    return (
      <>
        <Wrapper
          style={collectionStyle}
          onMouseEnter={this.onScroll}
        >
          {!wrap && <div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
            <h2 onClick={() => Navigation.push(`/collections/${collection._id}`)}>{collection.title}</h2>
            <CollectionSubscribeButton collection={collection} />
          </div>}
          <Scrollable
            horizontal={!wrap}
            vertical={wrap}
            onScroll={() => {
              Popup.clearApp();
              this.onScroll();
            }}
            scrollRef={(e) => {
              if (!e) return;
              this.topicsRef = e;
              this.onScroll();
            }}
            rememberScrollPosition={`logicbasecollection_${collection._id}`}
            style={{ padding: wrap && '2rem 0' }}
          >
            <LogicBaseAppList
              showUser
              apps={apps}
              horizontal
              small={wrap && mobile}
              collection={collection._id}
              wrap={wrap}
              showCreateNew={showCreateNew}
              showHoverInformation
              onCreateNew={() => {
                this.setState({ createNewPopup: true });
              }}
            />
          </Scrollable>
          <Arrows>
            <div
              onClick={() => this.scroll(false)}
              style={{ display: showLeft && 'flex' || 'none' }}
            >
              <div>
                <AngleLeftIcon style={{ width: 12, height: 12 }} />
              </div>
            </div>
            <div
              onClick={() => this.scroll(true)}
              style={{ display: showRight && 'flex' || 'none' }}
            >
              <div>
                <AngleRightIcon style={{ width: 12, height: 12 }} />
              </div>
            </div>
          </Arrows>
        </Wrapper>
        {createNewPopup && <CollectionNewAppPopup
          collection={collection}
          onClose={() => this.setState({ createNewPopup: false })}
        />}
      </>
    )
  }
}
