/* eslint-disable jsx-a11y/anchor-has-content */
import React from 'react';
import styled from 'styled-components';
import { PropTypes } from 'prop-types';
import Constants from '../../submodules/logictry_config/constants';
import SidebarService from '../../services/SidebarService';
import SpreadsheetCache from '../../services/cache/SpreadsheetCache';
import ClickableDiv from '../../components/ClickableDiv/index';
import VersioningList from '../../components/VersioningList/index';
import SpreadsheetSettings from '../../components/ObjectSettings/SpreadsheetSettings';
import { HtmlIcon, LockIcon, SaveIcon, UnlockIcon, VersionIcon } from '../../styledhtml/Icon';

const Wrapper = styled.div`
  position: fixed;
  right: 0px;
  background-color: ${Constants.White};
  font-size: ${Constants.SemiLargeFontSize};
  * {
    font-size: ${Constants.SemiSmallFontSize};
  }
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  // border-top-left-radius: 8px;
  box-shadow: ${Constants.BoxShadow};
  // border-left: 1px solid ${Constants.DarkGray};
  overflow-y: auto;

  > div {
    width: 100%;
    height: 100%;
    overflow-x: hidden;
  }
  h3 {
    text-align: center;
  }
`;
const AnswerButtons = styled.div`
  display: flex;
  width: 100%;
  > * {
    width: 100%;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
const Buttons = styled.div`
  display: flex;
  width: 100%;
  * {
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
const AccessStatus = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem 0px 0.25rem 0px;
  > i {
    margin-right: 10px;
  }
`;
const Divider = styled.div`
  width: 100%;
  background-color: ${Constants.MediumBackground};
  margin: 20px 0px;
  text-align: center;
`;

export default class Sidebar extends React.PureComponent {
  static propTypes = {
    spreadsheet: PropTypes.object,
    saveChanges: PropTypes.func,
    checkout: PropTypes.func,
    checkin: PropTypes.func,
    showEditor: PropTypes.bool,
  };
  state = {
    showVersions: false,
  }
  componentDidMount() {
    const { spreadsheet } = this.props;
    spreadsheet.onStateUpdate(this);
    SpreadsheetCache.onStateUpdate(this);
    SidebarService.onStateUpdate(this);
  }
  componentWillUnmount() {
    const { spreadsheet } = this.props;
    spreadsheet.offStateUpdate(this);
    SpreadsheetCache.offStateUpdate(this);
    SidebarService.offStateUpdate(this);
  }
  getUserFullname = (users, userId) => {
    if (!users || !userId) return '';
    const foundUser = users.find((_user) => _user._id === userId);
    return foundUser ? foundUser.fullname : '';
  }
  getAnswerButtons = () => {
    const { showWysiwyg } = SidebarService;
    const { checkout, checkin, spreadsheet } = this.props;
    const { isCheckoutAllowed, isCheckedOut, isCheckinAllowed, isOwnerOrAdmin, _id } = spreadsheet;
    const answerButtons = [];
    if (isOwnerOrAdmin && !isCheckedOut && isCheckoutAllowed) answerButtons.push(<ClickableDiv key="checkout_button" onClick={checkout}>
      <LockIcon style={{ height: 16, width: 16 }} />
    </ClickableDiv>);
    if (isOwnerOrAdmin && isCheckedOut && isCheckinAllowed) answerButtons.push(<ClickableDiv key="checkin_button" onClick={checkin}>
      <UnlockIcon style={{ height: 16, width: 16 }} />
    </ClickableDiv>);
    answerButtons.push(<div key="show_html">
      <HtmlIcon
        style={{ opacity: !showWysiwyg && 0.3, height: 16, width: 16 }}
        onClick={() => SidebarService.updateShowWysiwyg(!showWysiwyg)}
      />
    </div>);
    if (_id) answerButtons.push(<div key="show_versions">
      <VersionIcon style={{ opacity: 0.3, height: 16, width: 16 }} onClick={this.toggleVersions} />
    </div>);
    answerButtons.push(<div key="settings"><SpreadsheetSettings object={spreadsheet} /></div>);
    return answerButtons;
  }
  getVersionContent = () => {
    const { spreadsheet } = this.props;
    const { version, sheetDiffs, currentVersionDisplayed } = spreadsheet;
    if (!sheetDiffs) return null;
    const diffs = sheetDiffs[0];
    if (!version || !diffs) return null;
    return [
      <Divider key="versions_divider">Versions</Divider>,
      <VersioningList
        key="versioning_list"
        node={spreadsheet}
        diffs={diffs}
        version={version}
        currentVersionDisplayed={currentVersionDisplayed}
        showVersion={this.showVersion}
        diffCache={SpreadsheetCache.diffCache}
      />
    ]
  }
  showVersion = (version, deltas, index) => {
    const { spreadsheet } = this.props;
    spreadsheet.showVersion(version, deltas.slice(0, index));
  }
  toggleVersions = () => {
    const { showVersions } = this.state;
    this.setState({ showVersions: !showVersions });
  }
  render() {
    const { showWysiwyg } = SidebarService;
    const { showEditor, saveChanges, checkout, checkin, spreadsheet } = this.props;
    const { showVersions } = this.state;
    const { isLocked, isOwnerOrAdmin, isCheckedOut, isCheckinAllowed, isCheckoutAllowed, currentVersionDisplayed, isEditor } = spreadsheet;
    const width = 40;
    const borderBottomLeftRadius = 8;
    const borderTopLeftRadius = 8;
    const overflowY = 'hidden';
    const buttons = this.getAnswerButtons();
    const top = `calc(50vh - ${25 * buttons.length}px)`;
    const height = `${50 * buttons.length}px`;
    if (isLocked) return null;
    const iconStyle = { height: 16, width: 16 };
    if (showVersions) {
      return (<Wrapper style={{ top: Constants.DesktopHeaderHeight, width: 240, height: `calc(100vh - ${Constants.DesktopHeaderHeight})` }}>
        <div>
          {isOwnerOrAdmin && isCheckedOut && isCheckinAllowed && <AccessStatus onClick={checkin}>
            <UnlockIcon style={{ ...iconStyle, marginRight: 8 }} />
            <div>Finish Editing</div>
          </AccessStatus>}
          {isOwnerOrAdmin && isCheckedOut && !isCheckinAllowed && <AccessStatus>
            <LockIcon style={{ ...iconStyle, marginRight: 8 }} />
            <div>Already In-use</div>
          </AccessStatus>}
          {isOwnerOrAdmin && !isCheckedOut && isCheckoutAllowed && <AccessStatus onClick={checkout}>
            <LockIcon style={{ ...iconStyle, marginRight: 8 }} />
            <div>Start Editing</div>
          </AccessStatus>}
          {!isOwnerOrAdmin && <div style={{ textAlign: 'center', padding: 10 }}>You dont have permission to edit</div>}
          <AnswerButtons style={{ marginTop: 10, flexDirection: 'row' }}>
            <div><VersionIcon style={iconStyle} onClick={this.toggleVersions} /></div>
            <div>
              <HtmlIcon
                style={{ opacity: !showWysiwyg && 0.3, ...iconStyle }}
                onClick={() => SidebarService.updateShowWysiwyg(!showWysiwyg)} />
            </div>
            <ClickableDiv onClick={saveChanges}>
              <SaveIcon
                style={{ opacity: (!isEditor || !currentVersionDisplayed) && 0.3, ...iconStyle }} />
            </ClickableDiv>
          </AnswerButtons>
          {this.getVersionContent()}
          <div style={{ flex: 1, height: 40 }}></div>
        </div>
      </Wrapper>);
    }
    return (
      <Wrapper style={{ top, width, height, borderBottomLeftRadius, borderTopLeftRadius, overflowY }}>
        <div style={{ padding: 0 }}>
          <Buttons style={{ marginTop: 0, flexDirection: showEditor ? 'row' : 'column' }}>
            {buttons}
          </Buttons>
        </div>
      </Wrapper>
    );
  }
}
