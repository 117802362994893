import React from 'react';
import styled from 'styled-components';
import Constants from '../../submodules/logictry_config/constants';
import LogicBaseCollectionCache from '../../services/cache/LogicBaseCollectionCache';
import WindowSize from '../../services/WindowSize';
import FullScreenVerticalCenterContent from '../../styledhtml/FullScreenVerticalCenterContent';
import LoadingIndicator from '../../components/LoadingIndicator';
import { getCollectionMostFollowers, getCollectionMostRecentlyUpdated } from '../../services/Pagination';
import Scrollable from '../../components/Scrollable';
import Navigation from '../../services/Navigation';
import LogicBaseCollectionRow from '../../components/LogicBaseCollectionRow';
import ScrollIt from '../../utils/Scroll';
import Popup from '../../services/Popup';
import { AngleLeftIcon, AngleRightIcon } from '../../styledhtml/Icon';

const TOPICS = {
  'latest': 'Latest',
  'trending': 'Trending',
  'logic': 'Logic',
  'wld': 'World Logic Day',
  'sdgs': 'Sustainable Development Goals',
  'community': 'Community',
  'volunteering': 'Volunteering'
}

const Content = styled.div`
  position: relative;
  overflow: hidden;
  height: 34px;
  > div:last-child {
  transition: 0.5s all;
    opacity: 0;
  }
  :hover {
    > div:last-child {
      ${Constants.MediaDesktop} {
        opacity: 1;
      }
    }
  }
`;
const Topic = styled.div`
  cursor: pointer;
  min-width: 4rem;
  text-align: center;
  border-radius: 1.5rem;
  font-size: 0.75rem;
  flex-shrink: 0;
  padding: 0.5rem 1rem;
`;
const Arrows = styled.div`
  > div {
    position: absolute;
    height: 34px;
    width: 60px;
    top: 0;
    display: flex;
    align-items: center;
    cursor: pointer;
    > div {
      border-radius: 50%;
      height: 1.5rem;
      width: 1.5rem;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  > div:first-child {
    left: 0;
    background: linear-gradient(270deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 50%, rgba(255,255,255,1) 100%);
    justify-content: flex-start;
  }
  > div:last-child {
    right: 0;
    background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 50%, rgba(255,255,255,1) 100%);
    justify-content: flex-end;
  }
`;

export default class LogicBaseCollectionsPage extends React.PureComponent {
  constructor() {
    super();
    this.collectionMostFollowers = getCollectionMostFollowers('');
    this.collectionMostRecentlyUpdate = getCollectionMostRecentlyUpdated('');
  }
  state = {
    t: Navigation.currentSearch.t || 'latest',
    showLeft: false,
    showRight: false,
  }
  componentDidMount() {
    this.collectionMostFollowers.onStateUpdate(this);
    this.collectionMostRecentlyUpdate.onStateUpdate(this);
    LogicBaseCollectionCache.onStateUpdate(this);
    WindowSize.onStateUpdate(this);
  }
  componentWillUnmount() {
    this.collectionMostFollowers.offStateUpdate(this);
    this.collectionMostRecentlyUpdate.offStateUpdate(this);
    LogicBaseCollectionCache.offStateUpdate(this);
    WindowSize.offStateUpdate(this);
  }
  onPagination = () => {
    if (this.collectionsCache && this.collectionsCache.showMore && this.collectionsCache.allFound && this.collectionsCache.initialized) {
      this.collectionsCache.getMore();
      this.forceUpdate();
    }
  }
  scroll = (forward) => {
    if (!this.topicsRef) return;
    const destination = Math.max(this.topicsRef.scrollLeft + (forward ? this.topicsRef.offsetWidth : -this.topicsRef.offsetWidth), 0);
    ScrollIt(destination, this.topicsRef, undefined, undefined, undefined, true);
  }
  onScroll = () => {
    if (!this.topicsRef) return;
    const showLeft = this.topicsRef.scrollLeft > 0;
    const showRight = Math.ceil(this.topicsRef.scrollLeft + this.topicsRef.offsetWidth) < this.topicsRef.scrollWidth;
    this.setState({ showLeft, showRight });
  }
  navigateTag = (tag) => {
    if (Constants.isIphone) {
      this.setState({ t: tag });
    } else {
      if (!tag || tag === 'latest') return Navigation.push(`collections`);
      return Navigation.push(`collections?t=${tag}`, { 'logicbasecollectiontopics_horizontal': this.topicsRef.scrollLeft });
    }
  }
  render() {
    const { t, showLeft, showRight } = this.state;
    const { mobile } = WindowSize;
    const loading = <FullScreenVerticalCenterContent><LoadingIndicator /></FullScreenVerticalCenterContent>;
    this.collectionsCache = null;
    if (t === 'trending') {
      this.collectionsCache = this.collectionMostFollowers;
      this.collections = this.collectionMostFollowers.currentPage;
    } else if (t === 'logic') {
      this.collections = LogicBaseCollectionCache.getByIds([
        '64bd2be9671e7439ff49f9a9'
      ])
    } else if (t === 'wld') {
      this.collections = LogicBaseCollectionCache.getByIds([
        '6764b36bd245af2839873075', '6765c78ad245af2839878ae8', '6765b722d245af2839877693', '6765b30bd245af2839877268'
      ]);
    } else if (t === 'sdgs') {
      this.collections = LogicBaseCollectionCache.getByIds([
        '664a9d28b4e8ff3186c37061'
      ])
    } else if (t === 'community') {
      this.collections = LogicBaseCollectionCache.getByIds([
        '66e9aa872277c6df8dcbdf88', '67094cd4c60e63fe1ac56e6e', '64bc3170fd1d5c1bdba61eb6', '657dbae66837722e73eadd11', '664a5407a8e1681903c28679', '64bd1698b20bfa2b228ad089', '657db498a73f67a5614832db', '6759b05eb954a7f950f0a13e', '6582fc663591dc84594585ed', '65847f29be50070b6740e2c0', '66f70f72294fad9529b55b52', '659c5b2ebc5a1e35ec1593c0', '65843ad64b554905bb0f4ea8', '655e06340d377f6535bea496'
      ]);
    } else if (t === 'volunteering') {
      this.collections = LogicBaseCollectionCache.getByIds([
        '67ad18a6fc7fe117ebe30022'
      ]);
    } else {
      this.collectionsCache = this.collectionMostRecentlyUpdate;
      this.collections = this.collectionMostRecentlyUpdate.currentPage;
    }
    if (!this.collections || this.collections.length === 0) return loading;
    const tagArray = t && t.split(',') || [];
    return (
      <Scrollable
        vertical
        paginationHeight={400}
        onPagination={this.onPagination}
        style={{ position: mobile ? 'relative' : 'absolute', top: 0, left: 0, right: 0, bottom: 0, padding: mobile ? '2rem 1rem' : '2rem' }}
        rememberScrollPosition={`logicbasecollections`}
        onScroll={() => {
          Popup.clearApp();
        }}
      >
        <div>
          <Content
            onMouseEnter={this.onScroll}
          >
            <Scrollable
              horizontal
              onScroll={this.onScroll}
              scrollRef={(e) => { this.topicsRef = e; this.onScroll(); }}
              style={{ position: 'absolute', inset: 0 }}
              rememberScrollPosition={`logicbasecollections_topics`}
            >
              <div style={{ display: 'flex', gap: '1rem', alignItems: 'center' }}>
                {Object.keys(TOPICS).map((url) => {
                  const isSelected = tagArray.includes(url);
                  return (
                    <Topic key={url} style={{ backgroundColor: isSelected ? Constants.DarkGray : Constants.MediumBackground, color: isSelected ? 'white' : 'unset', }} onClick={() => this.navigateTag(url)}>{TOPICS[url]}</Topic>
                  );
                })}
              </div>
            </Scrollable>
            <Arrows>
              <div style={{ display: showLeft && 'flex' || 'none' }}>
                <div onClick={() => this.scroll(false)}>
                  <AngleLeftIcon style={{ width: 10, height: 10 }} />
                </div>
              </div>
              <div style={{ display: showRight && 'flex' || 'none' }}>
                <div onClick={() => this.scroll(true)}>
                  <AngleRightIcon style={{ width: 10, height: 10 }} />
                </div>
              </div>
            </Arrows>
          </Content>
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '2rem', marginTop: '2rem', paddingBottom: '4rem' }}>
          {this.collections.map((collection) => <LogicBaseCollectionRow
            key={collection._id}
            collection={collection}
          />)}
        </div>
      </Scrollable>
    );
  }
}
