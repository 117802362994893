import React from 'react';
// import PropTypes from 'prop-types';
import styled from 'styled-components';
import Constants from '../../submodules/logictry_config/constants';
import WindowSize from '../../services/WindowSize';
import Navigation from '../../services/Navigation';
import UserAccount from '../../services/UserAccount';
import LogicBaseUserCache from '../../services/cache/LogicBaseUserCache';
import LogicBaseCollectionCache from '../../services/cache/LogicBaseCollectionCache';
import LogicBaseCommentsCache from '../../services/cache/LogicBaseCommentsCache';
import UserCache from '../../services/cache/UserCache';
import LogicBaseRelationshipCache from '../../services/cache/LogicBaseRelationshipCache';
import LogicBaseNotificationCache from '../../services/cache/LogicBaseNotificationCache';
import TreeCache from '../../services/cache/TreeCache';
import { getNotifications } from '../../services/Pagination';
import timeAgo from '../../utils/timeAgo';
import Scrollable from '../../components/Scrollable';
import UserProfileHeader from '../../components/UserProfileHeader';
import getFormattedTextElements from '../../utils/getFormattedTextElements';
import { AngleRightIcon } from '../../styledhtml/Icon';

const NotificationWrapper = styled.div`
  max-width: 48rem;
  margin: auto;
  display: flex;
  flex-direction: column;

  h1 {
    margin: 2rem 0;
    text-align: center;
  }
`;
const NotificationSection = styled.div`
  width: 100%;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
  > div {
    display: flex;
    width: 100%;
    > div:nth-child(2) {
      flex: 1;
    }
    > div:last-child {
      i {
        padding: 1rem;
      }
    }
  }
`;

const GET_APP_TYPES = ['NewAppLike', 'NewAppPin', 'NewComment'];
const GET_COLLECTION_TYPES = ['NewCollectionSubscriber', 'NewCollectionApp', 'NewCollectionEditor', 'NewCollectionOwner', 'NewAppCollection'];
const GET_COMMENT_TYPES = ['NewCommentMention', 'NewCommentReply', 'NewCommentLike'];
const GET_USER_TYPES = ['NewFollower', 'FollowApproved', 'NewFollowRequest'];

export default class LogicBaseNotificationPage extends React.PureComponent {
  constructor() {
    super();
    const { account } = UserAccount;
    this.notifications = getNotifications(account._id);
  }
  state = {
    showNotifications: [],
    newNotifications: {},
  }
  componentDidMount() {
    LogicBaseUserCache.onStateUpdate(this);
    UserCache.onStateUpdate(this);
    this.notifications.onStateUpdate(this);
    UserAccount.onStateUpdate(this);
    TreeCache.onStateUpdate(this);
    LogicBaseRelationshipCache.onStateUpdate(this);
    LogicBaseCollectionCache.onStateUpdate(this);
    LogicBaseNotificationCache.onStateUpdate(this);
    LogicBaseCommentsCache.onStateUpdate(this);
    WindowSize.onStateUpdate(this);
  }
  componentWillUnmount() {
    LogicBaseUserCache.offStateUpdate(this);
    UserCache.offStateUpdate(this);
    this.notifications.offStateUpdate(this);
    UserAccount.offStateUpdate(this);
    TreeCache.offStateUpdate(this);
    LogicBaseRelationshipCache.offStateUpdate(this);
    LogicBaseCollectionCache.offStateUpdate(this);
    LogicBaseNotificationCache.offStateUpdate(this);
    LogicBaseCommentsCache.offStateUpdate(this);
    WindowSize.offStateUpdate(this);
  }
  clearNotification = (notification) => {
    notification.read = true;
    LogicBaseNotificationCache.update(notification);
  }
  onPagination = () => {
    if (this.notifications.showMore && this.notifications.allFound && this.notifications.initialized) {
      this.notifications.getMore();
    }
  }
  getUserAction = (user, url, text, updatedTime, image) => (
    <div style={{ display: 'flex', alignItems: 'flex-start', gap: '0.5rem' }}>
      {user && <UserProfileHeader
        user={{
          _id: user._id,
          image: user.image,
          description: user.description,
          username: user.username,
          fullname: user.fullname,
        }}
        small
        hideFollow
        hideProfile
      />}
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: '0.25rem' }}>
        <div style={{ cursor: 'pointer' }} onClick={() => Navigation.push(url)}>{user && (user.fullname && `${user.fullname} ` || `@${user.username || `${user._id.slice(0, 8)}...`} `) || ''}{text}</div>
        <div style={{ fontSize: Constants.SmallFontSize }}>{timeAgo(new Date(updatedTime))}</div>
      </div>
    </div>
  )
  render() {
    const { mobile } = WindowSize;
    const { account } = UserAccount;
    let notifications = this.notifications.currentPage;
    if (notifications.length > 0) this.lastNotifications = notifications;
    else notifications = this.lastNotifications;

    const followRequest = (LogicBaseRelationshipCache.query({ 'fe': account._id, 'r': true, limit: 1 }) || [])[0];

    const appIds = notifications && notifications.map(({ objectId, type }) => (GET_APP_TYPES.includes(type)) && objectId).filter((v) => v);
    const getApps = appIds && appIds.length > 0;
    const apps = notifications && TreeCache.getByIds(Array.from(new Set([
      ...appIds
    ])));

    const commentIds = notifications && notifications.map(({ objectId, type }) => (GET_COMMENT_TYPES.includes(type)) && objectId).filter((v) => v);
    const getComments = commentIds && commentIds.length > 0;
    const comments = getComments && LogicBaseCommentsCache.getByIds(commentIds);

    const collectionIds = Array.from(new Set([
      ...(notifications || []).map(({ objectId, type }) => (GET_COLLECTION_TYPES.includes(type)) && objectId).filter((v) => v)
    ]));
    const getCollections = collectionIds && collectionIds.length > 0;
    const collections = getCollections && LogicBaseCollectionCache.getByIds(collectionIds);

    // Get Users
    const userIds = notifications && notifications.map(({ type, objectId }) => (GET_USER_TYPES.includes(type)) && objectId).filter((v) => v);
    const getUsers = userIds && userIds.length > 0;
    const users = getUsers && UserCache.getUsersByIds(Array.from(new Set(userIds)));

    if (!(!notifications
      || (getUsers && !users)
      || (getApps && !apps)
      || (getComments && !comments)
      || (getCollections && !collections)
    )) {
      const newNotifications = [];
      notifications.forEach((notification) => {
        const { _id, createdTime, type, objectId, read } = notification;
        if (!read) {
          this.state.newNotifications[_id] = true;
        }

        const app = apps && apps.find(({ _id }) => _id === objectId);
        const user = users && users.find(({ _id }) => _id === objectId);
        const comment = comments && comments.find(({ _id }) => _id === objectId);
        const collection = collections && collections.find(({ _id }) => _id === objectId);
        this.clearNotification(notification);
        if ((GET_COLLECTION_TYPES.includes(type)) && (!collection)) return;
        if ((GET_APP_TYPES.includes(type)) && (!app)) return;
        if ((GET_COMMENT_TYPES.includes(type)) && (!comment)) return;
        if ((GET_USER_TYPES.includes(type)) && (!user)) return;

        newNotifications.push({ _id, objectId, type, notification, app, collection, comment, time: createdTime, user });
      });
      this.state.showNotifications = newNotifications;
    }
    const iconStyle = { width: 10, height: 10, flex: 'unset' };
    return (
      <Scrollable
        vertical={!mobile}
        paginationHeight={400}
        onPagination={this.onPagination}
        style={{ position: mobile ? 'relative' : 'absolute', inset: 0, padding: mobile ? '1rem 1rem 2rem' : '1rem 2rem 2rem' }}
        rememberScrollPosition={`logicbasenotifications`}
      >
        <NotificationWrapper>
          <h1>Notifications</h1>
          <NotificationSection>
            {(!account.username || !account.fullname || (!account.image || !account.image.link)) && <div style={{ margin: '8px 12px', display: 'flex', alignItems: 'center', gap: '0.5rem', cursor: 'pointer' }} onClick={() => Navigation.push('/account/settings')}>
              <div style={{ fontWeight: 600 }}>Your contact information is incomplete</div>
              <AngleRightIcon style={iconStyle} />
            </div>}
            {followRequest && <div style={{ margin: '8px 12px', display: 'flex', alignItems: 'center', gap: '0.5rem', cursor: 'pointer' }} onClick={() => Navigation.push('/account/follow-requests')}>
              <div style={{ fontWeight: 600 }}>See follow requests</div>
              <AngleRightIcon style={iconStyle} />
            </div>}
            {this.state.showNotifications.map(({ _id, objectId, type, app, collection, time, user, comment }) => {
              return (
                <div key={_id}>
                  <div style={{ width: 4, height: 4, borderRadius: '50%', background: this.state.newNotifications[_id] ? 'red' : 'unset', marginTop: 14, marginRight: 8 }}></div>
                  <div>
                    {type === 'NewComment' && this.getUserAction(null, `/apps/${objectId}`, `Your app "${app.title.text}" has new comments!`, time)
                      || type === 'NewCommentMention' && this.getUserAction(null, `/apps/${comment.app}`, <>You were mentioned in a comment "{getFormattedTextElements(comment.text)}"!</>, time)
                      || type === 'NewCommentReply' && this.getUserAction(null, `/apps/${comment.app}`, <>Your comment "{getFormattedTextElements(comment.text)}" has new replies!</>, time)
                      || type === 'NewCommentLike' && this.getUserAction(null, `/apps/${comment.app}`, <>Your comment "{getFormattedTextElements(comment.text)}" has new likes!</>, time)
                      || type === 'NewAppLike' && this.getUserAction(null, `/apps/${app._id}`, `Your app "${app.title.text}" has new likes!`, time)
                      || type === 'NewAppPin' && this.getUserAction(null, `/apps/${app._id}`, `Your app "${app.title.text}" has new pins!`, time)
                      || type === 'NewFollower' && this.getUserAction(user, Navigation.userProfile(user), 'started following you!', time)
                      || type === 'NewFollowRequest' && this.getUserAction(user, '/account/follow-requests', 'requested to follow you!', time)
                      || type === 'FollowApproved' && this.getUserAction(user, Navigation.userProfile(user), 'accepted your follow request!', time)
                      || type === 'NewCollectionSubscriber' && this.getUserAction(null, `/collections/${collection._id}`, `Your collection "${collection.title}" has new subscribers!`, time)
                      || type === 'NewCollectionApp' && this.getUserAction(null, `/collections/${collection._id}`, `The collection "${collection.title}" has a new app!`, time, collection.image)
                      || type === 'NewAppCollection' && this.getUserAction(null, `/collections/${collection._id}`, `Your app was added to the collection "${collection.title}"`, time, collection.image)
                      || type === 'NewCollectionEditor' && this.getUserAction(null, `/collections/${collection._id}`, `You have been added as an editor to the collection "${collection.title}"`, time, collection.image)
                      || type === 'NewCollectionOwner' && this.getUserAction(null, `/collections/${collection._id}`, `You have been made the owner of the collection "${collection.title}"`, time, collection.image)
                    }
                  </div>
                </div>
              );
            }) || <div style={{ flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center', textAlign: 'center' }}>
              <div>You have no notifications</div>
            </div>}
          </NotificationSection>
        </NotificationWrapper>
      </Scrollable>
    );
  }
}
