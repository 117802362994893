import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Button from '../Button';
import Constants from '../../submodules/logictry_config/constants';
import WindowSize from '../../services/WindowSize';
import ChangeableImage from '../ChangeableImage/index';
import Navigation from '../../services/Navigation';
import UserAccount from '../../services/UserAccount';
import LogicBaseRelationshipCache from '../../services/cache/LogicBaseRelationshipCache';
import LogicBaseBlockCache from '../../services/cache/LogicBaseBlockCache';
import LogicBaseUserReportCache from '../../services/cache/LogicBaseUserReportCache';
import LogicBaseUserCache from '../../services/cache/LogicBaseUserCache';
import Popup from '../Popup';
import MenuList from '../Popup/MenuList';
import MenuItem from '../Popup/MenuItem';
import AppConstants from '../../submodules/logictry_config/s3_config.json';
import ConfirmDialog from '../ConfirmDialog';
import shortenLikesCount from '../../utils/shortenLikesCount';
import { EllipsisIcon } from '../../styledhtml/Icon';
import LogicBaseUsersPopup from '../../components/LogicBaseUsersPopup';

const ProfileSection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  > div:first-child {
    position: relative;
    border-radius: 50%;
  }
`;
const ClickToGoToAccount = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  cursor: pointer;
`
const ProfileInfo = styled.div`
  padding-left: 2rem;
  padding-top: 0rem;
  ${Constants.MediaMobile} {
    padding-left: 0rem;
    padding-top: 1rem;
    align-items: center;
    text-align: center;
  }
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
`;
const UsernameFullname = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem 1rem;
  ${Constants.MediaMobile} {
    gap: 1rem;
  }
  > div:first-child {
    > :hover {
      text-decoration: underline;
    }
    display: flex;
    align-items: center;
    cursor: pointer;
    > div:first-child {
      overflow: hidden;
      -webkit-line-clamp: 1;
      display: box;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      text-overflow: ellipsis;
      white-space: normal;
    }
  }
  img {
    height: 16px;
    margin-left: 1rem;
  }
`;
const Stats = styled.div`
  display: flex;
  align-items: center;
  white-space: nowrap;
`;
const MenuWrapper = styled.div`
  i {
    margin-right: 1rem;
    justify-content: center;
    display: flex;
    width: 1rem;
  }
`;
const Bio = styled.div`
  margin: 1rem 0 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  ${Constants.MediaMobile} {
    align-items: center;
  }
  > div:first-child {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 1rem;
    justify-content: flex-start;
    gap: 0 1rem;
    > div {
      cursor: pointer;
      display: flex;
      align-items: center;
      ${Constants.MediaMobile} {
        width: calc(50% - 0.5rem);
        // margin-left: 1rem;
        // margin-right: 1rem;
        margin-bottom: 0.5rem;
        justify-content: center;
      }
      > b {
        margin-right: 0.5rem;
        font-size: 1rem;
      }
    }
    > div:not(:last-child) {
      margin-right: 2rem;
      ${Constants.MediaMobile} {
        margin-right: unset;
      }
    }
  }
  > div:nth-child(2) {
    margin-bottom: 0.25rem;
    cursor: pointer;
  }
  > div:last-child {
    margin-bottom: 0;
  }
`;
const FlagWrapper = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 2rem;
  h1 {
    margin-bottom: 1rem;
  }
`;
const FlagButtonsWrapper = styled.div`
  margin-top: 1rem;
  display: flex;
  justify-content: space-around;
  width: 100%;
`;
const ProfileButton = styled.div`
  :hover {
    background: ${Constants.LightBackground};
  }
`;

export default class UserProfileHeader extends React.PureComponent {// eslint-disable-line
  static propTypes = {
    user: PropTypes.object,
    medium: PropTypes.bool,
    large: PropTypes.bool,
    small: PropTypes.bool,
    hideFollow: PropTypes.bool,
    hideProfile: PropTypes.bool,
    nameOnly: PropTypes.bool,
  }
  state = {
    overrideRelationship: false,
    menuOpen: false,
    blockUser: false,
    flagUser: false,
    showFollowers: false,
  }
  componentDidMount() {
    UserAccount.onStateUpdate(this);
    LogicBaseUserCache.onStateUpdate(this);
    LogicBaseRelationshipCache.onStateUpdate(this);
    LogicBaseBlockCache.onStateUpdate(this);
    LogicBaseUserReportCache.onStateUpdate(this);
    WindowSize.onStateUpdate(this);
  }
  componentWillUnmount() {
    UserAccount.offStateUpdate(this);
    LogicBaseUserCache.offStateUpdate(this);
    LogicBaseRelationshipCache.offStateUpdate(this);
    LogicBaseBlockCache.offStateUpdate(this);
    LogicBaseUserReportCache.offStateUpdate(this);
    WindowSize.offStateUpdate(this);
  }
  goToAccount = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const { user } = this.props;
    Navigation.push(Navigation.userProfile(user));
  }
  login = () => {
    Navigation.push(Navigation.login);
  }
  accountSettings = () => {
    Navigation.push(Navigation.accountSettings);
  }
  block = () => {
    const { user } = this.props;
    const youBlocked = LogicBaseBlockCache.query({ br: UserAccount.account._id, be: user._id })
    if (youBlocked && youBlocked[0]) {
      setTimeout(() => {
        LogicBaseBlockCache.__deleteQueryFromCache({ br: UserAccount.account._id, be: user._id });
        LogicBaseBlockCache.delete(youBlocked && youBlocked[0], true);
      });
      this.setState({ blockUser: false });
    } else {
      setTimeout(() => {
        LogicBaseBlockCache.__deleteQueryFromCache({ br: UserAccount.account._id, be: user._id });
        LogicBaseBlockCache.create(LogicBaseBlockCache.createObject({
          br: UserAccount.account._id,
          be: user._id,
        }));
      });
      this.setState({ blockUser: false });
    }
    this.forceUpdate();
  }
  flag = () => {
    const { user } = this.props;
    const youFlagged = LogicBaseUserReportCache.query({ o: UserAccount.account._id, u: user._id })
    if (youFlagged && youFlagged[0]) {
      setTimeout(() => {
        LogicBaseUserReportCache.__deleteQueryFromCache({ o: UserAccount.account._id, u: user._id });
        LogicBaseUserReportCache.delete(youFlagged && youFlagged[0], true);
      });
      this.setState({ flagUser: false });
    } else {
      const d = document.getElementById('userprofileheader__flagdescription').value;
      if (!d) return;
      setTimeout(() => {
        LogicBaseUserReportCache.__deleteQueryFromCache({ o: UserAccount.account._id, u: user._id });
        LogicBaseUserReportCache.create(LogicBaseUserReportCache.createObject({
          o: UserAccount.account._id,
          u: user._id,
          d,
        }));
      });
      this.setState({ flagUser: false });
    }
    this.forceUpdate();
  }
  follow = (privateAccount) => {
    const { isLoggedIn } = UserAccount;
    if (!isLoggedIn) {
      return Navigation.push(Navigation.login);
    }
    const { user } = this.props;
    const relationship = (isLoggedIn && LogicBaseRelationshipCache.query({ fr: UserAccount.account._id, fe: user._id }) || [])[0];
    const theirSocial = LogicBaseUserCache.get(user._id);
    const yourSocial = LogicBaseUserCache.get(UserAccount.account._id);
    if (relationship) {
      if (!(relationship.requested || this.state.overrideRelationship === 'Requested')) {
        theirSocial.followers -= 1;
        if (yourSocial) yourSocial.followees -= 1;
      }
      setTimeout(() => {
        LogicBaseRelationshipCache.__deleteQueryFromCache({ fr: UserAccount.account._id, fe: user._id });
        LogicBaseRelationshipCache.delete(relationship, true);
      });
      this.setState({ overrideRelationship: 'Follow' });
    } else {
      const newRelationship = { fr: UserAccount.account._id, fe: user._id };
      if (privateAccount) {
        newRelationship.r = true;
        this.setState({ overrideRelationship: 'Requested' });
      } else {
        theirSocial.followers += 1;
        if (yourSocial) yourSocial.followees += 1;
        this.setState({ overrideRelationship: '✓ Following' });
      }
      setTimeout(() => {
        LogicBaseRelationshipCache.__deleteQueryFromCache({ fr: UserAccount.account._id, fe: user._id });
        LogicBaseRelationshipCache.create(LogicBaseRelationshipCache.createObject(newRelationship), true);
      });
    }
    this.forceUpdate();
  }
  verifyAccount = () => {
    const { user } = this.props;
    let logicbaseuser = LogicBaseUserCache.get(user._id);
    if (logicbaseuser._id) {
      logicbaseuser.verified = !logicbaseuser.verified;
      LogicBaseUserCache.update(logicbaseuser);
    } else {
      logicbaseuser = LogicBaseUserCache.createObject({ _id: user._id, a: 0, fr: 0, fe: 0, v: true });
      LogicBaseUserCache.create(logicbaseuser);
    }
  }
  render() {
    const { mobile } = WindowSize;
    const { user, small, medium, large, hideFollow, hideProfile, nameOnly } = this.props;
    const { overrideRelationship, menuOpen, blockUser, flagUser, showFollowers } = this.state;
    const { isLoggedIn, isDevAccount } = UserAccount;
    const profileStyle = mobile && !small && { flexDirection: 'column', alignItems: 'center', width: '100%' } || {};
    const editProfileButtonStyle = { cursor: 'pointer', border: `1px solid ${Constants.LightTextGray}`, margin: mobile ? 'auto' : null, color: 'black', padding: '0.25rem 1rem', borderRadius: '0.5rem' }
    const s3Path = `${user._id}/`;
    const signingUrl = `/v1${AppConstants.S3.signingUrls.userprofiles}`;
    const isUserAccount = isLoggedIn && UserAccount.account._id === user._id;
    const social = LogicBaseUserCache.get(user._id);
    const { followers, followees, verified, socialSettings } = social || {};
    const relationshipFound = (isLoggedIn && !hideFollow) ? (LogicBaseRelationshipCache.query({ fr: UserAccount.account._id, fe: user._id }) || this.lastRelationshipFound) : [];
    this.lastRelationshipFound = relationshipFound;
    const youFlagged = (isLoggedIn && !hideFollow) ? (LogicBaseUserReportCache.query({ o: UserAccount.account._id, u: user._id }) || this.youFlagged) : [];
    this.youFlagged =  youFlagged;
    const youBlocked = (isLoggedIn && !hideFollow) ? (LogicBaseBlockCache.query({ br: UserAccount.account._id, be: user._id }) || this.youBlocked) : [];
    const theyBlocked = (isLoggedIn && !hideFollow) ? (LogicBaseBlockCache.query({ br: user._id, be: UserAccount.account._id }) || this.theyBlocked) : [];
    this.youBlocked = youBlocked;
    this.theyBlocked = theyBlocked;
    const relationship = (relationshipFound || [])[0];
    const imageSize = small && '2rem' || medium && '4rem' || large && '7rem' || Constants.BoxSize;
    const disableEditing = !isUserAccount || small || medium || large;
    const privateAccount = socialSettings && socialSettings.s === 'private';
    const backgroundImage = UserAccount.formatUserImage(user) || 'https://logictryuserfiles.s3.amazonaws.com/5cf13c4899397a33fd191007/f9df6454b26ce055592d615f6aec9ec7/avatar.jpg';
    const username = user.fullname || `@${user.username || `${user._id.slice(0, 8)}...`}`;
    const followButtonStyle = { ...editProfileButtonStyle };
    const highlightGreen = isUserAccount && !backgroundImage && !medium && !small && !large;
    if (!relationshipFound) followButtonStyle.backgroundColor = 'white';
    let initials;
    if (user) initials = user.fullname && user.fullname.split(' ').map((_string) => _string.charAt(0)).slice(0, 3) || user.username && user.username.slice(0, 3);
    const border = highlightGreen ? `2px solid ${Constants.PrimaryColor}` : Constants.LightBorder;
    const logicCircleMember = ['5cf13c4899397a33fd191007', '61b9066d992922729af8abe5', '66e1bd7c7bfbfb10437f13f3'].includes(user._id);
    return (
      <ProfileSection style={{ ...profileStyle, width: small && !hideProfile && '100%' }}>
        {!nameOnly && <div>
          <div style={{ border, position: 'absolute', inset: 0, borderRadius: '50%' }}></div>
          {((isUserAccount && !disableEditing) || backgroundImage) ? (
            <ChangeableImage
              onlyAllowImage
              disableEditing={disableEditing}
              s3Path={s3Path}
              signingUrl={signingUrl}
              style={{
                width: imageSize,
                height: imageSize,
                borderRadius: '50%',
                backgroundSize: 'cover'
              }}
              backgroundImage={backgroundImage}
              onUploadFinish={(file) => {
                if (!isUserAccount) return;
                const imageUrl = file.signedUrl.split('?')[0];
                UserAccount.changeUserImage(imageUrl);
              }}
            />
          ) : (<div style={{
            fontSize: small && Constants.SmallFontSize || Constants.SemiLargeFontSize,
            width: imageSize,
            height: imageSize,
            borderRadius: '50%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontWeight: 'bold'
          }}>{initials}</div>)}
          {disableEditing && <ClickToGoToAccount onClick={this.goToAccount}></ClickToGoToAccount>}
        </div>}
        {!hideProfile && theyBlocked && <ProfileInfo style={{ padding: nameOnly && '0' || small && '0 0 0 0.5rem' || null, width: small && '100%' }}>
          <UsernameFullname style={{ flexDirection: mobile && !small && 'column-reverse' || null, width: small && '100%' }}>
            <div onClick={this.goToAccount}>
              <div style={{ fontSize: small && Constants.SmallFontSize || (medium || large) && Constants.SemiSmallFontSize || Constants.SemiLargeFontSize, textAlign: 'left' }}>{username}</div>
              {!nameOnly && verified && <img src="https://logictrystatic.s3.amazonaws.com/images/verifiedbadge072223.png" />}
              {!nameOnly && logicCircleMember && <img style={{ width: 26, height: 26, marginLeft: 12 }} src="https://logictryuserfiles.s3.amazonaws.com/5cf13c4899397a33fd191007/b3af86d02b00ba06b5b30f9cbeb2c5c4/logiccircle.png" />}
            </div>
            <div style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
              {!hideFollow && !(theyBlocked && theyBlocked[0]) && (isUserAccount ? (
                <Stats>
                  {(!small && !medium && !large) && <ProfileButton style={editProfileButtonStyle} onClick={this.accountSettings}>Edit Profile</ProfileButton>}
                </Stats>
              ) : (
                <Stats>
                  {(youBlocked && youBlocked[0]) && <ProfileButton style={followButtonStyle} onClick={() => this.block()}>Unblock</ProfileButton>
                    || <ProfileButton style={followButtonStyle} onClick={() => this.follow(privateAccount)}>{overrideRelationship || (relationshipFound && (relationship ? (relationship.requested ? 'Requested' : '✓ Following') : 'Follow') || <span>&nbsp;</span>)}</ProfileButton>}
                </Stats>
              ))}
              {!small && isLoggedIn && !isUserAccount && !(theyBlocked && theyBlocked[0]) && <EllipsisIcon
                style={{ width: 14, height: 14, marginLeft: 8 }}
                onClick={(e) => { e.preventDefault(); e.stopPropagation(); this.anchorEl = e.target; this.setState({ menuOpen: true }); }}
              />}
            </div>
            {!!menuOpen && <Popup
              onClose={() => this.setState({ menuOpen: false })}
              allowBackgroundClose
              anchorEl={this.anchorEl}
            >
              <MenuWrapper>
                <MenuList>
                  {isDevAccount && <MenuItem onClick={(e) => { e.preventDefault(); e.stopPropagation(); this.verifyAccount(); this.setState({ menuOpen: false }); }}>
                    <div>{verified ? 'Unverify' : 'Verify'}</div>
                  </MenuItem>}
                  <MenuItem onClick={(e) => { e.preventDefault(); e.stopPropagation(); this.setState({ menuOpen: false, blockUser: true }); }}>
                    <div>{(youBlocked && youBlocked[0]) && 'Unblock User' || 'Block User'}</div>
                  </MenuItem>
                  <MenuItem onClick={(e) => { e.preventDefault(); e.stopPropagation(); this.setState({ menuOpen: false, flagUser: true }); }}>
                    <div>{(youFlagged && youFlagged[0]) && 'Unflag User' || 'Flag User'}</div>
                  </MenuItem>
                </MenuList>
              </MenuWrapper>
            </Popup>}
          </UsernameFullname>
          {!hideFollow && (youFlagged && youFlagged[0]) && <div>
            <div style={{ background: 'red', color: 'white', padding: '0.25rem 0.5rem', display: 'inline-block' }}>Flagged for review</div>
          </div>}
          {!small && (
            <Bio>
              {!(medium || large) && <div>
                <div onClick={() => this.setState({ showFollowers: 'Followers' })}><b>{shortenLikesCount(followers || 0)}</b> {followers === 1 ? 'follower' : 'followers'}</div>
                <div onClick={() => this.setState({ showFollowers: 'Following' })}><b>{shortenLikesCount(followees || 0)}</b> following</div>
              </div>}
              {!(medium || large) && <div>{user.username && <div onClick={this.goToAccount}>{`@${user.username}`}</div> || isUserAccount && <ProfileButton style={editProfileButtonStyle} onClick={this.accountSettings}>Edit Username</ProfileButton> || <div onClick={this.goToAccount}>{`@${user._id.slice(0, 8)}...`}</div>}</div>}
              <div style={{ whiteSpace: 'pre-wrap', maxWidth: '40rem' }}>{user.description || isUserAccount && <ProfileButton style={{ ...editProfileButtonStyle, marginTop: '0.5rem' }} onClick={this.accountSettings}>Edit Bio</ProfileButton>}</div>
              {!(medium || large) && user.website && <a style={{ marginTop: '0.25rem' }} rel="noopener noreferrer" target="_blank" href={user.website.startsWith('http') ? user.website : `https://${user.website}`}>{user.website}</a>}
            </Bio>
          )}
        </ProfileInfo>}
        {blockUser && ((youBlocked && youBlocked[0]) && <ConfirmDialog
          text="Are you sure you wish to unblock the user?"
          description="They will now be able to follow you."
          open onNo={() => this.setState({ blockUser: false })} onYes={() => this.block()} />
          || <ConfirmDialog
          text="Are you sure you wish to block the user?"
          description="This will unfollow you both from each other and prevent them from following you."
          open onNo={() => this.setState({ blockUser: false })} onYes={() => this.block()} />
        )}
        {flagUser && ((youFlagged && youFlagged[0]) && <ConfirmDialog
          text="Are you sure you wish to unflag the user?"
          description="We will no longer review the user's profile."
          open onNo={() => this.setState({ flagUser: false })} onYes={() => this.flag()} />
          || <Popup
          allowBackgroundClose
          onClose={this.close}
        >
          <FlagWrapper>
            <h2>Are you sure you wish to flag the user?</h2>
            <p>We will review the user's profile and take any neccessary action.</p>
            <p>REQUIRED: Please share what is wrong with the profile.</p>
            <textarea
              id="userprofileheader__flagdescription" maxLength={1000}
              style={{ width: '100%', border: `1px solid ${Constants.LightTextGray}`, minHeight: 200, padding: 12 }}
            />
            <FlagButtonsWrapper>
              <Button onClick={() => this.setState({ flagUser: false })} color="primary" autoFocus>
                Cancel
              </Button>
              <Button onClick={() => this.flag()} color="primary" autoFocus>
                Save
              </Button>
            </FlagButtonsWrapper>
          </FlagWrapper>
        </Popup>
        )}
        {showFollowers && <LogicBaseUsersPopup
          content={showFollowers}
          id={user._id}
          onClose={(e) => { if (e) { e.preventDefault(); e.stopPropagation(); } this.setState({ showFollowers: false })} }
        />}
      </ProfileSection>
    );
  }
}
