/* eslint-disable react/no-danger */
import React from 'react';
import styled from 'styled-components';
import { PropTypes } from 'prop-types';
import Constants from '../../submodules/logictry_config/constants';
import InfoPopup from './InfoPopup';
import UserAnswer from './UserAnswer';
import UserAccount from '../../services/UserAccount';
import GrayOut from '../../services/TreeDisplay';
// eslint-disable-next-line import/no-cycle
import CreateChildren from './CreateChildren';
import RawHtml from '../../components/RawHtml/index';
import KeywordCount from './KeywordCount';
import { DATE_TIME_ATTR, TIME_ATTR, DATE_ATTR } from '../../models/nodeattributes';
import AnswerAnalytics from './AnswerAnalytics';
import AddNote from './AddNote';
import { CheckedBoxIcon, UncheckedBoxIcon } from '../../styledhtml/Icon';

const Wrapper = styled.div`
  height: 100%;
  .question-box-hide-till-hover {
    opacity: 0;
  }
  &:hover {
    .question-box-hide-till-hover {
      opacity: 1 !Important;
    }
  }
`;
const AnswerWrapper = styled.div`
  display: flex;
  height: 100%;
  &:hover {
    opacity: 1 !Important;
  }
  &:hover {
    > div:last-child {
      opacity: 1 !Important;
    }
  }
`;
const MultipleChoiceAnswer = styled.div`
  display: flex;
`;
const CenterWrapper = styled.div`
  display: flex;
  align-items: center;
`;
const SVG = styled.div`
  min-width: 40px;
  width: 40px;
  min-height: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const EnterListener = styled.div`
  margin: ${Constants.defaultLinePadding} 0px;
`;


export default class AnswerBox extends React.PureComponent {
  static propTypes = {
    answer: PropTypes.object,
  }
  componentDidMount() {
    const { answer } = this.props;
    answer.onStateUpdate(this);
    if (answer.displayText) answer.displayText.onStateUpdate(this);
    GrayOut.onStateUpdate(this);
  }
  componentWillUnmount() {
    const { answer } = this.props;
    answer.offStateUpdate(this);
    if (answer.displayText) answer.displayText.offStateUpdate(this);
    GrayOut.offStateUpdate(this);
  }
  onFocus = () => {
    GrayOut.addActiveObject(this.props.answer);
  }
  getAnswerChildren = (checked, answer) => {
    const { editing } = GrayOut;
    if (!checked && !editing) return null;
    return <CreateChildren node={answer} />;
  }
  collapse = (e) => {
    e.preventDefault();
    e.stopPropagation();
    GrayOut.setExpanded(this.props.answer, false);
  };
  expand = (e) => {
    e.preventDefault();
    e.stopPropagation();
    GrayOut.setExpanded(this.props.answer, true);
  };
  handleClick = (e, answer, question) => {
    e.stopPropagation();
    e.preventDefault();

    GrayOut.addActiveObject(answer);
    const { editing } = GrayOut;
    if (editing) return;

    answer.toggleUserVote();
    GrayOut.setExpanded(answer, answer.checked);
    if (question.goToNextOnSelection && !question.multipleAnswers) {
      setTimeout(() => {
        if (answer.checked) GrayOut.goToNextQuestion();
      }, answer.updateRate);
    }

    if (this.answerRef) this.answerRef.focus();
    else if (document.activeElement) document.activeElement.blur();
  }
  
  render() {
    const { answer } = this.props;
    const question = answer.parent;
    const { editing, tree } = GrayOut;
    const { root, multipleProjects, isDisabled, template } = tree;
    const { showInlineNotes, saveOnSubmit, primaryColor } = root;
    const { text, userInputType, multipleAnswers, preventSelections, hideRadioButtons, showChildrenAsSiblings } = question;
    const { info, checked, keywordsFound, note, filter, isUserAnswer, isNodeHidden, isTextHidden } = answer;
    let answerText = answer.formattedProjectText;
    const showAnswerSelections = !isUserAnswer && !preventSelections && !hideRadioButtons;
    const marginLeft = (showAnswerSelections) ? Constants.TreeContentHalfIndent : Constants.TreeContentIconIndent;
    const childrenMarginLeft = (showAnswerSelections) ? 12 : 0;
    const flex = (![DATE_TIME_ATTR, DATE_ATTR, TIME_ATTR].includes(userInputType) && (editing || isUserAnswer)) ? 1 : null;
    const pointerEvents = isDisabled ? 'none' : null;
    const onMouseDown = (e) => this.handleClick(e, answer, question);

    if (!editing && isNodeHidden) return null;
    const iconStyle = { width: 16, height: 16, margin: 12, backgroundColor: primaryColor };
    if (!editing && isTextHidden) {
      const answerChildren = this.getAnswerChildren(true, answer);
      return (
        <Wrapper style={{ marginLeft }}>
          <AnswerWrapper>
            <MultipleChoiceAnswer style={{ flex: 1 }} onMouseDown={hideRadioButtons ? onMouseDown : null}>
              <CenterWrapper>
                {(showAnswerSelections) && (multipleAnswers ? (
                  <div
                    style={{ pointerEvents, zIndex: 1, cursor: 'pointer' }}
                    onFocus={this.onFocus}
                    onMouseDown={onMouseDown}
                  >
                    {checked ? <CheckedBoxIcon
                      style={iconStyle}
                    /> : <UncheckedBoxIcon
                      style={iconStyle}
                    />}
                  </div>
                ) : (
                  <SVG
                    ref={(e) => { this.answerRef = e; }}
                    style={{ pointerEvents, zIndex: 1 }}
                    onFocus={this.onFocus}
                    tabIndex={-1}
                    role={editing ? '' : 'button'}
                    onMouseDown={onMouseDown}
                  >
                    <svg
                      style={{ display: 'block' }}
                      height="16"
                      width="16"
                      viewBox="0 0 16 16"
                    >
                      <circle
                        className="radioOutline"
                        cx="8"
                        cy="8"
                        r="7"
                        fill="none"
                        stroke={primaryColor}
                        strokeWidth="1.5"
                      />
                      {checked && <circle className="radioDot" cx="8" cy="8" r="4" fill={primaryColor} />}
                    </svg>
                  </SVG>
                ))}
              </CenterWrapper>
              {multipleProjects && checked && <CenterWrapper><AnswerAnalytics answer={answer} /></CenterWrapper>}
              {(editing || !showChildrenAsSiblings) && <div style={{ marginLeft: -28, width: '100%' }}>{answerChildren}</div>}
            </MultipleChoiceAnswer>
          </AnswerWrapper>
        </Wrapper>
      );
    }

    const answerChildren = this.getAnswerChildren(checked, answer);
    if (hideRadioButtons) answerText = `<div style="${checked ? `box-shadow: 0 0 1rem ${primaryColor}; ` : ''}border-radius: 0.5rem; cursor: pointer;">${answerText}</div>`;

    const isEmail = !editing && template && UserAccount.isLoggedIn && saveOnSubmit === `{{${text}}}`;
    if (isEmail) {
      if (answerText !== UserAccount.account.email) {
        answerText = UserAccount.account.email;
        answer.updateText(answerText);
      }
    }
    return (
      <Wrapper style={{ marginLeft }}>
        <AnswerWrapper>
          <MultipleChoiceAnswer style={{ flex }} onMouseDown={hideRadioButtons ? onMouseDown : null}>
            <CenterWrapper>
              {(showAnswerSelections) && (multipleAnswers ? (
                <div
                  style={{ pointerEvents, cursor: 'pointer' }}
                  onFocus={this.onFocus}
                  onMouseDown={onMouseDown}
                >
                  {checked ? <CheckedBoxIcon
                    style={iconStyle}
                  /> : <UncheckedBoxIcon
                    style={iconStyle}
                  />}
                </div>
              ) : (
                <SVG
                  ref={(e) => { this.answerRef = e; }}
                  style={{ pointerEvents }}
                  onFocus={this.onFocus}
                  tabIndex={-1}
                  role={editing ? '' : 'button'}
                  onMouseDown={onMouseDown}
                >
                  <svg
                    style={{ display: 'block' }}
                    height="16"
                    width="16"
                    viewBox="0 0 16 16"
                  >
                    <circle
                      className="radioOutline"
                      cx="8"
                      cy="8"
                      r="7"
                      fill="none"
                      stroke={primaryColor}
                      strokeWidth="1.5"
                    />
                    {checked && <circle className="radioDot" cx="8" cy="8" r="4" fill={primaryColor} />}
                  </svg>
                </SVG>
              ))}
            </CenterWrapper>
            {multipleProjects && checked && <CenterWrapper><AnswerAnalytics answer={answer} /></CenterWrapper>}
            <EnterListener style={{ minHeight: 21, flex, display: 'flex', pointerEvents }}>
              {(!isEmail && ((answer.owner || !answer.text) || editing)) ? (
                <UserAnswer question={question} answer={answer} />
              ) : (
                <RawHtml onMouseDown={showAnswerSelections && onMouseDown || null} className="logictry-answer__text" html={answerText} />
              )}
            </EnterListener>
          </MultipleChoiceAnswer>
          {!editing && keywordsFound && keywordsFound.size > 0 && <InfoPopup text={`<h3 style="text-decoration: underline;">Keywords Found</h3><p>${([...keywordsFound]).join(', ')}</p>`}><KeywordCount style={{ backgroundColor: primaryColor }}>{keywordsFound.size}</KeywordCount></InfoPopup>}
          {info && !isUserAnswer && <InfoPopup text={info.text} />}
          <div className="question-box-hide-till-hover">
            {showInlineNotes && !isDisabled && !editing && !filter && !isUserAnswer && checked && !note && <AddNote node={answer} />}
          </div>
        </AnswerWrapper>
        {(editing || !showChildrenAsSiblings) && <div style={{ marginLeft: childrenMarginLeft }}>{answerChildren}</div>}
      </Wrapper>
    );
  }
}
