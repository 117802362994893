/* eslint-disable react/no-danger */
/* eslint-disable no-useless-escape */
import React from 'react';
import styled from 'styled-components';
import { PropTypes } from 'prop-types';
import Constants from '../../submodules/logictry_config/constants';
import WindowSize from '../../services/WindowSize';
import GrayOut from '../../services/TreeDisplay';
// eslint-disable-next-line import/no-cycle
import CreateChildren from './CreateChildren';
import { CATEGORY } from '../../models/nodetypes';
import RawHtml from '../../components/RawHtml/index';
import ClickableDiv from '../../components/ClickableDiv/index';
import { FORM_ATTR, HIDE_NAV_BUTTONS_ATTR, SHOW_PROGRESS_ATTR, HIDE_PAGE_TITLES_ATTR, WIKI_LAYOUT_ATTR } from '../../models/nodeattributes';
import ProgressBar from '../../components/ProgressBar';
// eslint-disable-next-line import/no-cycle
import HtmlBox from './HtmlBox';
import { recursivelyCheckIfRequiredNodesEmpty } from '../../models/tree';
import goToReport from './actions/gotoreport';
import Scrollable from '../../components/Scrollable';
import { AngleDownIcon, AngleLeftIcon, AngleRightIcon } from '../../styledhtml/Icon';


const WikiWrapper = styled.div`
  position: relative;
  flex: 1;
  width: 100%;
  height: 100%;

  > div:not(:last-child) {
    margin: ${Constants.defaultLinePadding} 0px ${Constants.defaultLinePadding} ${Constants.TreeContentIconIndent};
    border-radius: ${Constants.BoxRadius}px;
  }
`;
const TableOfContents = styled.div`
  padding: ${Constants.TreeContentHalfIndent} 12px ${Constants.TreeContentHalfIndent} 0px;
  position: absolute;
  top: 0;
  left: 0;
  width: 180px;
  max-height: calc(100% - 19px);
  background-color: ${Constants.White};
  display: flex;
  overflow: hidden;
  box-shadow: ${Constants.BoxShadow};
`;
const Content = styled.div`
  padding: ${Constants.TreeContentHalfIndent} 28px ${Constants.TreeContentHalfIndent} 0px;
  position: absolute;
  right: 0px;
  bottom: 0px;
  background-color: ${Constants.White};
  overflow: hidden;
  box-shadow: ${Constants.BoxShadow};
`;
const SectionWrapper = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  width: 100%;
  > div {
    flex: 1;
    width: 100%;
  }
  > i {
    padding: 0.5rem;
  }
`;
const BackNextButtons = styled.div`
  > div:first-child {
    display: flex;
    justify-content: space-between;
    align-items: center;
    > div {
      > div {
        display: flex;
        align-items: center;
      }
    }
    > div:nth-child(2) {
      margin: 0 1rem;
      flex: 1;
    }
    > div:last-child {
      display: flex;
      justify-content: flex-end;
    }
  }
`;
const Title = styled.div`
  margin: 20px 0px;
  text-align: center;
`;
const BackNextButtonsRelative = styled(BackNextButtons)`
  margin: 0 -12px 0 ${Constants.TreeContentHalfIndent};
`;
const BackNextButtonsAbsolute = styled(BackNextButtons)`
  position: absolute;
  bottom: 18px;
  height: 48px;
  background-color: white;
`;

const FormContent = styled.div`

`;

const WikiWikiWrapper = styled.div`
  position: relative;
  flex: 1;
`;
const WikiTableOfContents = styled.div`
  position: absolute;
  top: 0px;
  left: 1.75rem;
  width: 200px;
  bottom: 0px;
  border: 1px solid #030303;
  border-right: 0px;
`;
const WikiContent = styled.div`
  position: absolute;
  top: 0px;
  right: 0px;
  left: calc(200px + 1.75rem);
  bottom: 0px;
  border: 1px solid #030303;
`;
const WikiInfoPanel = styled.div`
  position: absolute;
  top: 0px;
  width: 200px;
  right: 0px;
  bottom: 0px;
`;
const I = styled.i``;

export default class WikiBox extends React.PureComponent {
  static propTypes = {
    node: PropTypes.object,
  };
  state = {
    showRequiredText: false,
    expanded: {},
  }
  componentDidMount() {
    const { node } = this.props;
    node.onStateUpdate(this);
    GrayOut.onStateUpdate(this);
    WindowSize.onStateUpdate(this);
    const { tree } = GrayOut;
    tree.onStateUpdate(this.treeUpdated);
  }
  componentWillUnmount() {
    const { node } = this.props;
    node.offStateUpdate(this);
    GrayOut.offStateUpdate(this);
    WindowSize.offStateUpdate(this);
    this.__last = null;
    const { tree } = GrayOut;
    tree.offStateUpdate(this.treeUpdated);
  }
  treeUpdated = () => {
    if (!this.state.showRequiredText) return;
    if (this.__last && this.__last.objectToDisplay) {
      const found = recursivelyCheckIfRequiredNodesEmpty(this.__last.objectToDisplay);
      if (!found) this.setState({ showRequiredText: false });
    }
  }
  setActive = (section, next) => {
    if (next && this.__last && this.__last.objectToDisplay) {
      const found = recursivelyCheckIfRequiredNodesEmpty(this.__last.objectToDisplay);
      if (found) {
        this.setState({ showRequiredText: true });
        return;
      }
    }
    this.state.showRequiredText = false;
    GrayOut.addActiveObject(section.child, true);
  }
  showReport = () => {
    const { tree } = GrayOut;
    const { isDisabled } = tree;
    if (isDisabled) return;
    if (this.__last && this.__last.objectToDisplay) {
      const found = recursivelyCheckIfRequiredNodesEmpty(this.__last.objectToDisplay);
      if (found) {
        this.setState({ showRequiredText: true });
        return;
      }
    }
    goToReport();
  }
  onRef = (e) => GrayOut.addScrollableParent(e);
  updateWidth = () => {
    this.forceUpdate();
  }
  render() {
    const { tablet } = WindowSize;
    const { node } = this.props;
    const { showRequiredText } = this.state;
    const { activeObject, activeObjectParents, lastActiveObject, lastActiveObjectParents } = GrayOut;
    const { sections, contentFormatOption, showProgressOption, navButtonsOption, pageTitlesOption, showInfoPanel, hideSubmitButton } = node;

    let dontDisplayCategories;
    let previousSection;
    let objectToDisplay;
    let nextSection;
    let objectToDisplayParents;
    if (activeObject || lastActiveObject) {
      const objectsToTest = (activeObject ? [...activeObjectParents, activeObject] : [...lastActiveObjectParents, lastActiveObject]).reverse();
      let sectionToFind;
      const objectIndex = objectsToTest.findIndex((o) => {
        const findSectionIndex = sections.findIndex((s) => s.child === o);
        if (findSectionIndex < 0) return null;
        const findSection = sections[findSectionIndex];
        previousSection = sections[findSectionIndex - 1];
        sectionToFind = findSection;
        nextSection = sections[findSectionIndex + 1];
        return sectionToFind;
      });
      if (objectIndex >= 0) {
        objectToDisplay = objectsToTest[objectIndex];
        objectToDisplayParents = objectsToTest.slice(objectIndex + 1).reverse();
        dontDisplayCategories = sectionToFind.dontDisplayCategories;
      }
    }

    // Scroll to Top if the new object to display is different from the last
    if (this.__last && objectToDisplay && objectToDisplay !== this.__last.objectToDisplay) GrayOut.scrollTop(true);

    // If no objectToDisplay was found, set to the last objectToDisplay
    if (!objectToDisplay && this.__last && this.__last.objectToDisplay) {
      objectToDisplay = this.__last.objectToDisplay;
      objectToDisplayParents = this.__last.objectToDisplayParents;
      previousSection = this.__last.previousSection;
      nextSection = this.__last.nextSection;
    }
    if (!objectToDisplay || !objectToDisplayParents) {
      const firstSection = sections[0];
      nextSection = sections[1];
      if (firstSection) {
        objectToDisplay = firstSection.child;
        objectToDisplayParents = firstSection.parents;
        dontDisplayCategories = firstSection.dontDisplayCategories;
      }
    }

    if (!objectToDisplay && !this.__last) return null;
    this.__last = {
      objectToDisplay,
      objectToDisplayParents,
      previousSection,
      nextSection
    };

    const showNavButtons = navButtonsOption !== HIDE_NAV_BUTTONS_ATTR;
    const showPageTitles = pageTitlesOption !== HIDE_PAGE_TITLES_ATTR;
    const showProgressBar = showProgressOption === SHOW_PROGRESS_ATTR;
    let progress;
    if (showProgressBar) {
      const currentSectionIndex = sections.findIndex((s) => s.child === objectToDisplay);
      progress = (currentSectionIndex / sections.length) * 100;
    }
    const { primaryColor } = GrayOut.tree.root;
    const iconStyle = { width: 12, height: 12, margin: 14 };

    const BackNextButtonsToRender = contentFormatOption === FORM_ATTR ? BackNextButtonsRelative : BackNextButtonsAbsolute;
    const BackNextButtonsComponent = showNavButtons && (
      <BackNextButtonsToRender key={objectToDisplay && objectToDisplay.key} style={{
        right: showInfoPanel ? '252px' : '16px',
        left: tablet ? '44px' : '256px'
      }}>
        <div>
          {previousSection ? (
            <ClickableDiv onClick={() => { this.setActive(previousSection); }}><div>
              <AngleLeftIcon style={iconStyle} />
            <RawHtml html="Back" /></div></ClickableDiv>
          ) : (
            <div></div>
          )}
          <div>
            {showProgressBar && (
              <ProgressBar progress={progress} primaryColor={primaryColor} />
            )}
          </div>
          {nextSection ? (
            <ClickableDiv onClick={() => { this.setActive(nextSection, true); }}><div><RawHtml html="Next" />
              <AngleRightIcon style={iconStyle} />
            </div></ClickableDiv>
          ) : (
            !hideSubmitButton && <ClickableDiv onClick={() => { this.showReport() }}>
              <div>
                <RawHtml html="Submit" />
                <AngleRightIcon style={iconStyle} />
              </div>
            </ClickableDiv>
          )}
        </div>
        {showRequiredText && (
          <div style={{ width: '100%', textAlign: 'center', color: 'red', marginTop: -8 }}>* Answer(s) required above</div>
        )}
      </BackNextButtonsToRender>
    ) || <div></div>;

    if (contentFormatOption === FORM_ATTR) {
      return (
        <FormContent>
          <CreateChildren node={objectToDisplay} dontDisplay={dontDisplayCategories ? [CATEGORY] : []} />
          {BackNextButtonsComponent}
        </FormContent>
      )
    }

    const wikiLayout = contentFormatOption === WIKI_LAYOUT_ATTR;

    const tableOfContents = sections.map((section, i) => {
      const isExpanded = this.state.expanded[i];
      if (wikiLayout) {
        const _previousParentIndex = sections.findIndex((_parent, j) => j < i && _parent.level < section.level);
        const hideSection = _previousParentIndex >= 0 && !this.state.expanded[_previousParentIndex];
        if (hideSection) return null;
      }
      const active = section.child === objectToDisplay;
      const paddingLeft = (active ? 11 : 16) + section.level * 10;
      const style = wikiLayout ? {
        borderLeft: (active ? `5px solid ${primaryColor}` : 'unset'),
        paddingTop: 5 - section.level * 2,
        paddingBottom: 5 - section.level * 2,
        paddingLeft,
        fontSize: `${(0.0875 * (10 - section.level * 0.8))}rem`,
      } : {
        borderLeft: (active ? `5px solid ${primaryColor}` : 'unset'),
        paddingTop: 9.5 - section.level * 2,
        paddingBottom: 9.5 - section.level * 2,
        paddingLeft,
        fontSize: `${(0.0875 * (10 - section.level * 0.8))}rem`,
      };
      const _nextSection = sections[i + 1];
      const children = _nextSection && _nextSection.level > section.level;
      return <SectionWrapper role="button" key={section.child.key} onClick={() => { GrayOut.addActiveObject(section.child); }}>
        <RawHtml style={style} html={section.child.text} />
        {wikiLayout && children && (isExpanded ? <AngleDownIcon
          style={iconStyle}
          onClick={(e) => { e.stopPropagation(); e.preventDefault(); this.state.expanded[i] = !isExpanded; this.forceUpdate() }}
        />
        : <AngleRightIcon
          style={iconStyle}
          onClick={(e) => { e.stopPropagation(); e.preventDefault(); this.state.expanded[i] = !isExpanded; this.forceUpdate() }}
        />)}
      </SectionWrapper>;
    });

    const content = <div style={{ height: showPageTitles ? 'calc(100% - 3.8rem)' : (showNavButtons ? 'calc(100% - 2rem)' : 'calc(100%)') }}>
      <CreateChildren node={objectToDisplay} dontDisplay={dontDisplayCategories ? [CATEGORY] : []} />
    </div>;

    if (wikiLayout) {
      return (
        <WikiWikiWrapper>
          <WikiTableOfContents>
            <Scrollable
              vertical
              style={{ position: 'absolute', top: 0, right: 0, bottom: 0, left: 0 }}
            >
              {tableOfContents}
            </Scrollable>
          </WikiTableOfContents>
          <WikiContent>
            <Scrollable
              vertical
              scrollRef={this.onRef}
              style={{ position: 'absolute', top: 0, right: 0, bottom: 0, left: 0 }}
            >
              {content}
            </Scrollable>
          </WikiContent>
        </WikiWikiWrapper>
      );
    }

    return (
      <WikiWrapper>
        {!tablet && <TableOfContents>
          <Scrollable
            vertical
            style={{ maxHeight: '100%', display: 'flex', flexDirection: 'column' }}
          >
            {tableOfContents}
          </Scrollable>
        </TableOfContents>}
        <Content
          style={{
            borderBottom: showNavButtons ? '3rem solid white' : null,
            right: showInfoPanel ? 'calc(200px + 2.5rem)' : '0',
            left: tablet ? '0' : '208px',
            top: '0px'
          }}>
          <Scrollable
            vertical
            scrollRef={this.onRef}
            style={{ position: 'absolute', top: Constants.TreeContentHalfIndent, right: '28px', bottom: Constants.TreeContentHalfIndent, left: 0 }}
          >
            {showPageTitles && <Title>
              <RawHtml html={objectToDisplay.text} />
            </Title>}
            {content}
          </Scrollable>
        </Content>
        {showInfoPanel && (
          <WikiInfoPanel>
            <Scrollable
              vertical
              style={{ position: 'absolute', top: 0, right: 0, bottom: 0, left: 0 }}
            >
              {activeObject && activeObject.info && <HtmlBox node={activeObject.info} />}
            </Scrollable>
          </WikiInfoPanel>
        )}
        {BackNextButtonsComponent}
      </WikiWrapper>
    );
  }
}
