/* eslint-disable no-param-reassign */
import ObjectCache from './CacheService';
import UserAccount from '../UserAccount';
import SpreadsheetApi from '../server/SpreadsheetApi';
import Spreadsheet from '../../models/spreadsheet';
import DiffCache from './DiffCache';
// eslint-disable-next-line import/no-cycle
import Company from '../Company';
import AppConstants from '../../submodules/logictry_config/s3_config.json';

class SpreadsheetCache extends ObjectCache {
  initialize() {
    super.initialize();
    /**
     * Diff Cache
     */
    this.diffCache = new DiffCache(AppConstants.S3.signingUrls.spreadsheetversions, AppConstants.S3.buckets.spreadsheetversions);
    this.diffCache.__spreadsheetCacheReferenceForDebugging = this;
    this.diffCache.onUpdateDiffs = this.updateDiffs.bind(this);
  }
  isIdInvalid = (id) => id.length > 28 || id.length < 20;
  api = SpreadsheetApi;
  createNew = () => new Spreadsheet({ _id: UserAccount.account._id }, UserAccount.account._id, [], [], UserAccount.account._id, [[]], null, null, null, null, null, null, null, null, null, Company.company && Company.company._id || '');
  createObject = (_results) => {
    const { isDevAccount } = UserAccount;
    const results = JSON.parse(JSON.stringify(_results));
    return new Spreadsheet(results.editor, results.owner, results.users, results.teams, UserAccount.account._id, results.sheets, results._id, results.title, results.description, results.createdTime, results.updateTime, results.state, results.sheetDiffs, results.version, results.shareSetting, results.company, results.background, isDevAccount);
  }
  revertObject = (_object) => {
    const results = this.objectJson[_object._id];
    this.objects[_object._id] = this.createObject(results);
    this.emitStateUpdate();
  };
  duplicate(_objectModel) {
    const _spreadsheet = this.getObjectJson(_objectModel);
    const duplicate = this.createObject({
      owner: UserAccount.account._id,
      users: [],
      teams: [],
      sheets: _spreadsheet.sheets,
      title: `Copy of ${_spreadsheet.title}`,
      description: _spreadsheet.description,
    });
    if (Company.company && Company.company._id) duplicate.company = Company.company._id;
    this.create(duplicate);
  }
  updateDiffs(_id, _diffs) {
    const spreadsheet = this.objects[_id];
    // eslint-disable-next-line no-param-reassign
    spreadsheet.sheetDiffs = [_diffs];
    this.update(spreadsheet);
  }
  async updateObject(newObject, objectModel) {
    let updatesToSyncLocally;
    if (newObject.sheets) {
      objectModel.createVersion();

      if (newObject.version === objectModel.version) {
        // eslint-disable-next-line no-param-reassign
        delete newObject.sheets;
      } else {
        // Check paging which will upload to s3 the page
        const results = await this.diffCache.checkDiffPaging(objectModel._id, objectModel.version, objectModel.sheetDiffs[0] || [], true);
        // If no results, then the update failed and dont save
        if (!results) {
          this.onUpdateFail();
          return null;
        }

        // Update the objectModel
        const { keep, page } = results;

        objectModel.sheetDiffs = [keep];
        newObject.version = objectModel.version;
        newObject.sheetDiffs = JSON.parse(JSON.stringify([keep]));

        // If there is not paging, reduce the payload
        if (!page || page.length === 0) {
          // Sending just the newSheetDiff but syncing locally is different
          updatesToSyncLocally = JSON.parse(JSON.stringify(newObject));

          newObject.newSheetDiff = keep[0];
          if (newObject.newSheetDiff && newObject.newSheetDiff.delta) {
            delete newObject.sheets;
          }
          delete newObject.sheetDiffs;
        }
      }
    }
    return updatesToSyncLocally;
  }
  getObjectJson = (_model) => _model.getJson();
  __checkDiffPaging(newObject) {
    // Check the Diff Paging
    if (newObject.isOwnerOrAdmin && newObject.isEditor) this.diffCache.checkDiffPaging(newObject._id, newObject.version, newObject.sheetDiffs[0] || []);
  }
}

const singleton = new SpreadsheetCache();
singleton.initialize();
let _lastAccountState;
UserAccount.onStateUpdate(() => {
  if (UserAccount.state === 'loggedOut' && _lastAccountState !== 'loggedOut') {
    singleton.initialize();
  } else if (UserAccount.state === 'loggedIn' && _lastAccountState !== 'loggedIn') {
    singleton.initialize();
  }
  _lastAccountState = UserAccount.state;
});
export default singleton;
