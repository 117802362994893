import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Constants from '../../submodules/logictry_config/constants';
import User from '../../models/user';

const Button = styled.div`
  display: flex;
  justify-content: center;
`;
const ProfileAvatar = styled.div`
  position: relative;
  text-align: center;
  border-radius: 50%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  overflow: hidden;
  font-size: ${Constants.SemiLargeFontSize};
`;

export default class UserAvatar extends React.PureComponent {
  static propTypes = {
    user: PropTypes.object,
    onClick: PropTypes.func,
    buttonRef: PropTypes.func,
    showShadow: PropTypes.bool,
    mini: PropTypes.bool,
    backgroundColor: PropTypes.string,
    widthAndHeight: PropTypes.number
  }

  render() {
    const { user, buttonRef, onClick, showShadow, mini, widthAndHeight } = this.props;
    const profileSize = widthAndHeight || mini && 22 || 32;
    const backgroundImage = user && (new User(user)).getImage();
    const boxShadow = showShadow ? 'unset' : 'unset';
    return (
      <Button
        ref={buttonRef}
        onClick={onClick}
        style={{ cursor: onClick ? 'pointer' : null }}
      >
        <ProfileAvatar
          style={{
            width: profileSize,
            height: profileSize,
            fontSize: profileSize,
            backgroundImage: `url("${backgroundImage || 'https://logictryuserfiles.s3.amazonaws.com/5cf13c4899397a33fd191007/f9df6454b26ce055592d615f6aec9ec7/avatar.jpg'}")`,
            boxShadow
          }}
        />
      </Button>
    );
  }

}
