/* eslint-disable import/no-cycle */
import React from 'react';
import styled from 'styled-components';
import { PropTypes } from 'prop-types';
import QuestionBox from './QuestionBox';
import CategoryBox from './CategoryBox';
import Category from '../../models/category';
import Question from '../../models/question';
import Branch from '../../models/branch';
import BranchBox from './BranchBox';
import Subtree from '../../models/subtree';
import SubtreeBox from './SubtreeBox';
import Text from '../../models/text';
import TextBox from './TextBox';
import Goto from '../../models/goto';
import GotoBox from './GotoBox';
import FileGroup from '../../models/filegroup';
import FilesBox from './FilesBox';
import Checklist from '../../models/checklist';
import ChecklistBox from './ChecklistBox';
import FilterLogic from '../../models/filterlogic';
import FilterBox from './FilterBox';
import Data from '../../models/data';
import DataBox from './DataBox';
import Filter from '../../models/filter';
import SearchText from '../../models/searchtext';
import SplitPane from '../../models/splitpane';
import SplitPaneBox from './SplitPaneBox';
import SearchLogic from '../../models/searchlogic';
import LogicBox from './LogicBox';
import Search from '../../models/search';
import ChatbotService from '../../services/Chatbot';
import TreeDisplay from '../../services/TreeDisplay';
import SidebarService from '../../services/SidebarService';
import Autofill from '../../models/autofill';
import Note from '../../models/note';
import CalendarLogic from '../../models/calendarlogic';
import Calendar from '../../models/calendar';
import CalendarViewBox from './CalendarViewBox';
import { AUTO_CREATE_LOGIC, CONDITIONAL_OPERATOR, SEARCH_LOGIC, CALENDAR_LOGIC, DATA, AUTOFILL, FILTER_LOGIC, CALCULATION_LOGIC, CONDITIONAL_LOGIC, CALCULATION, CONDITIONAL, PRESENTATION, CALCULATION_OPERATOR, TEXT_LOGIC } from '../../models/nodetypes';
import SearchBox from './SearchBox';
import CalendarMap from '../../models/calendarmap';
import CalendarMapBox from './CalendarMapBox';
import Export from '../../models/export';
import ExportBox from './ExportBox';
import WikiBox from './WikiBox';
import NodeBox from './NodeBox';
import Wiki from '../../models/wiki';
import Action from '../../models/action';
import Comment from '../../models/comment';
import ActionBox from './ActionBox';
import EditableDivWrapper from './EditableDivWrapper';
import Answer from '../../models/answer';
import AnswerBox from './AnswerBox';
import ItemBox from './ItemBox';
import ChecklistItem from '../../models/checklistitem';
import InputBox from './InputBox';
import Pane from '../../models/pane';
import PaneBox from './PaneBox';
import Answerable from '../../models/answerable';
import Modal from '../../models/modal';
import ModalBox from './ModalBox';
import CalculationLogic from '../../models/calculationlogic';
import ConditionalLogic from '../../models/conditionallogic';
import ConditionalOperator from '../../models/conditionaloperator';
import Calculation from '../../models/calculation';
import CalculationBox from './CalculationBox';
import Conditional from '../../models/conditional';
import OperatorBox from './OperatorBox';
import Presentation from '../../models/presentation';
import PresentationBox from './PresentationBox';
import CalculationOperator from '../../models/calculationoperator';
import HtmlBox from './HtmlBox';
import Chart from '../../models/chart';
import ChartBox from './ChartBox';
import Video from '../../models/video';
import VideoBox from './VideoBox';
import TextLogic from '../../models/textlogic';
import AutoCreateLogic from '../../models/autocreatelogic';
import AutoCreateLogicBox from './AutoCreateLogicBox';
import DataTable from '../../models/datatable';
import DataTableBox from './DataTableBox';
import ShowNodeTypeWrapper from './ShowNodeTypeWrapper';
import Chatbot from '../../models/chatbot';
import ChatbotBox from './ChatbotBox';
import CommentBox from './CommentBox';
import Questionable from '../../models/questionable';

const FadeInDiv = styled.div`
  @keyframes fadeInDiv {
    0% {
      opacity:0;
      // transform:translateY(-1rem);
    }
    100% {
      opacity:1;
      // transform:translateY(0rem);
    }
  }
  animation-name: fadeInDiv;
  animation-timing-function: ease-in-out;
  animation-iteration-count: 1;
  animation-duration: .5s;
  animation-delay: 0;
`;

export default class CreateChild extends React.PureComponent {
  static propTypes = {
    node: PropTypes.object,
    dontDisplay: PropTypes.array,
    showAddNewAnswer: PropTypes.bool,
    style: PropTypes.object,
  }
  constructor(props) {
    super();
    const { node } = props;
    this.__parent = node.parent;
  }
  componentDidMount() {
    const { node } = this.props;
    node.onStateUpdate(this);
    SidebarService.onStateUpdate(this);
    TreeDisplay.onStateUpdate(this);
    ChatbotService.onStateUpdate(this);
    this.__parent.onStateUpdate(this);
  }
  componentWillUnmount() {
    const { node } = this.props;
    node.offStateUpdate(this);
    SidebarService.offStateUpdate(this);
    TreeDisplay.offStateUpdate(this);
    ChatbotService.offStateUpdate(this);
    this.__parent.offStateUpdate(this);
    this.__parent = null;
  }
  getNode = () => {
    const { editing } = TreeDisplay;
    const { dontDisplay, node, style } = this.props;
    if (dontDisplay && dontDisplay.find((d) => node.isType(d))) return null;
    if (!editing) {
      if (node instanceof Action) return <ActionBox node={node} />;
      if (node instanceof AutoCreateLogic) return <LogicBox node={node} placeholderText={AUTO_CREATE_LOGIC} />;
      if (node instanceof Calculation) return <CalculationBox node={node} placeholderText={CALCULATION} />;
      if (node instanceof CalculationLogic) return <LogicBox node={node} placeholderText={CALCULATION_LOGIC} />;
      if (node instanceof CalculationOperator) return <OperatorBox node={node} placeholderText={CALCULATION_OPERATOR} />;
      if (node instanceof Calendar) return <CalendarViewBox node={node} />;
      if (node instanceof CalendarLogic) return <LogicBox node={node} placeholderText={CALENDAR_LOGIC} />;
      if (node instanceof CalendarMap) return <CalendarMapBox node={node} />;
      if (node instanceof Chart) return <ChartBox node={node} />;
      if (node instanceof Chatbot) return <ChatbotBox node={node} />;
      if (node instanceof Checklist) return <ChecklistBox checklist={node} />;
      if (node instanceof ConditionalLogic) return <LogicBox node={node} placeholderText={CONDITIONAL_LOGIC} />;
      if (node instanceof Conditional) return <OperatorBox node={node} placeholderText={CONDITIONAL} />;
      if (node instanceof ConditionalOperator) return <OperatorBox node={node} placeholderText={CONDITIONAL_OPERATOR} />;
      if (node instanceof FilterLogic) return <LogicBox node={node} placeholderText={FILTER_LOGIC} />;
      if (node instanceof Modal) return <ModalBox node={node} />;
      if (node instanceof Note) return <TextBox text={node} />;
      if (node instanceof Pane) return <PaneBox pane={node} paneStyle={style} />;
      if (node instanceof Presentation) return <PresentationBox node={node} placeholderText={PRESENTATION} />;
      if (node instanceof Search) return <SearchBox search={node} />;
      if (node instanceof SearchLogic) return <LogicBox node={node} placeholderText={SEARCH_LOGIC} />;
      if (node instanceof SearchText) return <InputBox input={node} />;
      if (node instanceof SplitPane) return <SplitPaneBox splitPane={node} />;
      if (node instanceof Text) return <HtmlBox className="logictry-text__text" node={node} />;
      if (node instanceof TextLogic) return <LogicBox node={node} placeholderText={TEXT_LOGIC} />;
      if (node instanceof Video) return <VideoBox node={node} />;
      if (node instanceof Wiki) return <WikiBox node={node} />;
    }
    if (editing) {
      if (node instanceof Comment) return <CommentBox node={node} />;
    }
    if (node instanceof Answer) return <AnswerBox answer={node} />;
    if (node instanceof AutoCreateLogic) return <AutoCreateLogicBox node={node} />;
    if (node instanceof Autofill) return <DataBox data={node} placeholderText={AUTOFILL} />;
    if (node instanceof Branch) return <BranchBox branch={node} />;
    if (node instanceof CalculationOperator) return <NodeBox node={node} placeholderText={`${CALCULATION_OPERATOR} (${node.typeOption})`} />;
    if (node instanceof Category) return <CategoryBox category={node} />;
    if (node instanceof ChecklistItem) return <ItemBox item={node} />;
    if (node instanceof ConditionalOperator) return <NodeBox node={node} placeholderText={`${CONDITIONAL_OPERATOR} (${node.typeOption})`} />;
    if (node instanceof Data) return <DataBox data={node} placeholderText={DATA} />;
    if (node instanceof DataTable) return <DataTableBox node={node} />;
    if (node instanceof Export) return <ExportBox node={node} />;
    if (node instanceof FileGroup) return <FilesBox files={node} />;
    if (node instanceof Filter) return <FilterBox question={node} />;
    if (node instanceof Goto) return <GotoBox goto={node} />;
    if (node instanceof Question) return <QuestionBox question={node} />;
    if (node instanceof Subtree) return <SubtreeBox subtree={node} />;
    return <NodeBox node={node} />;
  }
  render() {
    const { node, showAddNewAnswer } = this.props;
    this.__parent = node.parent;
    if (!this.__parent) return null;
    const { editing } = TreeDisplay;
    const expanded = TreeDisplay.isExpanded(this.__parent);
    if (editing && !expanded) return null;
    const child = this.getNode();
    if (!editing && !(node instanceof Answerable)) {
      const { isInsideChatbot } = node;
      if (isInsideChatbot) {
        const isNodeToConditionallyRender = node instanceof Category || node instanceof Questionable || node instanceof Note || node instanceof Text || node instanceof Checklist || node instanceof Action || node instanceof Subtree;
        const found = ChatbotService.chainIncludes(node);
        if (isNodeToConditionallyRender && !found) return null;
        return (
          <FadeInDiv
            key={node.key}
          >
            {child}
          </FadeInDiv>
        );
      }
      return child;
    }
    return (
      <EditableDivWrapper showAddNewAnswer={showAddNewAnswer} key={node.key} node={node}>
        <ShowNodeTypeWrapper node={node}>
          {child}
        </ShowNodeTypeWrapper>
      </EditableDivWrapper>
    );
  }
}
