import React from 'react';
// import styled from 'styled-components';

import Constants from '../../submodules/logictry_config/constants';
// import Navigation from '../../services/Navigation';
import UserAccount from '../../services/UserAccount';
import { AccountLinksOpen } from '../../services/Pagination/index';
import Box from './Box';
import DatabaseSettings from '../../components/ObjectSettings/DatabaseSettings';
// import { getAccountDatabasesSearch } from '../../services/Pagination/index';
import CreateLinkPopup from './CreateLinkPopup';
import LinksPopup from '../../components/ObjectSettings/LinksPopup';
import { LinksIcon } from '../../styledhtml/Icon';

export default class LinksBox extends Box {
  name = 'Links';
  missingTitleText = 'New Link';
  paginationService = this.props.paginationService || AccountLinksOpen;
  backgroundColor = Constants.PrimaryColor;
  boxIcon = <LinksIcon style={{ width: 16, height: 16, filter: 'invert(1)' }} />;
  openPopup = (_tree) => <LinksPopup domainObject={_tree} onClose={() => this.setState({ showOpenPopup: null })} />
  createText = 'New Link';
  createNewPopup = () => {
    const { isDevAccount } = UserAccount;
    if (!isDevAccount) return this.setState({ showCreatePopup: false, showUpgradePopup: true });
    return <CreateLinkPopup team={this.props.team} onClose={this.hideCreatePopup} />;
  }
  objectSettings = DatabaseSettings;
}
