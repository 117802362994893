import React from 'react';
// import { PropTypes } from 'prop-types';
import styled from 'styled-components';
import Constants from '../../submodules/logictry_config/constants';
import { FacebookIcon, LinkedinIcon, TwitterIcon } from '../../styledhtml/Icon';

const ShareIcons = styled.div`
  text-align: center;
  font-weight: bold;
  margin-bottom: 4rem;
  > div {
    display: flex;
    justify-content: center;
    gap: 1rem;
    h2 {
      font-size: Constants.LargeFontSize;
    }
    i {
      color: ${Constants.PrimaryColor};
      font-size: ${Constants.LargeFontSize};
      height: 32px;
    }

    svg {
      height: 26px;
      font-size: ${Constants.LargeFontSize};
      fill: ${Constants.PrimaryColor};
    }
  }
`;

export default function ShareOnSocial({ title, text, facebook, twitter, linkedIn }) {
  return (
    <ShareIcons>
      <h2>{title}</h2>
      <div>
        <a target="_blank" href={`https://www.facebook.com/sharer.php?u=${facebook}`}>
          <FacebookIcon style={{ width: 20, height: 20 }} />
        </a>
        <a target="_blank" href={`https://twitter.com/intent/tweet?url=${twitter}&text=${text}&via=logictry&hashtags=logictry`}>
          <TwitterIcon style={{ width: 20, height: 20 }} />
        </a>
        <a target="_blank" href={`https://www.linkedin.com/shareArticle?url=${linkedIn}`}>
          <LinkedinIcon style={{ width: 20, height: 20 }} />
        </a>
      </div>
    </ShareIcons>
  )
}
