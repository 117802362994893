// import React from 'react';
// import styled from 'styled-components';

import Constants from '../../submodules/logictry_config/constants';
import Navigation from '../../services/Navigation';
import { AccountSpreadsheetsOpen, AccountSpreadsheetsInvitedOpen, AccountSpreadsheetsDeleted } from '../../services/Pagination/index';
import Box from './Box';
import SpreadsheetCache from '../../services/cache/SpreadsheetCache';
import SpreadsheetSettings from '../../components/ObjectSettings/SpreadsheetSettings';
import { getAccountSpreadsheetsSearch } from '../../services/Pagination/index';
import { SpreadsheetsIcon } from '../../styledhtml/Icon';

export default class SpreadsheetsBox extends Box {
  name = 'Spreadsheets';
  missingTitleText = 'New Spreadsheet';
  paginationService = this.props.paginationService || this.props.showDeleted && AccountSpreadsheetsDeleted || (this.props.searchText || this.props.filterText) && getAccountSpreadsheetsSearch(this.props.searchText, null, this.props.filterText) || this.props.invitedTo && AccountSpreadsheetsInvitedOpen || AccountSpreadsheetsOpen;
  backgroundColor = Constants.PrimaryColor;
  boxIcon = <SpreadsheetsIcon style={{ width: 16, height: 16, filter: 'invert(1)' }} />;
  create = () => {
    const spreadsheet = SpreadsheetCache.createNew();
    if (this.props.team) spreadsheet.addTeam(this.props.team);
    SpreadsheetCache.create(spreadsheet);
  }
  createText = 'New Spreadsheet';
  open = (_tree) => Navigation.push(`/spreadsheets/${_tree._id}`);
  objectSettings = SpreadsheetSettings;
}
