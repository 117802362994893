/* eslint-disable no-param-reassign */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/anchor-has-content */
import React from 'react';
import styled from 'styled-components';
import { PropTypes } from 'prop-types';
import Constants from '../../submodules/logictry_config/constants';
import GrayOut from '../../services/TreeDisplay';
import TreeCache from '../../services/cache/TreeCache';
import TreeCreateNode from '../../services/TreeCreateNode';
import Navigation from '../../services/Navigation';
import SidebarService from '../../services/SidebarService';
import VersioningList from '../../components/VersioningList';
import Tooltip from '../../components/Tooltip/index';
import SocialShare from '../../components/SocialShare/index';
import TemplateTreeSettings from '../../components/ObjectSettings/TemplateTreeSettings';
import ProjectTreeSettings from '../../components/ObjectSettings/ProjectTreeSettings';
import Scrollable from '../../components/Scrollable/index';
import NodeSettings from '../../components/NodeSettings';
import { ArrowUpIcon, ChatIcon, DeleteIcon, EditIcon, EmbedIcon, FileIcon, HtmlIcon, InfoIcon, LockIcon, PreviewIcon, ReportIcon, SaveIcon, TreeIcon, UnlockIcon, VersionIcon } from '../../styledhtml/Icon';

const Wrapper = styled.div`
  position: absolute;
  right: 0px;
  font-size: ${Constants.SemiLargeFontSize};
  * {
    font-size: 0.8rem;
  }
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  border-left: ${Constants.LightBorder};
  background-color: ${Constants.White};
  h3 {
    text-align: center;
  }
`;
const Buttons = styled.div`
  display: flex;
  width: 100%;
  > * {
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
const AnswerButtons = styled.div`
  display: flex;
  width: 100%;
  > * {
    width: 100%;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
const Divider = styled.div`
  width: 100%;
  background-color: ${Constants.MediumBackground};
  margin: 1rem 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 0.5rem;
  cursor: pointer;
`;
const AccessStatus = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem 1rem 0.25rem 1rem;
  cursor: pointer;
  > i {
    margin-right: 0.5rem;
  }
`;

const LockedMessage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem 0px 0.25rem 0px;
  > i {
    margin-right: 10px;
  }
`;
const TopMenuItems = styled.div`
  padding: 0.5rem 0;
`

export default class Sidebar extends React.PureComponent {
  static propTypes = {
    tree: PropTypes.object,
    saveChanges: PropTypes.func,
    checkout: PropTypes.func,
    checkin: PropTypes.func,
    showEditor: PropTypes.bool,
    showMini: PropTypes.bool,
  };
  componentDidMount() {
    SidebarService.onStateUpdate(this);
    GrayOut.onStateUpdate(this);
    TreeCreateNode.onStateUpdate(this);
    TreeCache.onStateUpdate(this);
    const { tree } = this.props;
    tree.onStateUpdate(this);
  }
  componentWillUnmount() {
    SidebarService.offStateUpdate(this);
    GrayOut.offStateUpdate(this);
    TreeCreateNode.offStateUpdate(this);
    TreeCache.offStateUpdate(this);
    const { tree } = this.props;
    tree.offStateUpdate(this);
  }
  getFormButton(selectType) {
    return <div key="tasks"><Tooltip text="View questions">
      <TreeIcon
        style={{ opacity: (selectType === 'Tree') && '0.3', width: 16, height: 16 }}
        onClick={() => SidebarService.updateSelectType('Tree')}
      /></Tooltip>
    </div>;
  }
  getPdfButton(selectType) {
    return <div key="word">
      <Tooltip text="View report">
        <ReportIcon
          style={{ opacity: (selectType === 'Text') && '0.3', width: 16, height: 16 }}
          onClick={() => SidebarService.updateSelectType('Text')}
        />
      </Tooltip>
    </div>;
  }
  getChatButton(selectType) {
    return <div key="chat">
      <Tooltip text="View chat interface">
        <ChatIcon
          style={{ opacity: (selectType === 'Chat') && '0.3', width: 16, height: 16 }}
          onClick={() => SidebarService.updateSelectType('Chat')}
        />
      </Tooltip>
    </div>;
  }
  getAnswerButtons = () => {
    const { selectType } = SidebarService;
    const { tree } = this.props;
    const { project } = tree;
    const answerButtons = [];
    answerButtons.push(<div key="arrow_up"><Tooltip text="Scroll to top">
      <ArrowUpIcon
        style={{ opacity: (selectType !== 'Tree') && '0.3', width: 16, height: 16 }}
        onClick={this.scrollTop} />
    </Tooltip></div>);
    if (!project) answerButtons.push(<div key="edit"><Tooltip text="Edit">
      <EditIcon
        style={{ width: 16, height: 16 }}
        onClick={() => Navigation.push(`/apps/${tree._id}/edit`)} />
    </Tooltip></div>);
    answerButtons.push(this.getFormButton(selectType));
    answerButtons.push(this.getPdfButton(selectType));
    if (!project) answerButtons.push(this.getChatButton(selectType));
    answerButtons.push(<div key="social_share"><SocialShare tree={tree} /></div>);
    answerButtons.push((tree.type === 'Template' && (
      <TemplateTreeSettings key="settings" object={tree} />
    ) || (
      <ProjectTreeSettings key="settings" object={tree} />
    )))
    return answerButtons;
  }
  getCodeButton = (tree, disableCode, selectType) => <div>
    <Tooltip text="Switch to code">
      <EmbedIcon
        style={{ opacity: (!tree || !tree._id || disableCode || selectType === 'Code') && '0.3', width: 16, height: 16 }}
        onClick={() => SidebarService.updateSelectType('Code')}
      />
    </Tooltip>
  </div>;
  getVersionContent = (marginTop) => {
    const { showVersions, showWysiwyg, showNodeTypes } = SidebarService;
    const { tree } = this.props;
    const { version, diffs, currentVersionDisplayed } = tree;
    if (!version || !diffs) return null;
    const htmlIcon = <div><Tooltip text="Toggle Wysiwyg">
      <HtmlIcon
        style={{ opacity: !showWysiwyg && '0.3', width: 16, height: 16 }}
        onClick={() => SidebarService.updateShowWysiwyg(!showWysiwyg)} />
    </Tooltip></div>;
    const versionIcon = <div>
      <Tooltip text="Toggle Versions">
        <VersionIcon
          style={{ width: 16, height: 16, opacity: !showVersions && '0.3' }}
          onClick={() => SidebarService.updateShowVersions(!showVersions)}
        />
      </Tooltip>
    </div>;
    const showNodeTypesIcon = <div><Tooltip text="Toggle Node Types">
      <InfoIcon
        style={{ width: 16, height: 16, opacity: !showNodeTypes && '0.3' }}
        onClick={() => SidebarService.updateShowNodeTypes(!showNodeTypes)} />
    </Tooltip></div>;

    if (!showVersions) return [
      <AnswerButtons key="select_type_buttons" style={{ marginTop, flexDirection: 'row' }}>
        {htmlIcon}
        {showNodeTypesIcon}
        {versionIcon}
      </AnswerButtons>,
    ];
    return [
      <AnswerButtons key="select_type_buttons" style={{ marginTop, flexDirection: 'row' }}>
        {htmlIcon}
        {showNodeTypesIcon}
        {versionIcon}
      </AnswerButtons>,
      <Divider key="versions_divider"><div style={{ width: '100%', textAlign: 'center' }}>Versions</div></Divider>,
      <VersioningList
        key="versioning_list"
        node={tree}
        diffs={diffs}
        version={version}
        currentVersionDisplayed={currentVersionDisplayed}
        showVersion={this.showVersion}
        diffCache={TreeCache.diffCache}
      />
    ]
  }
  scrollTop = () => GrayOut.scrollTop();
  showVersion = (version, deltas, index) => {
    const { tree } = this.props;
    tree.showVersion(version, deltas.slice(0, index));
    GrayOut.clearAllActiveObjects();
  }
  viewAsProject = () => {
    const { tree } = this.props;
    if (!tree._id) return;
    Navigation.push(`${Navigation.apps}/${tree._id}`);
  }
  viewAsTemplate = () => {
    const { tree } = this.props;
    Navigation.push(`${Navigation.apps}/${tree._id}/edit`);
  }
  createProject = () => {
    const { tree } = this.props;
    Navigation.push(`${Navigation.apps}/${tree._id}?autocreate`);
  }
  render() {
    const { saveChanges, showEditor, tree, checkout, checkin, showMini } = this.props;
    const { isEditor, isCheckoutAllowed, isCheckedOut, isCheckinAllowed, isOwnerOrAdmin, isLocked, isDeleted } = tree;
    const { activeObject, activeObjectParents } = GrayOut.activeOrLastActiveObjectAndParents();
    const { selectType, showVersions } = SidebarService;
    const showActiveObjectButtons = activeObject && activeObjectParents && selectType === 'Tree';

    if (showMini) return null;

    if (showEditor) {
      const top = 0;
      const width = 240;
      const height = '100%';
      const borderBottomLeftRadius = 0;
      const borderTopLeftRadius = 0;
      const marginTop = 10;
      const overflowY = null;
      // const disableGraph = !tree || !tree._id || selectType === 'Graph';
      const textHtmlWysiwygLine = this.getVersionContent(marginTop);

      const scrollTopIcon = () => <div><Tooltip text="Scroll to top">
        <ArrowUpIcon
          style={{ width: 16, height: 16, opacity: (selectType !== 'Tree') && '0.3' }}
          onClick={this.scrollTop} />
      </Tooltip></div>;
      const saveIcon = (disabled) => <div>
        <Tooltip text="Save">
          <SaveIcon
            onClick={() => saveChanges()}
            style={{ width: 16, height: 16, opacity: disabled && '0.3' }} />
        </Tooltip>
      </div>;
      const previewIcon = () => <div>
        <Tooltip text="Preview">
          <PreviewIcon onClick={() => this.viewAsProject()} style={{ width: 16, height: 16, opacity: !tree._id && '0.3' }} />
        </Tooltip>
      </div>;
      const treeIcon = () => <div>
        <Tooltip text="Switch to tree">
          <TreeIcon onClick={() => SidebarService.updateSelectType('Tree')} style={{ width: 16, height: 16, opacity: selectType === 'Tree' && '0.3' }} />
        </Tooltip>
      </div>;
      const graphIcon = () => <div>
        <Tooltip text="Switch to doc">
          <FileIcon onClick={() => SidebarService.updateSelectType('Doc')} style={{ width: 16, height: 16, opacity: selectType === 'Doc' && '0.3' }} />
        </Tooltip>
      </div>;

      if (isDeleted) {
        return (<Wrapper style={{ top, width, height, borderBottomLeftRadius, borderTopLeftRadius, overflowY }}>
          <Scrollable
            vertical
            style={{ position: 'absolute', top: 0, right: 0, bottom: 0, left: 0 }}
          >
            <TopMenuItems>
              <LockedMessage>
                <DeleteIcon style={{ width: 16, height: 16, marginRight: 8 }} />
                <div>Deleted</div>
              </LockedMessage>
              <AnswerButtons style={{ marginTop, flexDirection: 'row' }}>
                {scrollTopIcon()}
                {saveIcon(true)}
                {previewIcon()}
              </AnswerButtons>
              <AnswerButtons style={{ marginTop, flexDirection: 'row' }}>
                {treeIcon()}
                {graphIcon()}
                {this.getCodeButton(tree, false, selectType)}
              </AnswerButtons>
              {textHtmlWysiwygLine}
            </TopMenuItems>
            <div style={{ flex: 1, height: 40 }}></div>
          </Scrollable>
        </Wrapper>);
      }

      if (isLocked) {
        return (<Wrapper style={{ top, width, height, borderBottomLeftRadius, borderTopLeftRadius, overflowY }}>
          <Scrollable
            vertical
            style={{ position: 'absolute', top: 0, right: 0, bottom: 0, left: 0 }}
          >
            <TopMenuItems>
              <LockedMessage>
                <LockIcon style={{ width: 16, height: 16, marginRight: 8 }} />
                <div>Locked</div>
              </LockedMessage>
              <AnswerButtons style={{ marginTop, flexDirection: 'row' }}>
                {scrollTopIcon()}
                {saveIcon(true)}
                {previewIcon()}
              </AnswerButtons>
              <AnswerButtons style={{ marginTop, flexDirection: 'row' }}>
                {treeIcon()}
                {graphIcon()}
                {this.getCodeButton(tree, false, selectType)}
              </AnswerButtons>
              {textHtmlWysiwygLine}
            </TopMenuItems>
            <div style={{ flex: 1, height: 40 }}></div>
          </Scrollable>
        </Wrapper>);
      }
      if (!isEditor) {
        return (<Wrapper style={{ top, width, height, borderBottomLeftRadius, borderTopLeftRadius, overflowY }}>
          <Scrollable
            vertical
            style={{ position: 'absolute', top: 0, right: 0, bottom: 0, left: 0 }}
          >
            <TopMenuItems>
              {isOwnerOrAdmin && isCheckedOut && !isCheckinAllowed && <AccessStatus>
                <LockIcon style={{ width: 16, height: 16, marginRight: 8 }} />
                <div>Already In-use</div>
              </AccessStatus>}
              {isOwnerOrAdmin && !isCheckedOut && isCheckoutAllowed && <AccessStatus onClick={() => checkout()}>
                <LockIcon style={{ width: 16, height: 16, marginRight: 8 }} />
                <div>Start Editing</div>
              </AccessStatus>}
              {!isOwnerOrAdmin && <div style={{ textAlign: 'center', padding: 10 }}>You dont have permission to edit</div>}
              <AnswerButtons style={{ marginTop, flexDirection: 'row' }}>
                {scrollTopIcon()}
                {saveIcon(true)}
                {previewIcon()}
              </AnswerButtons>
              <AnswerButtons style={{ marginTop, flexDirection: 'row' }}>
                {treeIcon()}
                {graphIcon()}
                {this.getCodeButton(tree, false, selectType)}
              </AnswerButtons>
              {textHtmlWysiwygLine}
            </TopMenuItems>
            <div style={{ flex: 1, height: 40 }}></div>
          </Scrollable>
        </Wrapper>);
      }
      if (showVersions) {
        return (
          <Wrapper style={{ top, width, height, borderBottomLeftRadius, borderTopLeftRadius, overflowY }}>
            <Scrollable
              vertical
              showVertical
              style={{ position: 'absolute', top: 0, right: 0, bottom: 0, left: 0 }}
            >
              <TopMenuItems>
                {isOwnerOrAdmin && isCheckedOut && isCheckinAllowed && <AccessStatus onClick={() => checkin()}>
                  <UnlockIcon style={{ width: 16, height: 16, marginRight: 8 }} />
                  <div>Finish Editing</div>
                </AccessStatus>}
                <AnswerButtons style={{ marginTop, flexDirection: 'row' }}>
                  {scrollTopIcon()}
                  {saveIcon(!isEditor || !tree.currentVersionDisplayed)}
                  {previewIcon()}
                </AnswerButtons>
                <AnswerButtons style={{ marginTop, flexDirection: 'row' }}>
                  {treeIcon()}
                  {graphIcon()}
                  {this.getCodeButton(tree, false, selectType)}
                </AnswerButtons>
                {textHtmlWysiwygLine}
              </TopMenuItems>
              <div style={{ flex: 1, height: 40 }}></div>
            </Scrollable>
          </Wrapper>
        );
      }

      return (
        <Wrapper style={{ top, width, height, borderBottomLeftRadius, borderTopLeftRadius, overflowY }}>
          <Scrollable
            vertical
            style={{ position: 'absolute', top: 0, right: 0, bottom: 0, left: 0 }}
          >
            <TopMenuItems>
              {isOwnerOrAdmin && isCheckedOut && isCheckinAllowed && <AccessStatus onClick={() => checkin()}>
                <UnlockIcon style={{ width: 16, height: 16, marginRight: 8 }} />
                <div>Finish Editing</div>
              </AccessStatus>}
              <AnswerButtons style={{ marginTop, flexDirection: 'row' }}>
                {scrollTopIcon()}
                {saveIcon(!isEditor || !tree.currentVersionDisplayed)}
                {previewIcon()}
              </AnswerButtons>
              <AnswerButtons style={{ marginTop, flexDirection: 'row' }}>
                {treeIcon()}
                {graphIcon()}
                {this.getCodeButton(tree, false, selectType)}
              </AnswerButtons>
              {textHtmlWysiwygLine}
            </TopMenuItems>
            {showActiveObjectButtons && showEditor && <NodeSettings key={activeObject && activeObject.key} node={activeObject} />}
            <div style={{ flex: 1, height: 40 }}></div>
          </Scrollable>
        </Wrapper>
      );
    }
    const width = 40;
    const marginTop = 0;
    const buttons = this.getAnswerButtons();
    const top = 0;
    const height = '100%';
    return (
      <div>
        <Wrapper style={{ top, width, height }}>
          <Scrollable
            vertical
            style={{ position: 'absolute', top: 0, right: 0, bottom: 0, left: 0 }}
          >
            <Buttons style={{ marginTop, flexDirection: 'column', justifyContent: 'center' }}>
              {buttons}
            </Buttons>
          </Scrollable>
        </Wrapper>
      </div>
    );
  }
}
