/* eslint-disable */
const fullnameRegex = /^([\u0000-\uFFFF]){0,50}$/;
const passwordRegex = /^([\u0000-\uFFFF]){6,30}$/;
const usernameRegex = /^[a-zA-Z_](?!.*?\.{2})[\w.]{1,28}[\w]$/;
const emailRegex = /(.+)@(.+){1,}\.(.+){1,}/;

export function isFullnameInvalid(f) {
  return (fullnameRegex.test(f) !== true);
}

export function isPasswordInvalid(p) {
  if (passwordRegex.test(p) !== true) return true;
  if (p.indexOf(' ') > -1) return true;
  return false;
}

export function isUsernameInvalid(u) {
  if (usernameRegex.test(u) !== true) return true;
  if (u.indexOf(' ') > -1) return true;
  return false;
}

export function isEmailInvalid(e) {
  if (emailRegex.test(e) !== true) return true;
  if (e.length < 5 || e.length > 256) return true;
  if (e.trim().match(' ')) return true;
  return false;
}

export function isColorInvalid(color) {
  const s = new Option().style;
  s.color = color;
  return s.color === '';
}

export function isUrlValid(url) {
  try {
    // If the URL does not contain a protocol, prepend "https://" temporarily for validation
    const normalizedUrl = url.includes("://") ? url : `https://${url}`;
    const parsedUrl = new URL(normalizedUrl);

    // Ensure the hostname contains at least one dot (e.g., "example.com")
    if (!/\.\w+$/.test(parsedUrl.hostname)) {
      throw new Error("Invalid hostname");
    }

    return normalizedUrl; // URL is valid
  } catch (error) {
    return null; // URL is invalid
  }
}
