import React from 'react';
import styled from 'styled-components';
import { PropTypes } from 'prop-types';
import Constants from '../../submodules/logictry_config/constants';
import LogicBaseUserCache from '../../services/cache/LogicBaseUserCache';
import LogicBaseCollectionCache from '../../services/cache/LogicBaseCollectionCache';
import UserCache from '../../services/cache/UserCache';
import TreeCache from '../../services/cache/TreeCache';
import WindowSize from '../../services/WindowSize';
import UserAccount from '../../services/UserAccount';
import LogicBaseAppList from '../LogicBaseAppList';
import UserProfileHeader from '../UserProfileHeader';
import StatefulTextField from '../StatefulTextField';
import Button from '../Button';
import StatefulButton from '../StatefulButton';
import CollectionsUserPopup from '../CollectionsUserPopup';
import ChangeableImage from '../ChangeableImage';
import AppConstants from '../../submodules/logictry_config/s3_config.json';
import Select from '../../styledhtml/Select';
import convertColorToHex from '../../utils/convertcolortohex';
import ColorPicker from '../ColorPicker';
import Popup from '../Popup';
import { EditIcon } from '../../styledhtml/Icon';
import { isColorInvalid, isUrlValid } from '../../utils/regex';

const Wrapper = styled.div`
  margin: auto;
  max-width: 100%;
  width: 540px;
  padding: 2rem 3rem;
  ${Constants.MediaMobile} {
    padding: 1rem 2rem;
  }
  h1 {
    font-weight: 600;
    color: ${Constants.DarkText};
    text-align: center;
  }
  h2 {
    font-size: ${Constants.NormalFontSize};
  }
  > div:last-child {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
const UserProfile = styled.div`
  margin: 2rem 0;
  color: ${Constants.Gray};
  p {
    margin: 0;
  }
  > div {
    textarea {
      margin-top: 1rem;
      border: 0.0625rem solid rgba(0,0,0,0.42);
      width: 100%;
      opacity: 1;
      height: 120px;
      overflow: hidden;
      resize: none;
      padding: 8px 8px;
    }
    margin: auto;
    > div {
      margin-top: 1rem;
    }
  }
`;

export default class LogicBaseCollectionEditPopup extends React.PureComponent {
  static propTypes = {
    collection: PropTypes.object,
    onClose: PropTypes.func
  }
  state = {
    usersPopup: false,
    error: '',
  }
  componentDidMount() {
    LogicBaseCollectionCache.onStateUpdate(this);
    LogicBaseUserCache.onStateUpdate(this);
    UserCache.onStateUpdate(this);
    UserAccount.onStateUpdate(this);
    WindowSize.onStateUpdate(this);
    TreeCache.onStateUpdate(this);
  }
  componentWillUnmount() {
    LogicBaseCollectionCache.offStateUpdate(this);
    LogicBaseUserCache.offStateUpdate(this);
    UserCache.offStateUpdate(this);
    UserAccount.offStateUpdate(this);
    WindowSize.offStateUpdate(this);
    TreeCache.offStateUpdate(this);
  }
  revert = () => {
    if (this.collection._id) LogicBaseCollectionCache.revertObject(this.collection);
  }
  editCreate = () => {
    const { onClose } = this.props;
    if (!this.collection.title) return this.setState({ error: 'Title Empty' });
    if (this.collection.image && !isUrlValid(this.collection.image)) return this.setState({ error: 'Background Image URL is invalid' });
    if (this.collection.color && isColorInvalid(this.collection.color)) return this.setState({ error: 'Background Color is invalid' });
    if (this.publicApps && this.publicApps.length > 0) {
      this.collection.apps = this.publicApps.map(({ _id }) => _id);
    }
    if (this.collection._id) LogicBaseCollectionCache.update(this.collection);
    else LogicBaseCollectionCache.create(this.collection);
    this.setState({ error: '' });
    onClose();
  }
  backgroundColorChanged = (color) => {
    this.collection.color = color;
    this.forceUpdate();
  }
  render() {
    const { collection, onClose } = this.props;
    const { usersPopup, error } = this.state;
    const { account, isDevAccount } = UserAccount;

    let user;
    let contributors;

    if (!collection) {
      const newCollection = { o: account._id, a: [] };
      this.collection = this.collection || LogicBaseCollectionCache.createObject(newCollection);
    } else {
      this.collection = collection;
    }
    this.publicApps = TreeCache.getByIds(this.collection.apps);
    const users = UserCache.getUsersByIds([this.collection.owner]);
    user = users && users[0];
    contributors = this.collection.editors && UserCache.getUsersByIds(this.collection.editors) || [];
    const s3Path = `${user._id}/`;
    const signingUrl = `/v1${AppConstants.S3.signingUrls.userfiles}`;
    const isOwner = this.collection.owner === account._id;
    return (
      <>
        <Popup
          showCloseButton
          allowBackgroundClose
          showFullscreen
          onClose={onClose}
        >
          <Wrapper>
            <h1>{`${collection ? 'Edit' : 'Create'} Collection`}</h1>
            <UserProfile>
              <div>
                {isOwner && <div>
                  <Select value={this.collection.socialShare && 'public' || 'private'} onChange={(e) => {
                    if (e.target.value === 'public') this.collection.socialShare = true;
                    else this.collection.socialShare = false;
                    this.forceUpdate();
                  }}>
                    <option value="public" disabled={!isDevAccount}>Publicly searchable and discoverable</option>
                    <option value="private">Only visible on your profile</option>
                  </Select>
                </div>}
                {isOwner && <div style={{ margin: '2rem 0' }}>
                  <h2
                    style={{ cursor: 'pointer', fontSize: Constants.SemiSmallFontSize, display: 'flex', alignItems: 'center', gap: '0.5rem' }}
                    onClick={() => this.setState({ usersPopup: true })}
                  >Owner<EditIcon style={{ width: 16, height: 16 }} /></h2>
                  <UserProfileHeader
                    user={{
                      _id: user._id,
                      image: user.image,
                      description: user.description,
                      username: user.username,
                      fullname: user.fullname
                    }}
                    small
                    hideFollow
                  />
                </div>}
                {isOwner && <div style={{ margin: '2rem 0' }}>
                  <h2
                    style={{ cursor: 'pointer', fontSize: Constants.SemiSmallFontSize, display: 'flex', alignItems: 'center', gap: '0.5rem' }}
                    onClick={() => this.setState({ usersPopup: true })}>Editors<EditIcon style={{ width: 16, height: 16 }} /></h2>
                  {contributors.map((editor) => (
                    <div key={editor._id} style={{ margin: '0.5rem 0' }}>
                      <UserProfileHeader
                        user={{
                          _id: editor._id,
                          image: editor.image,
                          description: editor.description,
                          username: editor.username,
                          fullname: editor.fullname,
                        }}
                        small
                        hideFollow
                      />
                    </div>
                  ))}
                </div>}
                <div style={{ display: 'flex', alignItems: 'center', gap: '1rem', height: 48 }}>
                  <StatefulTextField
                    value={this.collection.title}
                    onChange={(title) => { this.collection.title = title; this.forceUpdate(); }}
                    placeholder="Title"
                    maxLength={150}
                    width={'100%'}
                  />
                </div>
                <div style={{ display: 'flex', alignItems: 'center', gap: '1rem', height: 48 }}>
                  <StatefulTextField
                    value={this.collection.description}
                    onChange={(description) => { this.collection.description = description; this.forceUpdate(); }}
                    placeholder="Description"
                    maxLength={500}
                    width={'100%'}
                  />
                </div>
                <div style={{ display: 'flex', alignItems: 'center', gap: '1rem', height: 48 }}>
                  <StatefulTextField
                    value={this.collection.color || ''}
                    onChange={this.backgroundColorChanged}
                    label="Background Color"
                    maxLength={1000}
                    width={'100%'}
                  />
                  <div style={{ position: 'relative', paddingBottom: 8 }}>
                    <ColorPicker
                      onChange={(color) => {
                        this.collection.color = color;
                        this.forceUpdate();
                      }}
                      color={this.collection.color && convertColorToHex(this.collection.color) || Constants.PrimaryColor}
                    />
                  </div>
                </div>
                <div style={{ display: 'flex', alignItems: 'center', gap: '1rem', height: 48 }}>
                  <StatefulTextField
                    value={this.collection.image || ''}
                    onChange={(url) => {
                      this.collection.image = url;
                      this.forceUpdate();
                    }}
                    label="Background Image"
                    maxLength={1000}
                    width={'100%'}
                  />
                  <div style={{ position: 'relative', paddingBottom: 8 }}>
                    <ChangeableImage
                      onlyAllowImage
                      s3Path={s3Path}
                      signingUrl={signingUrl}
                      backgroundImage={this.collection.image}
                      hideUploadText
                      style={{
                        width: 96,
                        height: 54,
                        backgroundSize: 'cover',
                        borderRadius: 8,
                        border: '1px dashed'
                      }}
                      onUploadFinish={(fullUrl) => {
                        const imageUrl = fullUrl.signedUrl.split('?')[0];
                        this.collection.image = imageUrl;
                        this.forceUpdate();
                      }}
                    />
                  </div>
                </div>
                {isDevAccount && isOwner && <div style={{ display: 'flex', alignItems: 'center', gap: '1rem', height: 48 }}>
                  <label style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
                    <input type="checkbox" checked={this.collection.forceSubscribe} onChange={() => { this.collection.forceSubscribe = !this.collection.forceSubscribe; this.forceUpdate(); }}></input>
                    Force subscribe to view collection?
                  </label>
                </div>}
                {this.collection._id && <div style={{ margin: '2rem 0', display: 'flex', justifyContent: 'center', marginRight: 40 }}>
                  {this.publicApps.length > 0 && <LogicBaseAppList
                    apps={this.publicApps}
                    listView
                    allowSorting
                    collection={this.collection._id}
                  />}
                </div>}
              </div>
              {error && <div style={{ color: 'red', textAlign: 'center', margin: '1rem 0 1rem' }}>{error}</div>}
            </UserProfile>
            <div style={{ gap: '2rem', justifyContent: 'space-around' }}>
              <Button onClick={onClose}>Cancel</Button>
              <StatefulButton
                text={collection ? 'Update' : 'Create'}
                onClick={this.editCreate}
                noColor
              />
            </div>
          </Wrapper>
        </Popup>
        {usersPopup && <CollectionsUserPopup
          collection={this.collection}
          onClose={() => {
            this.setState({ usersPopup: false });
          }}
        />}
      </>
    );
  }
}
