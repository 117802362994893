import React from 'react';
import styled from 'styled-components';
import { PropTypes } from 'prop-types';
import UserAccount from '../../services/UserAccount';
import Constants from '../../submodules/logictry_config/constants';

const Wrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  flex: 1;
  min-height: 100%;
  ${Constants.MediaMobile} {
    min-height: calc(100% + 2px);
  }
`;
const FullscreenWrapper = styled(Wrapper)`
  min-height: calc(100vh - 112px);
  min-height: calc(100dvh - 112px);
`

export default class FullScreenVerticalCenterContent extends React.PureComponent {
  static propTypes = {
    children: PropTypes.any,
    alignTop: PropTypes.bool,
    backgroundColor: PropTypes.string,
    fullscreenHeight: PropTypes.bool,
  }
  componentDidMount() {
    UserAccount.onStateUpdate(this);
  }
  componentWillUnmount() {
    UserAccount.offStateUpdate(this);
  }
  render() {
    const padding = Constants.NormalSectionPadding;
    const WrapperToShow = this.props.fullscreenHeight ? FullscreenWrapper : Wrapper;
    return (
      <WrapperToShow
        style={{
          padding,
          justifyContent: this.props.alignTop ? 'unset' : 'center',
          backgroundColor: this.props.backgroundColor
        }}
      >
        {this.props.children}
      </WrapperToShow>
    );
  }
}
