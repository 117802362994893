/* eslint-disable no-useless-escape */
import Node from './node';
import { SHOW_ALL_TEXT, SHOW_ALL_FILTERED_TEXT, ANSWER } from './nodetypes';
import { SINGLE_ANSWER_ATTR, TEXT_ATTR, FIXED_ATTR, OPEN_ENDED_ATTR, DATE_TIME_ATTR, MULTIPLE_ANSWERS_ATTR, NUMBER_ATTR, DROPDOWN_ATTR, DATE_ATTR, TIME_ATTR, SORT_ASCENDING_ATTR, SORT_DESCENDING_ATTR, WYSIWYG_ATTR, HIDE_SHOW_ALL_ATTR, NONE_ATTR, WRAP_ATTR, CENTER_ATTR, REQUIRED_ATTR, HIDE_UNSELECTED_CHOICES_ATTR, EMAIL_ATTR, GO_TO_NEXT_ON_SELECTION, HIDE_RADIO_BUTTONS_ATTR } from './nodeattributes';

const answerQuantity = [SINGLE_ANSWER_ATTR, MULTIPLE_ANSWERS_ATTR];
const userInputTypes = [TEXT_ATTR, NUMBER_ATTR, DROPDOWN_ATTR, DATE_TIME_ATTR, DATE_ATTR, TIME_ATTR, EMAIL_ATTR];
const userInputFormats = [NONE_ATTR, WYSIWYG_ATTR];
const answerChoiceType = [FIXED_ATTR, OPEN_ENDED_ATTR];
const answerQuantityOptions = [{ name: 'Single Answer', value: SINGLE_ANSWER_ATTR }, { name: 'Multiple Answers', value: MULTIPLE_ANSWERS_ATTR }];
const userInputTypesOptions = [{ name: 'Text', value: TEXT_ATTR }, { name: 'Number', value: NUMBER_ATTR }, { name: DROPDOWN_ATTR, value: DROPDOWN_ATTR }, { name: 'Date & Time', value: DATE_TIME_ATTR }, { name: 'Date Only', value: DATE_ATTR }, { name: 'Time Only', value: TIME_ATTR }, { name: 'Email', value: EMAIL_ATTR }];
const userInputFormatOptions = [{ name: 'No Wysiwyg', value: NONE_ATTR }, { name: 'Show Wysiwyg', value: WYSIWYG_ATTR }];
const answerChoiceTypeOptions = [{ name: 'Fixed Choices', value: FIXED_ATTR }, { name: 'Open Ended', value: OPEN_ENDED_ATTR }];

export default class Questionable extends Node {
  constructor(_text, _attributes, _children, _owner, _cloned) {
    /**
     * Children can be answers and categories
     */
    super(_text, _attributes, _children, _owner);
    this.__defineNodeAttribute('required', [{ name: 'Optional', value: NONE_ATTR, default: true }, { name: REQUIRED_ATTR, value: REQUIRED_ATTR }]);
    this.__defineNodeAttribute('hideRadioButtons', [{ name: 'Show Buttons', value: NONE_ATTR, default: true }, { name: 'Hide Buttons', value: HIDE_RADIO_BUTTONS_ATTR }]);
    this.cloned = _cloned;
    this.showRequiredText = false;
    /**
     * Create the updateStateListeners
     */
    this.questionUpdated = () => {
      this.emitStateUpdate();
    };
    /**
     * Add missing attributes
     */
    if (this.attributes.every((_attr) => !userInputTypes.includes(_attr))) {
      this.attributes.push(TEXT_ATTR);
    }
    if (this.attributes.every((_attr) => !answerQuantity.includes(_attr))) {
      this.attributes.push(SINGLE_ANSWER_ATTR);
    }
    if (this.attributes.every((_attr) => !answerChoiceType.includes(_attr))) {
      if (this.answers.length > 0) this.__updateAnswerChoiceType(FIXED_ATTR);
      else this.__updateAnswerChoiceType(OPEN_ENDED_ATTR);
    }
    this.__defineNodeAttribute('sort', [{ name: 'No Sorting', value: NONE_ATTR, default: true }, { name: 'Sort Ascending', value: SORT_ASCENDING_ATTR }, { name: 'Sort Descending', value: SORT_DESCENDING_ATTR }]);
    this.__defineNodeAttribute('wrap', [{ name: 'No Wrap', value: NONE_ATTR, default: true }, { name: 'Wrap', value: WRAP_ATTR }]);
    this.__defineNodeAttribute('justifyContent', [{ name: 'Justify Left', value: NONE_ATTR, default: true }, { name: 'Justify Center', value: CENTER_ATTR }]);
    this.__defineNodeAttribute('selectionNavigation', [{ name: 'Stay On Selection', value: NONE_ATTR, default: true }, { name: 'Go To Next On Selection', value: GO_TO_NEXT_ON_SELECTION }]);
    this.__defineNodeAttribute('choicesVisibility', [{ name: 'Always Show Choices', value: NONE_ATTR, default: true }, { name: 'Hide Unselected Choices', value: HIDE_UNSELECTED_CHOICES_ATTR }]);
  }
  get hideRadioButtons() {
    return this.hideRadioButtonsOption === HIDE_RADIO_BUTTONS_ATTR;
  }
  get wrap() {
    return this.attributes.includes(WRAP_ATTR);
  }
  get justifyContentCenter() {
    return this.attributes.includes(CENTER_ATTR);
  }
  get hideShowAll() {
    return this.attributes.includes(HIDE_SHOW_ALL_ATTR);
  }
  get sortAscending() {
    return this.attributes.includes(SORT_ASCENDING_ATTR);
  }
  get sortDescending() {
    return this.attributes.includes(SORT_DESCENDING_ATTR);
  }
  get isRequired() {
    return this.attributes.includes(REQUIRED_ATTR);
  }
  get answerQuantityOptions() {
    return answerQuantityOptions;
  }
  get userInputFormatOptions() {
    return userInputFormatOptions;
  }
  get answerChoiceTypeOptions() {
    return answerChoiceTypeOptions;
  }
  get answerChoiceType() {
    return this.attributes.find((_attr) => answerChoiceType.includes(_attr));
  }
  get openEnded() {
    return this.attributes.includes(OPEN_ENDED_ATTR);
  }
  get fixed() {
    return this.attributes.includes(FIXED_ATTR);
  }
  get userInputTypesOptions() {
    return userInputTypesOptions;
  }
  get showAllText() {
    return this.children.find((_child) => _child.isType(SHOW_ALL_TEXT));
  }
  get showAllFilteredText() {
    return this.children.find((_child) => _child.isType(SHOW_ALL_FILTERED_TEXT));
  }
  get hideUnselectedChoices() {
    return this.attributes.includes(HIDE_UNSELECTED_CHOICES_ATTR);
  }
  get answersChecked() {
    return this.answers.filter((a) => a.checked);
  }
  onNodeUpdated(node, update) {
    if (!node.isOneOfTypes([ANSWER])) return null;
    if (update === 'AnswerChanged') {
      /**
       * Check if multiple answers are allowed and fix if not
       */
      if (!this.parents[0].editing && !this.parents[0].multipleProjects && !this.multipleAnswers && node.hasUserVoted()) {
        let revert = false;
        this.answers.forEach((_answer) => {
          if (_answer.key !== node.key && ((node.filter && _answer.filter) || (!node.filter && !_answer.filter))) {
            _answer.__removeUserVote();
            revert = revert || _answer.checked;
          }
        });
        if (revert) node.__removeUserVote();
      }
      if (node.filter) return this.__computeQuestionFilter();
      this.__updateAllParentsOnNodeUpdated();
      this.__updateTreeOnNodeUpdated();
      this.showRequiredText = false;
      return this.emitStateUpdate();
    }
    if (this.parents[0].editing) return this.checkFixedOpenEnded();
    return this.__computeQuestionFilter();
  }
  updateText(_text) {
    super.updateText(_text);
    this.updateAttributes();
  }
  updateAttributes(_attributes) {
    const oldType = this.attributes[0];
    const attributes = [...(_attributes ? _attributes.slice(1) : this.attributes.slice(1))];
    this.attributes = [];
    attributes.forEach((_attr) => {
      if (answerQuantity.includes(_attr) && this.attributes.some((a) => answerQuantity.includes(a))) return;
      if (userInputTypes.includes(_attr) && this.attributes.some((a) => userInputTypes.includes(a))) return;
      if (answerChoiceType.includes(_attr) && this.attributes.some((a) => answerChoiceType.includes(a))) return;
      if (!this.attributes.includes(_attr)) this.attributes.push(_attr);
    });
    this.attributes.splice(0, 0, (_attributes ? _attributes[0] : oldType));
  }
  updateAnswerChoiceType(_attribute) {
    this.__updateAnswerChoiceType(_attribute);
    return this.emitStateUpdate();
  }
  updateShowRequiredText(_showRequired) {
    this.showRequiredText = _showRequired;
    this.emitStateUpdate();
  }
  canAddChild(_type, userCreated) {
    if (!super.canAddChild(_type)) return false;
    if (_type === ANSWER) {
      if (this.parents[0].editing && userCreated) return true;
      if (this.fixed) return false;
      const isThereABlankAnswer = this.answers.some((_answer) => !_answer.text || _answer.owner === this.parents[0].userLoggedIn);
      if (isThereABlankAnswer && !userCreated) return false;
    }
    return true
  }
  addChild(_child, index) {
    super.addChild(_child, index);
    this.checkFixedOpenEnded();
  }

  checkFixedOpenEnded() {
    if (!this.parents[0].editing) return;
    if (this.filter) return;
    const openAnswer = this.answers.some((_answer) => !_answer.text);
    if (this.answers.length === 0) this.__updateAnswerChoiceType(OPEN_ENDED_ATTR);
    else if (!this.multipleAnswers && openAnswer && this.fixed) this.__updateAnswerChoiceType(OPEN_ENDED_ATTR);
    else if (!openAnswer && this.openEnded) this.__updateAnswerChoiceType(FIXED_ATTR);
  }
  get forEachAnswerQuestions() {
    if (this.parents[0].editing) return [];
    const questions = [];
    this.forEachAnswer.forEach((_forEachAnswer) => {
      _forEachAnswer.children.forEach((_q) => questions.push(_q.clone()));
    });
    return questions;
  }
  get userInputType() {
    return this.attributes.find((_attr) => userInputTypes.includes(_attr));
  }
  get userInputFormat() {
    return this.attributes.find((_attr) => userInputFormats.includes(_attr)) || 'No Wysiwyg';
  }
  get filter() {
    return this.answers.find((answer) => answer.stringMatch || answer.greaterThan || answer.lessThan);
  }
  get filters() {
    return this.answers.filter((answer) => answer.stringMatch || answer.greaterThan || answer.lessThan);
  }
  get multipleAnswers() {
    return this.attributes.includes(MULTIPLE_ANSWERS_ATTR);
  }
  get dropdown() {
    return this.attributes.includes(DROPDOWN_ATTR);
  }
  get showAnswerInputFormatOptions() {
    return this.attributes.includes(TEXT_ATTR);
  }
  updateAnswerQuantity(_attribute) {
    this.attributes = this.attributes.filter((_attr) => _attr !== SINGLE_ANSWER_ATTR && _attr !== MULTIPLE_ANSWERS_ATTR);
    return this.addAttribute(_attribute);
  }
  updateAnswerInputType(_attribute) {
    this.attributes = this.attributes.filter((_attr) => !userInputTypes.includes(_attr));
    this.addAttribute(_attribute);
  }
  updateAnswerFormatType(_attribute) {
    this.attributes = this.attributes.filter((_attr) => !userInputFormats.includes(_attr));
    if (_attribute !== NONE_ATTR) this.addAttribute(_attribute);
    else this.__attributeChanged();
  }
  /**
   * Add an existing answer by someone to this question
   */
  addExistingAnswer(_answer) {
    this.children.push(_answer);
    if (this.parents && this.parents[0]) this.parents[0].onNodeAdded(_answer, [...this.parents, this]);
    this.onNodeUpdated(_answer);
  }
  __computeQuestionFilter() {
    if (this.updateTimeout) clearTimeout(this.updateTimeout);
    this.updateTimeout = setTimeout(() => {
      this.__internalComputeQuestionFilter();
      this.__updateAllParentsOnNodeUpdated();
      this.__updateTreeOnNodeUpdated();
      this.emitStateUpdate();
    }, 300);
  }
  __internalComputeQuestionFilter() {
    const { filters } = this;
    if (filters.length === 0) return;
    const filter = filters.find((f) => f.checked);

    // Handle StringMatch
    if (filters[0].stringMatch) {
      const excludeWords = 'and|or|to|too|for|the|but|etc|';
      const searchInputText = filter && filter.text || '';

      this.keywordsFoundByQuestion = new Set();
      let matches = 0;
      const answers = this.answers;
      answers.forEach((_answer) => {
        // eslint-disable-next-line no-param-reassign
        _answer.keywordsFoundByQuestion = new Set();
        if (!_answer.stringMatch) {
          let keywords = [];
          _answer.keywords.forEach((_keywords) => {
            keywords = [...keywords, ..._keywords.text
              .split(_keywords.delimiterString)
              .map((_k) => _k.toLowerCase().trim())
              .filter((k) => k && !excludeWords.includes(k))];
          });

          const keywordsFound = keywords.filter((_keyword) => {
            let startIndex = 0;
            let keywordFound;
            while (startIndex >= 0) {
              startIndex = searchInputText.indexOf(_keyword, startIndex);
              if (startIndex >= 0) {
                let preText;
                if (startIndex - 1 >= 0) preText = searchInputText.substr(startIndex - 1, 1).match(/[a-z]/i);
                let postText;
                if (startIndex + _keyword.length <= searchInputText.length) postText = searchInputText.substr(startIndex + _keyword.length, 1).match(/[a-z]/i);
                if (!preText && !postText) {
                  startIndex = -1;
                  keywordFound = _keyword;
                } else {
                  startIndex += 1;
                }
              }
            }
            return keywordFound;
          });

          // Keep cumulative track at filter and search levels
          keywordsFound.forEach((k) => {
            this.keywordsFoundByQuestion.add(k);
            _answer.keywordsFoundByQuestion.add(k);
          });

          // Apply keyword filtering to answers
          if (_answer.filter || _answer.isUserAnswer) {
            // eslint-disable-next-line no-param-reassign
            _answer.filteredByQuestion = false;
          } else if (keywords.length === 1 && keywords[0] === '') {
            // eslint-disable-next-line no-param-reassign
            _answer.filteredByQuestion = false;
          } else if (keywordsFound && keywordsFound.length > 0) {
            // eslint-disable-next-line no-param-reassign
            _answer.filteredByQuestion = false;
            matches += 1;
          } else {
            // eslint-disable-next-line no-param-reassign
            _answer.filteredByQuestion = true;
          }
        }
      });
      if (matches === 0) {
        answers.forEach((_answer) => {
          // eslint-disable-next-line no-param-reassign
          _answer.filteredByQuestion = null;
        });
      }
      return;
    }

    // Handle greaterThan or lessThan
    if (filters[0].greaterThan || filters[0].lessThan) {
      const number = filter && filter.text && parseFloat(filter.text, 10);
      this.answers.forEach((_answer) => {
        const answerNumber = parseFloat(_answer.text, 10);

        if (typeof number !== 'number') {
          _answer.__removeUserVote();
        } else if (_answer === filter) {
          //
        } else if (_answer.filter) {
          _answer.__removeUserVote();
        } else if (filter.greaterThan && answerNumber >= number) {
          _answer.__addUserVote();
        } else if (filter.lessThan && answerNumber <= number) {
          _answer.__addUserVote();
        } else {
          _answer.__removeUserVote();
        }
      });
    }
  }
  __updateAnswerChoiceType(_attribute) {
    this.attributes = this.attributes.filter((_attr) => !answerChoiceType.includes(_attr));
    this.addAttribute(_attribute);
  }
  isMatchingNode(_node) {
    if ((this.attributes.find((_attr) => userInputTypes.includes(_attr)) || TEXT_ATTR) !== (_node.attributes.find((_attr) => userInputTypes.includes(_attr)) || TEXT_ATTR)) return false;
    return super.isMatchingNode(_node);
  }
  getFilteredAnswers() {
    const { multipleProjects, editing } = this.parents[0];
    const { hideUnselectedChoices, children, answers, sortAscending, sortDescending, allowFilteringSelections, filters, answersChecked } = this;

    // Filter which answers to display
    let answersToDisplay = [];
    const answersToHide = [];
    const filteredKeywordsFound = new Set();
    const showMoreKeywordsFound = new Set();
    if (editing) {
      // If editing, display all children
      answersToDisplay = [...children];
    } else if (answers.length > 0) {
      // If not editing, display just answers
      answersToDisplay = [...answers];
      if (filters[0] && (filters[0].greaterThan || filters[0].lessThan)) {
        // If greaterThan or lessThan question, just display the answer
        answersToDisplay = filters;
      } else {
        // Apply sorting
        if (sortAscending) {
          answersToDisplay.sort((a, b) => {
            if (a.text < b.text) return -1;
            if (b.text < a.text) return 1;
            return 0;
          });
        } else if (sortDescending) {
          answersToDisplay.sort((a, b) => {
            if (a.text > b.text) return -1;
            if (b.text > a.text) return 1;
            return 0;
          });
        }
        if (this.keywordsFound && this.keywordsFound.size > 0) {
          // If keywords were found, sort the keywords and then hide the ones not matched
          answersToDisplay.sort((answerA, answerB) => {
            if (answerA.stringMatch) return -1;
            if (answerB.stringMatch) return 1;
            const a = (answerA.keywordsFound && answerA.keywordsFound.size) || 0;
            const b = (answerB.keywordsFound && answerB.keywordsFound.size) || 0;
            if (a > b) return -1;
            if (b > a) return 1;
            return 0;
          });
        }
        // Sort by votes if multipleProjects
        if (multipleProjects) {
          answersToDisplay.sort((a, b) => {
            const aVotes = a.votes ? a.votes.length : 0;
            const bVotes = b.votes ? b.votes.length : 0;
            if (aVotes > bVotes) return -1;
            if (bVotes > aVotes) return 1;
            return 0;
          });
        }
      }
      // Filter out hidden answers
      answersToDisplay = answersToDisplay.filter((_answer) => {
        if (!(_answer.filteredByFilters && (!_answer.hasUserVoted() || allowFilteringSelections))) return true;
        if (_answer.keywordsFound && _answer.keywordsFound.size > 0) [..._answer.keywordsFound].forEach((keyword) => filteredKeywordsFound.add(keyword));
        answersToHide.push(_answer);
        return false;
      });
      // Filter out unselected choices
      if (hideUnselectedChoices) {
        if (answersChecked.length > 0) answersToDisplay = [...answersChecked];
      }
    }
    return { answersToDisplay, answersToHide, filteredKeywordsFound, showMoreKeywordsFound };
  }
}
