import React from 'react';
import styled from 'styled-components';
import { PropTypes } from 'prop-types';
import StatefulTextField from '../../components/StatefulTextField';
import UserAccount from '../../services/UserAccount';
import StatefulButton from '../../components/StatefulButton/index';
import { isPasswordInvalid } from '../../utils/regex';

const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  h1 {
    margin-bottom: 2rem;
  }
`;

export default class ChangePassword extends React.PureComponent {
  static propTypes = {
    email: PropTypes.string,
    changePassword: PropTypes.bool,
    onComplete: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.state = {
      password: '',
      passwordAgain: '',
      warning: '',
    };
  }

  submitPressed = () => {
    if (this.state.password === '') return this.setState({ warning: 'New Password blank' });
    if (this.state.password !== this.state.passwordAgain) return this.setState({ warning: 'Passwords must match' });
    if (isPasswordInvalid(this.state.password)) return this.setState({ warning: 'Invalid password - passwords should be 8 to 30 characters' });
    UserAccount.updatePassword(this.props.email, this.state.password);
    if (this.props.onComplete) this.props.onComplete();
  };

  render() {
    return <Form>
      <h1>Set Password</h1>
      <StatefulTextField
        value={this.state.password}
        onChange={(password) => { this.setState({ password }); }}
        name="password"
        type="password"
        style={{ minWidth: 120, maxWidth: '80%', width: 400 }}
        label={this.props.changePassword ? 'New Password' : 'Password'}
      />
      <StatefulTextField
        value={this.state.passwordAgain}
        onChange={(passwordAgain) => { this.setState({ passwordAgain }); }}
        name="password"
        type="password"
        style={{ minWidth: 120, maxWidth: '80%', width: 400 }}
        label={this.props.changePassword ? 'New Password Again' : 'Password Again'}
        onKeyDown={(e) => { if (e.key === 'Enter') { this.submitPressed(); } }}
      />
      <div style={{ margin: 40 }}>
        <StatefulButton
          onClick={this.submitPressed}
          text={this.props.changePassword ? 'Update Password' : 'Set Password'}
          warning={this.state.warning}
          state=""
        />
      </div>
    </Form>;
  }
}
