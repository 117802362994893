import React from 'react';
import PropTypes from 'prop-types';
import ClickableIcon from '../ClickableIcon/index';
import ClickableDiv from '../ClickableDiv/index';
import Questionable from '../../models/questionable';
import Calculation from '../../models/calculation';
import FileGroup from '../../models/filegroup';
import { DownloadIcon } from '../../styledhtml/Icon';


export default class ExportCSVButton extends React.PureComponent {
  static propTypes = {
    filename: PropTypes.string,
    tree: PropTypes.object,
    trees: PropTypes.array,
  }
  createCSV = async () => {
    const { filename, tree, trees } = this.props;
    const roots = trees.map(({ root }) => root);
    const results = convertProjectsToSpreadsheet(tree, roots);

    // Create the CSV string format
    let headersString;
    const dataStrings = [].fill([], 0, roots.length);

    headersString = 'Created Time,Last Update Time';
    for (let i = 0; i < trees.length; i += 1) {
      dataStrings[i] = `"${trees[i].createdTime.toUTCString()}","${trees[i].updateTime.toUTCString()}"`
    }

    Object.keys(results).forEach((key) => {
      headersString = headersString && `${headersString},"${key}"` || `"${key}"`;
      for (let i = 0; i < roots.length; i += 1) {
        dataStrings[i] = `${dataStrings[i] && `${dataStrings[i]},` || ''}"${(results[key][i] || ['']).join(' | ')}"`;
      }
    });

    // Export to file clientside
    const dataString = dataStrings.join('\r\n');
    const csvData = new Blob([`${headersString}\r\n${dataString}`], { type: 'text/csv' });
    const csvUrl = URL.createObjectURL(csvData);


    const link = document.createElement("a");
    // link.setAttribute("href", encodedUri);
    link.setAttribute("href", csvUrl);
    link.setAttribute("download", `${filename || 'logictry'}.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
  render() {
    return (
      <ClickableDiv onClick={this.createCSV} style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
        <DownloadIcon style={{ width: 16, height: 16 }} /> Download CSV
      </ClickableDiv>
    );
  }
}

export function convertProjectsToSpreadsheet(tree, roots, ignoreDisplayText, makeLinksClickable) {
  const results = {};
  const formattingDiv = document.createElement('div');
  formattingDiv.style.opacity = 0;
  document.body.appendChild(formattingDiv);

  // Create an object of the results
  const __recursivelyPopulateResults = (_node, i, _templateNode) => {
    if (!_node) return;
    if (_node instanceof Questionable) {
      const { answers } = _node;
      let keyText;
      if (_templateNode) keyText = ignoreDisplayText && _templateNode.text || _templateNode.displayTextOrText;
      if (!keyText) keyText = ignoreDisplayText && _node.text || _node.displayTextOrText || '';
      formattingDiv.innerHTML = keyText;
      keyText = formattingDiv.innerText;
      const templateAnswers = _templateNode && _templateNode.answers;
      const key = (keyText).replace(/"/g, '""');
      answers.forEach((answer) => {
        if (answer.votes.length === 0) return;
        if (!results[key]) results[key] = {};
        if (i < 0) return;
        if (!results[key][i]) results[key][i] = [];
        const matchingTemplateAnswer = templateAnswers && templateAnswers.find((child) => child.isMatchingNode(answer));
        let answerText;
        if (matchingTemplateAnswer) answerText = matchingTemplateAnswer.displayTextOrText;
        if (!answerText) answerText = answer.displayTextOrText || '';
        let text = (answerText).replace(/"/g, '""');
        formattingDiv.innerHTML = text;
        text = formattingDiv.innerText;
        results[key][i].push(text);
      });
    } else if (_node instanceof Calculation) {
      let keyText = _node.text;
      formattingDiv.innerHTML = keyText;
      keyText = formattingDiv.innerText;
      const key = (keyText).replace(/"/g, '""');
      if (!results[key]) results[key] = {};
      if (i < 0) return;
      let text = (_node.calculatedValueFormatted || '').replace(/"/g, '""');
      formattingDiv.innerHTML = text;
      text = formattingDiv.innerText;
      if (!results[key][i]) results[key][i] = [];
      results[key][i].push(text);
    } else if (_node instanceof FileGroup) {
      let keyText = _node.text;
      formattingDiv.innerHTML = keyText;
      keyText = formattingDiv.innerText;
      const key = (keyText).replace(/"/g, '""');
      _node.children.forEach((file) => {
        if (!file.text) return;
        if (!results[key]) results[key] = {};
        if (i < 0) return;
        if (!results[key][i]) results[key][i] = [];
        let text = (file.text).replace(/"/g, '""');
        formattingDiv.innerHTML = text;
        text = formattingDiv.innerText;
        results[key][i].push(makeLinksClickable ? `<a href="${text}">${text}</a>` : text);
      });
    }
    _node.children.forEach((node) => __recursivelyPopulateResults(node, i, _templateNode && _templateNode.children.find((child) => child.isMatchingNode(node))));
  }

  // Execute for each node
  __recursivelyPopulateResults(tree.root, -1);
  roots.forEach((root, i) => __recursivelyPopulateResults(root, i, tree.root));

  document.body.removeChild(formattingDiv);

  return results;
}
