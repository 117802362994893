import React from 'react';
import styled from 'styled-components';
import { PropTypes } from 'prop-types';
import Constants from '../../submodules/logictry_config/constants';
import GrayOut from '../../services/TreeDisplay';
// eslint-disable-next-line import/no-cycle
import CreateChildren from './CreateChildren';
import RenderedHtmlToTextArea from '../../components/RenderedHtmlToTextArea/index';
import { BRANCH } from '../../models/nodetypes';
import Dropdown from '../../components/Dropdown/index';
import ClickableDiv from '../../components/ClickableDiv/index';
import { BranchIcon } from '../../styledhtml/Icon';

const BranchWrapper = styled.div`
  margin: 0px 0px 0px ${Constants.TreeContentIconIndent};

  > div:first-child {
    > div:first-child {
      margin: ${Constants.defaultLinePadding} 0px;
    }
    display: flex;
    align-items: center;
    &:hover {
      opacity: 1 !Important;
    }
    > i {
      cursor: pointer;
    }
  }
`;
const Wrapper = styled.div`
  margin-left: ${Constants.TreeContentIconIndent};
  > div:first-child {
    > div:first-child {
      margin: ${Constants.defaultLinePadding} 0px;
    }
    display: flex;
    align-items: center;
    &:hover {
      opacity: 1 !Important;
    }
    > i {
      cursor: pointer;
    }
  }
`;
const BranchBoxWrapper = styled.div`

`;
const TreeList = styled.div`
  display: flex;
  align-items: center;
  color: ${Constants.DarkTextColor};
  width: 100%;
  > div:first-child {
    margin-right: 12px;
    border-radius: 8px;
    min-width: 40px;
    width: 40px;
    min-height: 40px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    color: ${Constants.White};
    font-size: ${Constants.SmallFontSize};
    display: flex;
    align-items: center;
    justify-content: center;
  }
  > div:nth-child(2) {
    display: flex;
    flex-direction: column;
    flex: 1;
  }
`;
const SpreadsheetLink = styled.div`

`;

export default class BranchBox extends React.PureComponent {
  static propTypes = {
    branch: PropTypes.object,
  }
  componentDidMount() {
    const { branch } = this.props;
    branch.onStateUpdate(this);
    GrayOut.onStateUpdate(this);
  }
  componentWillUnmount() {
    const { branch } = this.props;
    branch.offStateUpdate(this);
    GrayOut.offStateUpdate(this);
  }
  onChange = (val) => {
    const { branch } = this.props;
    branch.updateText(val);
  }
  render() {
    const { branch } = this.props;
    const { editing, tree } = GrayOut;
    const { isDisabled } = tree;
    
    if (!editing) {
      return (
        <BranchBoxWrapper>
          <CreateChildren node={branch} />
        </BranchBoxWrapper>
      );
    }

    const expanded = GrayOut.isExpanded(branch);
    const branches = tree.branches.children;
    const isActive = GrayOut.isActive(branch);
    const showDropdown = isActive && !isDisabled;

    if (branch.parents.length === 2 && branch.parents[1] === tree.branches) {
      return (
        <Wrapper>
          <div>
            <RenderedHtmlToTextArea node={branch} placeholderText={BRANCH} />
          </div>
          {expanded && <CreateChildren node={branch} />}
        </Wrapper>
      );
    }

    const existingBranch = branch.text && tree.branches.findExistingBranch(branch.text);
    const { primaryColor } = GrayOut.tree.root;
    const iconStyle = { width: 18, height: 18, filter: 'invert(1)' };
    return (
      <BranchWrapper>
        <div>
          {(!branch.text || GrayOut.isActive(branch)) ? (
            <TreeList>
              <div style={{ fontSize: Constants.NormalFontSize, backgroundColor: primaryColor }}>
                <BranchIcon style={iconStyle} />
              </div>
              <div>
                <RenderedHtmlToTextArea node={branch} placeholderText={BRANCH} />
              </div>
            </TreeList>
          ) : (
            <TreeList key={branch.text}>
              <div style={{ fontSize: Constants.NormalFontSize, backgroundColor: primaryColor }}>
                <BranchIcon style={iconStyle} />
              </div>
              <div>
                {existingBranch && <SpreadsheetLink
                  role="button"
                  tabIndex={-1}
                  style={{ color: primaryColor }}
                  onClick={() => { GrayOut.addActiveObject(existingBranch); GrayOut.goToActiveObject(); }}
                >{branch.text}</SpreadsheetLink>}
                <ClickableDiv style={{ pointerEvents: isDisabled ? 'none' : null }} onClick={() => GrayOut.addActiveObject(branch)}>Change Branch</ClickableDiv>
              </div>
            </TreeList>
          )}
        </div>
        {showDropdown && <Dropdown values={branches.map((s) => ({ name: s.text, value: s.text }))} onChange={this.onChange} />}
      </BranchWrapper>
    );
  }
}
