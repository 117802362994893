import React from 'react';
// import PropTypes from 'prop-types';
import styled from 'styled-components';
import Constants from '../../submodules/logictry_config/constants';
import Navigation from '../../services/Navigation';
import UserAccount from '../../services/UserAccount';
import WindowSize from '../../services/WindowSize';
import LogicBaseUserCache from '../../services/cache/LogicBaseUserCache';
import UserCache from '../../services/cache/UserCache';
import LogicBaseRelationshipCache from '../../services/cache/LogicBaseRelationshipCache';
import FullScreenVerticalCenterContent from '../../styledhtml/FullScreenVerticalCenterContent';
import { getUserFollowRequests } from '../../services/Pagination';
import Scrollable from '../../components/Scrollable';
import UserAvatar from '../../components/UserAvatar';

const NotificationWrapper = styled.div`
  max-width: 52rem;
  margin: auto;
  display: flex;
  flex-direction: column;

  > h1 {
    margin: 10px;
  }
`;
const Request = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  cursor: pointer;
  > * {
    margin: 0.5rem;
    ${Constants.MediaMobile} {
      margin: 0.5rem 0.25rem 0.5rem 0;
    }
  }
  > div:nth-child(2) {
    flex: 1;
  }
`;

export default class LogicBaseFollowPage extends React.PureComponent {
  constructor() {
    super();
    this.requested = getUserFollowRequests(UserAccount.account._id);
  }
  state = {
    isAccount: UserAccount.isLoggedIn && Navigation.currentLocation[1] === 'account'
  }
  componentDidMount() {
    WindowSize.onStateUpdate(this);
    LogicBaseUserCache.onStateUpdate(this);
    UserCache.onStateUpdate(this);
    this.requested.onStateUpdate(this);
  }
  componentWillUnmount() {
    WindowSize.offStateUpdate(this);
    LogicBaseUserCache.offStateUpdate(this);
    UserCache.offStateUpdate(this);
    this.requested.offStateUpdate(this);
  }
  onPagination = () => {
    const userPaginations = this.requested;
    if (userPaginations.showMore && userPaginations.allFound && userPaginations.initialized) {
      userPaginations.getMore();
    }
  }
  approveDeny = (user, approve) => {
    if (approve) {
      user.requested = false
      LogicBaseRelationshipCache.update(user);
    } else {
      LogicBaseRelationshipCache.delete(user);
    }
  }
  render() {
    const { mobile } = WindowSize;
    this.users = UserCache.getUsersByIds(this.requested.currentPage.map(({ follower }) => follower)) || this.users;
    if (!this.users) return null;
    return (
      <Scrollable
        vertical={!mobile}
        paginationHeight={400}
        onPagination={this.onPagination}
        style={{ position: mobile ? 'relative' : 'absolute', inset: 0, padding: mobile ? '1rem 1rem 2rem' : '1rem 2rem 2rem' }}
        rememberScrollPosition={`logicbasefollow`}
      >
        <NotificationWrapper>
          <FullScreenVerticalCenterContent>
            {(this.requested.currentPage.length > 0 && this.requested.currentPage.map((follower) => {
              const user = this.users.find(({ _id }) => follower.follower === _id);
              if (!user) return null;
              return (
                <Request key={user._id}>
                  <UserAvatar onClick={() => Navigation.push(Navigation.userProfile(user))} user={user} />
                  <div onClick={() => Navigation.push(Navigation.userProfile(user))}>{user.fullname}</div>
                  {follower.requested && (
                    <>
                      <button style={{ cursor: 'pointer', color: 'white', background: Constants.PrimaryColor, padding: '0.25rem 0.5rem', borderRadius: '0.5rem', margin: 0 }} onClick={() => this.approveDeny(follower, true)}>Approve</button>
                      <button style={{ cursor: 'pointer', color: 'white', background: Constants.PrimaryColor, padding: '0.25rem 0.5rem', borderRadius: '0.5rem', margin: '0 0 0 0.5rem' }} onClick={() => this.approveDeny(follower, false)}>Reject</button>
                    </>
                  ) || (
                    <div>Approved</div>
                  )}
                </Request>
              )
            }) || (
              <div style={{ flex: 1, display: 'flex', justifyContent: 'center' }}>
                <div>{'No requests yet'}</div>
              </div>
            ))}
          </FullScreenVerticalCenterContent>
        </NotificationWrapper>
      </Scrollable>
    );
  }
}
