import React from 'react';
import styled from 'styled-components';
// import { PropTypes } from 'prop-types';
import LoadingIndicator from '../../components/LoadingIndicator';
import Constants from '../../submodules/logictry_config/constants';
import FullScreenVerticalCenterContent from '../../styledhtml/FullScreenVerticalCenterContent/index';
import SelectMenu from '../../components/SelectMenu/index';
import WindowSize from '../../services/WindowSize';
import UserAccount from '../../services/UserAccount';
import Navigation from '../../services/Navigation';
import Company from '../../services/Company';
import Storage from '../../services/Storage';
import AssetsBox from './AssetsBox';
import SpreadsheetsBox from './SpreadsheetsBox';
import TemplateTreesBox from './TemplateTreesBox';
import ProjectTreesBox from './ProjectTreesBox';
import DrawerBreadcrumb from '../../components/DrawerBreadcrumb';
import ListViewGridViewSelect from './ListViewGridViewSelect';
import HeaderSection from './HeaderSection';
import DatabasesBox from './DatabasesBox';
import LinksBox from './LinksBox';
import Scrollable from '../../components/Scrollable/index';
import LogicBaseBanner from '../../components/LogicBaseBanner';
import { SearchIcon } from '../../styledhtml/Icon';

const TextContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  * {
    text-align: center;
  }
  h1, h2 {
    margin-top: 3rem;
    margin-bottom: 1rem;
  }
`;
const SearchBox = styled.div`
  border-radius: 0.25rem;
  padding: 0.25rem 0.5rem;
  display: flex;
  align-items: center;
  i {
    margin-right: 0.5rem;
    cursor: pointer;
  }
  input {
    width: 90px;
    transition: 0.3s all;
  }
  ${Constants.MediaTabletAndDesktop} {
    input:focus {
      width: 200px;
    }
  }
`;
const Wrapper = styled.div`
  display: flex;
  width: calc(100% - 4rem);
  justify-content: center;
  flex-direction: column;
  margin: 0 2rem;
  ${Constants.MediaMobile} {
    width: calc(100% - 2rem);
    margin: 0;
  }
  > div:last-child {
    margin: 2rem 0;
  }
`;
const QuickLinks = styled.div`
  margin: 2rem 0 0;
  display: flex;
  align-items: center;
  gap: 1rem;
  flex-wrap: wrap;
  > div {
    width: 300px;
    padding: 1rem 1.5rem;
    background: white;
    border-radius: 0.5rem;
    cursor: pointer;
    border: ${Constants.LightBorder};
    :hover {
      box-shadow: ${Constants.CardShadow};
    }
    > div:first-child {
      font-weight: 600;
    }
  }
`;

let filterState = {};

export default class TreePage extends React.PureComponent {
  componentDidMount() {
    WindowSize.onStateUpdate(this);
  }
  componentWillUnmount() {
    WindowSize.offStateUpdate(this);
  }
  getBoxes = (searchText, invited) => {
    const filterText = (filterState && Object.keys(filterState).length > 0) ? JSON.parse(JSON.stringify(filterState)) : null;
    const treePageSelectType = Navigation.currentSearch.content || 'apps';
    const showDeleted = Navigation.currentSearch.state === 'deleted';
    if (treePageSelectType === 'apps') return <TemplateTreesBox key={searchText || ''} showDeleted={showDeleted} searchText={searchText || ''} filterText={filterText} invitedTo={invited} />;
    if (treePageSelectType === 'assets') return <AssetsBox key={searchText || ''} showDeleted={showDeleted} searchText={searchText || ''} filterText={filterText} invitedTo={invited} />;
    if (treePageSelectType === 'databases') return <DatabasesBox key={searchText || ''} showDeleted={showDeleted} searchText={searchText || ''} filterText={filterText} invitedTo={invited} />;
    if (treePageSelectType === 'links') return <LinksBox key={searchText || ''} showDeleted={showDeleted} searchText={searchText || ''} filterText={filterText} invitedTo={invited} />;
    if (treePageSelectType === 'spreadsheets') return <SpreadsheetsBox key={searchText || ''} showDeleted={showDeleted} searchText={searchText || ''} filterText={filterText} invitedTo={invited} />;
    if (treePageSelectType === 'submissions') return <ProjectTreesBox key={searchText || ''} showDeleted={showDeleted} searchText={searchText || ''} filterText={filterText} invitedTo={invited} />;
    return null;
  }
  updateState = (_selectType) => {
    Storage.setContentView(_selectType);
    const queryParams = [];
    if (Navigation.currentSearch.invited) queryParams.push('invited=true');
    if (Navigation.currentSearch.state === 'deleted') queryParams.push('state=deleted');
    Navigation.push(`/?${_selectType === 'apps' ? '' : `content=${_selectType}`}${queryParams.length > 0 ? `&${queryParams.join('&')}` : ''}`);
    this.forceUpdate();
  }
  keyDown = (e) => {
    if (e.key === 'Enter') this.search();
  }
  search = (e) => {
    if (!this.searchRef) return;
    Navigation.addOrReplaceQueryParam('search', this.searchRef.value || '');
  }
  render() {
    const { mobile } = WindowSize;
    const { isAuthorizedToLogIn } = Company;
    if (!UserAccount.isLoggedIn) return <FullScreenVerticalCenterContent><LoadingIndicator /></FullScreenVerticalCenterContent>;
    const treePageSelectType = Navigation.currentSearch.content || 'apps';
    const pageTitle = treePageSelectType ? (Navigation.currentSearch.invited ? `Shared ${treePageSelectType}` : `Your ${treePageSelectType}`) : 'Home';
    if (treePageSelectType === 'Home') {
      return (
        <Scrollable
          vertical
          style={{ position: 'absolute', top: 0, right: 0, bottom: 0, left: 0 }}
        >
          <FullScreenVerticalCenterContent>
            <TextContent>
              <DrawerBreadcrumb key={pageTitle}></DrawerBreadcrumb>
            </TextContent>
          </FullScreenVerticalCenterContent>
        </Scrollable>
      )
    }
    const searchText = Navigation.currentSearch['search'];
    const invited = !!Navigation.currentSearch.invited;
    const key = `${searchText || ''}${JSON.stringify(filterState) || ''}`;
    const showDeleted = Navigation.currentSearch.state === 'deleted';
    const treePageSelectOptions = [
      { name: 'Apps', value: 'apps' },
      { name: 'Assets', value: 'assets' },
      { name: 'Spreadsheets', value: 'spreadsheets' },
      { name: 'Databases', value: 'databases' },
      (UserAccount.isDevAccount && { name: 'Links', value: 'links' } || null),
      { name: 'Submissions', value: 'submissions' }
    ].filter((v) => v);
    const treePageOptionsToDisplay = treePageSelectOptions.map(({ name, value }) => ({ name: showDeleted ? `Deleted ${name}` : name, value }))
      .filter(({ value }) => isAuthorizedToLogIn || value === 'submissions');
    return (
      <Scrollable
        vertical
        showVertical
        style={{ position: 'absolute', top: 0, right: 0, bottom: 0, left: 0 }}
        rememberScrollPosition={'tree_page'}
      >
        <FullScreenVerticalCenterContent alignTop>
          <DrawerBreadcrumb key={pageTitle}></DrawerBreadcrumb>
          <Wrapper>
            <LogicBaseBanner
              // height={210}
              text="What will you curate today?"
            />
            {!mobile && <QuickLinks>
              <div onClick={() => Navigation.push(Navigation.documentation)}>
                <div>Read our documentation</div>
                <div>Review our implementation guide, data types, and more!</div>
              </div>
              <div onClick={() => Navigation.push(Navigation.tutorials)}>
                <div>Go through tutorials</div>
                <div>Access our interactive guided tutorials for creating content!</div>
              </div>
              {/* <div onClick={() => Navigation.push(Navigation.templates)}>
                <div>Start with a template</div>
                <div>Select a template, click the three dots, hit duplicate, and go!</div>
              </div> */}
              {/* <div onClick={() => Navigation.push(Navigation.templates)}>
                <div>Start with A.I.</div>
                <div>Use A.I. to create content from your website or existing content!</div>
              </div> */}
            </QuickLinks>}
            <div>
              <HeaderSection>
                <SelectMenu value={treePageSelectType} onChange={this.updateState} options={treePageOptionsToDisplay} />
                {!showDeleted && <SearchBox>
                  <SearchIcon
                    style={{ width: 14, height: 14, marginRight: '0.5rem'}}
                    onClick={this.search} />
                  <input ref={(e) => {this.searchRef = e;}} defaultValue={searchText} placeholder="Search" onKeyDown={this.keyDown} onBlur={this.search} />
                </SearchBox>}
                <ListViewGridViewSelect />
              </HeaderSection>
              <div key={key}>
                {this.getBoxes(searchText, invited)}
              </div>
            </div>
          </Wrapper>
        </FullScreenVerticalCenterContent>
      </Scrollable>
    );
  }
}
