import React from "react";
import styled, { keyframes } from "styled-components";

const Wrapper = styled.div`
  position: relative;
  width: 28px;
  height: 28px;
`;

const circleFadeDelay = keyframes`
  0%,
  39%,
  100% {
    opacity: 0;
  }

  40% {
    opacity: 1;
  }
`;

const Circle = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  transform: rotate(0deg);
  &:before {
    content: "";
    display: block;
    margin: 0 auto;
    width: 15%;
    height: 15%;
    background-color: ${(props) => props.color || "#999"};
    border-radius: 100%;
    animation: ${circleFadeDelay} 1.2s infinite ease-in-out both;
  }
`;
const Circle1 = styled(Circle)`
  transform: rotate(0deg);
  &:before {
    animation-delay: -1.2s;
  }
`;
const Circle2 = styled(Circle)`
  transform: rotate(30deg);
  &:before {
    animation-delay: -1.1s;
  }
`;
const Circle3 = styled(Circle)`
  transform: rotate(60deg);
  &:before {
    animation-delay: -1s;
  }
`;
const Circle4 = styled(Circle)`
  transform: rotate(90deg);
  &:before {
    animation-delay: -0.9s;
  }
`;
const Circle5 = styled(Circle)`
  transform: rotate(120deg);
  &:before {
    animation-delay: -0.8s;
  }
`;
const Circle6 = styled(Circle)`
  transform: rotate(150deg);
  &:before {
    animation-delay: -0.7s;
  }
`;
const Circle7 = styled(Circle)`
  transform: rotate(180deg);
  &:before {
    animation-delay: -0.6s;
  }
`;
const Circle8 = styled(Circle)`
  transform: rotate(210deg);
  &:before {
    animation-delay: -0.5s;
  }
`;
const Circle9 = styled(Circle)`
  transform: rotate(240deg);
  &:before {
    animation-delay: -0.4s;
  }
`;
const Circle10 = styled(Circle)`
  transform: rotate(270deg);
  &:before {
    animation-delay: -0.3s;
  }
`;
const Circle11 = styled(Circle)`
  transform: rotate(300deg);
  &:before {
    animation-delay: -0.2s;
  }
`;
const Circle12 = styled(Circle)`
  transform: rotate(330deg);
  &:before {
    animation-delay: -0.1s;
  }
`;

const LoadingIndicator = ({ style, color }) => {
  return (
    <Wrapper style={style}>
      <Circle1 color={color} />
      <Circle2 color={color} />
      <Circle3 color={color} />
      <Circle4 color={color} />
      <Circle5 color={color} />
      <Circle6 color={color} />
      <Circle7 color={color} />
      <Circle8 color={color} />
      <Circle9 color={color} />
      <Circle10 color={color} />
      <Circle11 color={color} />
      <Circle12 color={color} />
    </Wrapper>
  );
};
export default LoadingIndicator;
