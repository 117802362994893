import React from 'react';
import styled from 'styled-components';
import { PropTypes } from 'prop-types';
import TreeDisplay from '../../services/TreeDisplay';
import WindowSize from '../../services/WindowSize';
import Constants from '../../submodules/logictry_config/constants';
import Popup from '../../components/Popup';
import ClickableDiv from '../../components/ClickableDiv/index';
import RawHtml from '../../components/RawHtml/index';
import Scrollable from '../../components/Scrollable';
import GrayOut from '../../services/TreeDisplay';
import { InfoIcon } from '../../styledhtml/Icon';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;
const InfoText = styled.div`
  padding: ${Constants.TreeContentHalfIndent} ${Constants.TreeContentIconIndent};
  max-width: 600px;
  max-height: 600px;
  ${Constants.MediaMobileAndTablet} {
    max-width: 80vw;
  }
  ${Constants.MediaMobileAndTablet} {
    ${Constants.PopupMaxHeight}
  }
  overflow: hidden;
  display: flex;
`;

export default class InfoPopup extends React.PureComponent {
  static propTypes = {
    text: PropTypes.string,
    children: PropTypes.any,
  }
  state = {
    showInfo: false,
  }
  componentDidMount() {
    WindowSize.onStateUpdate(this);
  }
  componentWillUnmount() {
    WindowSize.offStateUpdate(this);
    this.onMouseLeave();
  }
  onClose = () => {
    this.setState({ showInfo: false });
  }
  onClick = () => {
    this.setState({ showInfo: true });
  }
  onMouseEnter = () => {
    if (this.timeout) return;
    this.timeout = setTimeout(() => {
      this.timeout = null;
      this.onClick();
    }, 500);
  }
  onMouseLeave = () => {
    if (!this.timeout) return;
    clearTimeout(this.timeout);
    this.timeout = null;
  }
  render() {
    const { text, children } = this.props;
    const { showInfo } = this.state;
    const { editing } = TreeDisplay;
    if (!text) return null;
    if (editing) return null;
    const { primaryColor } = GrayOut.tree.root;
    return (
      <Wrapper>
        {showInfo && <Popup showCloseButton anchorEl={this.anchorEl} allowMoveClose onClose={this.onClose}>
          <InfoText>
            <Scrollable
              vertical
              style={{ position: 'relative', maxHeight: 600 }}
            >
              <RawHtml html={text} />
            </Scrollable>
          </InfoText>
        </Popup>}
        {children ? (
          <ClickableDiv
            onMouseEnter={this.onMouseEnter}
            onMouseLeave={this.onMouseLeave}
            innerRef={(e) => { this.anchorEl = e; }}
            onClick={this.onClick}>{children}</ClickableDiv>
        ) : (
          <div
            onMouseEnter={this.onMouseEnter}
            onMouseLeave={this.onMouseLeave}
            ref={(e) => { this.anchorEl = e; }}
            onClick={this.onClick}
            style={{ display: text ? null : 'none', color: primaryColor, padding: 12 }}>
              <InfoIcon style={{ width: 16, height: 16, backgroundColor: primaryColor }} />
            </div>
        )}
      </Wrapper>
    );
  }
}
