import Service from './Service';
import Server from './server';
import ScriptLoader from './ScriptLoader';

export const LOGGED_OUT_USER_ID = 'loggedout';
export const LOGGED_OUT = 'loggedOut';
export const LOGGING_IN = 'loggingIn';
export const LOGGED_IN = 'loggedIn';

try {
  const urlObject = new URL(window.location.href);
  const searchParams = urlObject.searchParams;
  const referrer = searchParams.get('referrer');
  if (referrer) localStorage.setItem('logictry.referrer', referrer);
} catch(_) {}

class UserAccount extends Service {
  get userImage() {
    return this.formatUserImage(this.account);
  }
  get isDevAccount() {
    return this.account && this.account.featureFlags && this.account.featureFlags.dev;
  }
  get isAllowedToPublish() {
    return this.account && this.account.username && this.account.featureFlags && this.account.featureFlags.publish;
  }
  get showBetaFeatures() {
    return this.account && this.account.featureFlags && this.account.featureFlags.beta;
  }
  get isLoggingIn() {
    return this.state === LOGGING_IN;
  }
  get isLoggedIn() {
    return !!(this.state === LOGGED_IN && this.account);
  }
  get exists() {
    if (!this.account || !this.account._id || this.account._id === LOGGED_OUT_USER_ID) return false;
    return true;
  }
  get existsWithPasswordButNotLoggedIn() {
    if (this.account && this.account.password) return true;
    return false;
  }

  state = LOGGED_OUT; // loggedOut, loggingIn, loggedIn
  account = { _id: LOGGED_OUT_USER_ID }; // The User Account

  getAccountStatus() {
    this.state = LOGGING_IN;
    this.emitStateUpdate();
    Server.checkAuth().then((a) => {
      if (a._id && a.loggedIn) return this.getUserProfile(a);
      this.state = LOGGED_OUT;
      this.clearAccount();
      return this.emitStateUpdate();
    }).catch(() => {
      this.state = LOGGED_OUT;
      this.clearAccount();
      return this.emitStateUpdate();
    });
  }

  clearAccount() {
    this.account = { _id: LOGGED_OUT_USER_ID };
  }

  async registerAccount(account, login) {
    try {
      const referrer = localStorage.getItem('logictry.referrer');
      if (referrer) account.referrer = referrer;
    } catch(_) {}
    const created = await Server.registerAccount(account, login);
    if (created && login && created.loggedIn && !created.password) this.getUserProfile(created);
    return created;
  }
  async updatePassword(email, password) {
    const results = await Server.updatePassword(password);
    if (results && results._id) this.onPasswordChanged();
    return this.loginAccount({ email, password });
  }
  async resetPassword(e) {
    return Server.resetPassword(e);
  }
  async loginAccount(body) {
    return new Promise(async (resolve) => {
      if (this.state !== LOGGED_OUT) return resolve(null);

      this.state = LOGGING_IN;
      this.emitStateUpdate();

      const a = await Server.loginAccount(body);
      if (a) this.account = a;
      if (a && a._id) {
        resolve(null);
        return this.getUserProfile(a);
      }
      this.state = LOGGED_OUT;
      resolve(null);
      return this.emitStateUpdate();
    });
  }
  async updateUserProfile(p) {
    const account = await Server.updateProfile(p);
    if (account && account.error) this.updateState = account;
    else this.updateState = { success: true };
    if (account && account._id) this.account = { ...this.account, ...account };
    return this.emitStateUpdate();
  }
  async getUserProfile(_account) {
    const account = _account || await Server.getProfile();
    if (account.error === 'UserNotAuthorized') {
      this.state = LOGGED_OUT;
      this.clearAccount();
      return this.emitStateUpdate();
    }
    try {
      localStorage.removeItem('logictry.referrer');
    } catch(_) {}
    if (this.googleSignIn && this.googleSignIn.parentNode) {
      this.googleSignIn.parentNode.removeChild(this.googleSignIn);
    }
    if (account && account._id) {
      this.account = account;
      this.state = LOGGED_IN;
    }
    return this.emitStateUpdate();
  }
  async verifyAccount(email, token) {
    const account = await Server.verifyAccount(email, token);
    if (account && !this.isLoggedIn) this.account = account;
    return this.emitStateUpdate();
  }
  enableMFA() {
    return Server.enableMFA();
  }
  async verifyMFA(token) {
    return new Promise(async (resolve) => {
      const results = await Server.verifyMFA(token);
      if (results.error) resolve(results.error);
      if (results.success) this.account.multiFactorAuthentication = { secret: true, verified: true };
      this.emitStateUpdate();
    });
  }
  async disableMFA() {
    const results = await Server.disableMFA();
    if (results.success) delete this.account.multiFactorAuthentication;
    this.emitStateUpdate();
  }
  async logout() {
    if (!this.isLoggedIn) return;
    this.isLoggingOut = true;
    this.emitStateUpdate();
    await Server.logout();
    window.location = `${window.location.origin}`;
  }
  unsubscribe(email, unsubscribe, token) {
    return Server.unsubscribe(email, unsubscribe, token);
  }
  async changeUserImage(link) {
    if (!(this.account && this.account._id)) return new Error('Not Logged In');
    const newImage = { link };
    await Server.updateProfile({ image: newImage });
    this.account.image = newImage;
    return this.emitStateUpdate();
  }
  async addPhoneNumber(number) {
    const phone = { number };
    await Server.updateProfile({ phone });
    this.account.phone = phone;
    return this.emitStateUpdate();
  }
  formatUserImage(user) {
    let backgroundImage;
    if (user && user.image && user.image.link) backgroundImage = user.image.link;
    if (!backgroundImage && user.facebook && user.facebook.id) backgroundImage = `https://graph.facebook.com/${user.facebook.id}/picture?type=large`;
    return backgroundImage;
  }
  async setDeviceId(device) {
    const account = await Server.setDeviceId(device);
    if (account && account._id) this.account = { ...this.account, ...account };
    return this.emitStateUpdate();
  }
}

const singleton = new UserAccount();

if (window.location.hostname.split('.').length < 3) {

  const googleOneTapSignIn = document.createElement('div');
  if (['/'].includes(window.location.pathname)) {
    // Create Google One Tap Sign-in
    googleOneTapSignIn.innerHTML = `<div id="g_id_onload"
      data-client_id="937354875290-5ddd9f9hp4uina931fb4n9qlk43kce2h"
      data-context="signin"
      data-callback="googleSignIn"
      data-itp_support="true">
    </div>`;
    document.body.appendChild(googleOneTapSignIn);
  }

  // Create Google Embed Sign-in
  const googleSignIn = document.createElement('div');
  googleSignIn.innerHTML  =`<div id="g_id_onload"
    data-client_id="937354875290-5ddd9f9hp4uina931fb4n9qlk43kce2h"
    data-callback="googleSignIn"
    data-auto_prompt="false">
  </div>
  <div class="g_id_signin"
    data-type="standard"
    data-size="large"
    data-theme="outline"
    data-text="sign_in_with"
    data-shape="rectangular"
    data-logo_alignment="left">
  </div>`;
  googleSignIn.style.display = 'none';
  singleton.googleSignIn = googleSignIn;
  document.body.appendChild(googleSignIn);

  singleton.onStateUpdate(() => {
    setTimeout(() => {
      if (singleton.isLoggingIn) {
        //
      } else if (singleton.isLoggedIn) {
        if (window.google && window.google.accounts && window.google.accounts.id) window.google.accounts.id.cancel()
        if (googleOneTapSignIn.parentNode) googleOneTapSignIn.parentNode.removeChild(googleOneTapSignIn);
        if (googleSignIn.parentNode) googleSignIn.parentNode.removeChild(googleSignIn);
      } else {
        googleOneTapSignIn.style.display = null;
        ScriptLoader.load('https://accounts.google.com/gsi/client');
      }
    })
  });

  window.googleSignIn = async (googleUser) => {
    singleton.loginAccount({ google: { accessToken: googleUser.credential } });
  }
}

Server.onLoggedOutStatusCode = () => {
  singleton.logout();
};
export default singleton;
