import React from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import Constants from '../../submodules/logictry_config/constants';
import Navigation from '../../services/Navigation';
import WindowSize from '../../services/WindowSize';
import LogicBaseCollectionCache from '../../services/cache/LogicBaseCollectionCache';
import { PropTypes } from 'prop-types';
import { ArrowLeftIcon, CloseIcon, SearchIcon } from '../../styledhtml/Icon';

const Search = styled.div`
  width: 100%;
  max-width: 38rem;
  position: relative;
  overflow: hidden;
  > div:first-child {
    width: 100%;
    position: relative;
    background: white;
  }
  input {
    overflow: hidden;
    line-height: 18px;
    width: 100%;
  }
  i:first-child {
    position: absolute;
    top: 0px;
    left: 0px;
    cursor: pointer;
    background: white;
    color: ${Constants.LightTextGray};
    font-size: 14px;
    line-height: 20px;
  }
`;
const Trending = styled.div`
  padding: 0 1rem;
  overflow: scroll;
  background: white;
  > div {
    padding: 0.5rem 0;
    > div {
      padding: 0.5rem 0;
      cursor: pointer;
    }
    > div:hover {
      text-decoration: underline;
    }
  }
`;

let searches = [];

export default class LogicBaseSearchBar extends React.PureComponent {
  static propTypes = {
    showTyping: PropTypes.bool,
    autoFocus: PropTypes.bool,
    onSearching: PropTypes.func,
  }
  state = {
    showText: true,
    showSuggestions: false,
    defaultValue: Navigation.currentLocation[1] === 'search' && Navigation.currentLocation[2] && unescape(Navigation.currentLocation[2]) || Navigation.currentSearch.q || '',
    initialAutoFocus: false,
  }
  componentDidMount() {
    WindowSize.onStateUpdate(this);
    LogicBaseCollectionCache.onStateUpdate(this);
    window.addEventListener('blur', this.windowBlurred);
  }
  componentWillUnmount() {
    WindowSize.offStateUpdate(this);
    LogicBaseCollectionCache.offStateUpdate(this);
    window.removeEventListener('blur', this.windowBlurred);
    const { mobile } = WindowSize;
    if (mobile) {
      document.body.style.overflow = 'unset';
      document.body.style.position = 'unset';
    }
  }
  windowBlurred = () => {
    this.endSearching();
  }
  onscroll = () => {
    if (this.__input) this.__input.blur();
  }
  onKeyDown = (e) => {
    if (e.key === 'Enter') this.onSearch();
    this.getTrending();
  }
  getTrending = () => {
    if (this.searchTimeout) clearTimeout(this.searchTimeout);
    this.searchTimeout = setTimeout(this.performSearch, 250);
  }
  performSearch = () => {
    this.forceUpdate();
  }
  onSearch = () => {
    if (!this.__input) return;
    const search = this.__input.value.trim();
    if (search) {
      if (Constants.isIphone) Navigation.push(`/search/${encodeURIComponent(search)}`);
      else Navigation.push(`/search?q=${encodeURIComponent(search)}`);
    }
    else Navigation.push('/search');
    this.endSearching();
  }
  endSearching = () => {
    const { onSearching } = this.props;
    if (this.__input) this.__input.blur();
    this.setState({ showText: !this.__input.value, showSuggestions: false });
    onSearching(false);
  }
  render() {
    const { mobile } = WindowSize;
    const { autoFocus, onSearching } = this.props;
    const { defaultValue, showSuggestions, initialAutoFocus } = this.state;
    if (!showSuggestions) {
      searches = [];
    }
    const showFullscreen = mobile && showSuggestions;
    if (mobile) {
      document.body.style.overflow = 'hidden';
      document.body.style.position = 'fixed';
    } else {
      document.body.style.overflow = 'unset';
      document.body.style.position = 'unset';
    }
    const style = showFullscreen && { position: 'fixed', inset: 0, width: '100%', height: '100%', zIndex: 1, background: 'white' } || { margin: '0.5rem auto', border: showSuggestions && !mobile && '0' || '1px solid #C1C1C1', borderRadius: '1rem', transform: showSuggestions && !mobile && 'translateY(163px)', boxShadow: showSuggestions && !mobile && 'rgba(136, 152, 170, 0.1) 0px 0px 0px 1px, rgba(49, 49, 93, 0.1) 0px 15px 35px, rgba(0, 0, 0, 0.08) 0px 5px 15px' };
    const search = this.__input && this.__input.value.trim();
    const showCollections = search && LogicBaseCollectionCache.query({ search });
    if (showCollections && showCollections.length > 0) {
      searches = showCollections;
    }
    const html = <Search style={style}>
      <div
        style={{ position: showFullscreen && 'fixed' || null, top: 0, left: 0, right: 0 }}
        onMouseDown={(e) => { e.stopPropagation(); }}>
        {showFullscreen && <ArrowLeftIcon
          style={{ width: 16, height: 16, margin: '16px', position: 'absolute', top: 0, left: 0 }}
          onClick={() => {
            this.endSearching();
          }}
        />}
        <input
          key={showSuggestions ? 'searching' : 'notsearching'}
          style={{
            padding: showFullscreen ? `1rem 3rem 1rem 3rem` : `10px 3rem 10px 1rem`
          }}
          onBlur={() => {
            if (mobile) return;
            this.endSearching();
          }}
          enterKeyHint="search"
          defaultValue={defaultValue}
          onMouseDown={(e) => {
            if (!this.state.showSuggestions) {
              e.preventDefault();
              e.stopPropagation();
              this.setState({ showText: false, showSuggestions: true });
              onSearching(true);
              this.performSearch();
            }
          }}
          ref={(e) => {
            this.__input = e;
            if (this.__input && showSuggestions) {
              setTimeout(() => {
                if (this.__input) this.__input.focus();
              });
            } else if (this.__input && autoFocus && !initialAutoFocus) {
              this.setState({ showText: false, showSuggestions: true, initialAutoFocus: true });
              onSearching(true);
              this.performSearch();
              this.__input.focus();
            }
          }}
          onKeyDown={this.onKeyDown}
          placeholder="Search"
        ></input>
        {!showSuggestions && <SearchIcon
          style={{ width: 18, height: 18, opacity: 0.3, margin: showFullscreen ? '16px' : '10px 16px', position: 'absolute', top: 0, right: 0 }}
          onClick={this.onSearch}
        /> || <CloseIcon
          style={{ width: 14, height: 14, margin: showFullscreen ? '18px 16px' : '12px 16px', position: 'absolute', top: 0, right: 0 }}
          onMouseDown={(e) => {
            e.preventDefault(); e.stopPropagation(); this.__input.value = ''; this.performSearch();
          }}
        />}
      </div>
      {showSuggestions && <>
        <div style={{
          background: '#C1C1C1',
          width: 'calc(100% - 2rem)',
          height: 1,
          margin: '0 1rem',
          position: showFullscreen && 'absolute' || null,
          left: 0,
          top: 50
        }}></div>
        <Trending
          onTouchStart={mobile && this.onscroll || null}
          ref={(e) => { if (e && this.__input) { this.__input.focus(); } }}
          style={{
            height: showFullscreen ? 'calc(100% - 38px)' : '325px',
            marginTop: showFullscreen && 51 || null
          }}
        >
          <div>
            {searches.length > 0 && searches.map(({ _id, title }) => (
              <div key={_id} onMouseDown={(e) => {
                e.preventDefault();
                e.stopPropagation();
                Navigation.push(`/collections/${_id}`);
                onSearching(false);
              }}>{title}</div>
            ))}
          </div>
        </Trending>
      </>}
    </Search>;
    if (showFullscreen) return <div>{ReactDOM.createPortal(html, document.body)}</div>;
    return html
  }
}
