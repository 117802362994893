/*
 * FeaturePage
 *
 * List all the features
 */
import React from 'react';
import styled from 'styled-components';
import Constants from '../../submodules/logictry_config/constants';
import Navigation from '../../services/Navigation';
import UserAccount from '../../services/UserAccount';
import StatefulTextField from '../../components/StatefulTextField/index';
import StatefulButton from '../../components/StatefulButton';
import ChangePassword from './ChangePassword';
import HorizontalCenterDiv from '../../styledhtml/HorizontalCenterDiv';
import FullScreenVerticalCenterContent from '../../styledhtml/FullScreenVerticalCenterContent';
import RegisterCompany from './RegisterCompany';
import LoadingIndicator from '../../components/LoadingIndicator';
import Button from '../../components/Button';
import { isFullnameInvalid, isUsernameInvalid, isEmailInvalid } from '../../utils/regex';

const Wrapper = styled.div`
  a {
    text-decoration: none;
  }
`;
const SocialLogins = styled.div`
  margin-top: 2rem;
  display: flex;
  > div {
    color: ${Constants.PrimaryColor};
    cursor: pointer;
    padding: 0.5rem 1rem;
    margin: 0.5rem;
    font-size: 1rem;
    border-radius: 0.5rem;
  }
`;
const REGISTER_COMPANY = 'RegisterCompany';
const CHANGE_PASSWORD = 'ChangePassword';
const { innerHeight } = window;

export default class RegisterPage extends React.PureComponent {
  constructor(props, context) {
    super(props, context);
    const params = Navigation.currentSearch;
    this.state = {
      registeredScreen: CHANGE_PASSWORD,
      emailReady: false,
      email: params.email || UserAccount.account.email || '',
      fullname: UserAccount.account.fullname || '',
      username: UserAccount.account.username || '',
      token: params.token || '',
      warning: ''
    };
    const { token, email } = this.state;
    if (token && email) UserAccount.verifyAccount(email, token);
  }
  componentDidMount() {
    UserAccount.onStateUpdate(this.accountUpdate);
    this.accountUpdate();
  }
  componentWillUnmount() {
    if (!UserAccount.isLoggedIn) {
      UserAccount.account.error = '';
    }
    UserAccount.offStateUpdate(this.accountUpdate);
  }
  getRegisterContent = () => [
    <h1 key="register">Register</h1>,
    <StatefulTextField
      key="fullname"
      value={this.state.fullname}
      onChange={(fullname) => { this.state.fullname = fullname; this.forceUpdate(); }}
      label="Full Name"
      maxLength={50}
      style={{ minWidth: 120, maxWidth: '80%', width: 400 }}
      autoFocusOnLoad={!Constants.isIphone}
    />,
    <StatefulTextField
      key="username"
      value={this.state.username}
      onChange={(username) => { this.setState({ username }); }}
      label="Username (optional)"
      maxLength={30}
      style={{ minWidth: 120, maxWidth: '80%', width: 400 }}
      dontAllowSpaces
    />,
    <StatefulTextField
      key="email"
      value={this.state.email}
      onChange={(email) => { this.state.email = email; this.forceUpdate(); }}
      label="Email"
      type="email"
      name="email"
      maxLength={256}
      autoComplete
      style={{ minWidth: 120, maxWidth: '80%', width: 400 }}
    />,
    <div key="button" style={{ margin: '2rem 0' }}>
      <StatefulButton
        variant="raised"
        onClick={this.nextPressed}
        text="Sign Up"
        warning={this.state.warning}
      />
    </div>,
    <Button url={Navigation.login} tabIndex={-1}>
      <span style={{ fontSize: Constants.SemiSmallFontSize }}>Already have an account?</span>
    </Button>,
    <SocialLogins key="social">
      <div ref={(e) => {
        if (e && document.getElementById('google-sign-in')) {
          e.appendChild(document.getElementById('google-sign-in'));
          document.getElementById('google-sign-in').style.display = null;
        }
      }}></div>
    </SocialLogins>
  ];

  getCheckEmailContent = () => (
    <HorizontalCenterDiv>
      <h1>Success!</h1>
      <h3>Check Email for Verification</h3>
      <div style={{ margin: 10 }}>
      </div>
    </HorizontalCenterDiv>
  );

  getRegisteredContent = () => {
    const { registeredScreen } = this.state;
    return (
      <HorizontalCenterDiv>
        {registeredScreen === REGISTER_COMPANY && <RegisterCompany onComplete={this.companyRegistered} />}
        {registeredScreen === CHANGE_PASSWORD && <ChangePassword email={this.state.email} />}
      </HorizontalCenterDiv>
    );
  }

  isRegistered = () => {
    if (this.state.token && UserAccount.exists) return true;
    return false;
  }
  nextPressed = () => {
    if (this.state.fullname === '') {
      this.state.warning = 'Full Name blank';
      return this.forceUpdate();
    }
    if (isFullnameInvalid(this.state.fullname)) {
      this.state.warning = 'Invalid fullname';
      return this.forceUpdate();
    }
    if (this.state.username && isUsernameInvalid(this.state.username)) {
      this.state.warning = 'Invalid username';
      return this.forceUpdate();
    }
    if (this.state.email === '') {
      this.state.warning = 'Email blank';
      return this.forceUpdate();
    }
    if (isEmailInvalid(this.state.email)) {
      this.state.warning = 'Invalid email';
      return this.forceUpdate();
    }
    return UserAccount.registerAccount({ username: this.state.username, fullname: this.state.fullname, email: this.state.email, reply: 'Welcome' }).then((r) => {
      if (!r) {
        return this.forceUpdate();
      }
      if (r.error === 'InvalidUsername') {
        this.state.warning = 'Invalid username';
        return this.forceUpdate();
      }
      if (r.error === 'UsernameAlreadyRegistered') {
        this.state.warning = 'Username is already in use';
        return this.forceUpdate();
      }
      if (r.error === 'EmailAlreadyRegistered') {
        this.state.warning = 'Email already registered';
        return this.forceUpdate();
      }
      if (r.error) {
        this.state.warning = 'Something went wrong';
        return this.forceUpdate();
      }
      this.state.warning = 'Success!';
      return this.setState({ emailReady: true });
    });
  };

  companyRegistered = () => this.setState({ registeredScreen: CHANGE_PASSWORD });

  accountUpdate = () => {
    return this.forceUpdate();
  }

  render() {
    const { token, email } = this.state;
    const registered = this.isRegistered();
    const emailReady = !registered && this.state.emailReady;
    const register = !registered && !emailReady;
    if (!UserAccount.account) setTimeout(() => { throw new Error('UserAccount was empty') });
    if (UserAccount.account && UserAccount.account.error === 'VerifyAccountFailed') return <FullScreenVerticalCenterContent>
      <h1>Link Expired</h1>
    </FullScreenVerticalCenterContent>;
    if (token && email && (!UserAccount.account || !UserAccount.account.hasOwnProperty('password'))) {
      return <FullScreenVerticalCenterContent><LoadingIndicator /></FullScreenVerticalCenterContent>;
    }
    return (
      <Wrapper style={{ position: 'absolute', top: 0, right: 0, left: 0, height: Constants.isIphone ? `${innerHeight - 124}px` : '100%' }}>
        <FullScreenVerticalCenterContent>
          {register && this.getRegisterContent()}
          {emailReady && this.getCheckEmailContent()}
          {registered && this.getRegisteredContent()}
        </FullScreenVerticalCenterContent>
      </Wrapper>
    );
  }
}
