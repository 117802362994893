import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Constants from '../../submodules/logictry_config/constants';
import UserCache from '../../services/cache/UserCache';
import { getAppLikes, getAppPins, getCollectionSubscribers, getCommentLikes, getUserFollowees, getUserFollowers } from '../../services/Pagination';
import UserProfileHeader from '../UserProfileHeader';
import Scrollable from '../Scrollable';
import Popup from '../Popup';

const Wrapper = styled.form`
  padding: 2rem 3rem;
  max-width: 500px;
  h1 {
    text-align: center;
  }
  label {
    width: 100%;
    display: flex;
    align-items: center;
    margin: 0.25rem 0;
  }
  input {
    margin-right: 8px;
  }
  h2 {
    margin-top: 2rem;
    font-size: 1rem;
  }
  code {
    font-size: 0.8rem;
  }
  details {
    margin-top: 2rem;
  }
  summary {
    font-size: 1rem;
    margin-bottom: 1rem;
    cursor: pointer;
  }
`;

const NotificationWrapper = styled.div`
  width: 52rem;
  max-width: 100%;
  margin: auto;
  display: flex;
  flex-direction: column;
  background: white;

  > h1 {
    margin: 0 0 2rem;
  }
`;
const Request = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  cursor: pointer;
  > * {
    margin: 0.5rem;
    ${Constants.MediaMobile} {
      margin: 0.5rem auto;
    }
  }
  > div:nth-child(2) {
    flex: 1;
  }
`;

export default class LogicBaseUsersPopup extends React.PureComponent {
  static propTypes = {
    onClose: PropTypes.func,
    content: PropTypes.string,
    id: PropTypes.string
  }
  componentDidMount() {
    UserCache.onStateUpdate(this);
  }
  componentWillUnmount() {
    UserCache.offStateUpdate(this);
    if (this.cache) this.cache.offStateUpdate(this);
  }
  onPagination = () => {
    if (this.cache.showMore && this.cache.allFound && this.cache.initialized) {
      this.cache.getMore();
    }
  }
  render() {
    const { onClose, content, id } = this.props;
    let title;
    let users;
    let noneFoundText;
    if (content === 'CommentLikes') {
      if (!this.cache) {
        this.cache = getCommentLikes(id);
        this.cache.onStateUpdate(this);
      }
      users = this.cache.currentPage.map(({ follower }) => follower);
      title = 'Comment Likes';
      noneFoundText = 'No likes yet';
    } else if (content === 'CollectionSubscribers') {
      if (!this.cache) {
        this.cache = getCollectionSubscribers(id);
        this.cache.onStateUpdate(this);
      }
      users = this.cache.currentPage.map(({ follower }) => follower);
      title = 'Collection Subscribers';
      noneFoundText = 'No subscribers yet';
    } else if (content === 'Followers') {
      if (!this.cache) {
        this.cache = getUserFollowers(id);
        this.cache.onStateUpdate(this);
      }
      users = this.cache.currentPage.map(({ follower }) => follower);
      title = 'Followers';
      noneFoundText = 'No followers yet';
    } else if (content === 'Following') {
      if (!this.cache) {
        this.cache = getUserFollowees(id);
        this.cache.onStateUpdate(this);
      }
      users = this.cache.currentPage.map(({ followee }) => followee);
      title = 'Following';
      noneFoundText = 'Not following anyone yet';
    } else {
      if (!this.cache) {
        this.cache = content === 'AppSaves' && getAppPins(id) || getAppLikes(id);
        this.cache.onStateUpdate(this);
      }
      users = this.cache.currentPage.map(({ follower }) => follower);
      title = content === 'AppSaves' && 'App Saves' || 'App Likes';
      noneFoundText = content === 'AppSaves' && 'No pins yet' || 'No likes yet';
    }
    if (!this.cache.currentPage || (this.cache.currentPage.length === 0 && !this.cache.allFound)) return null;
    this.users = UserCache.getUsersByIds(users) || this.users;
    if (!this.users) return null;
    return (
      <Popup
        onClose={onClose}
        allowBackgroundClose
        showCloseButton
      >
        <Scrollable
          vertical
          paginationHeight={200}
          onPagination={this.onPagination}
          style={{ width: '100%' }}
          popupMaxHeight
        >
          <Wrapper>
            <NotificationWrapper>
              <h1>{title}</h1>
              {this.users && this.users.length > 0 ? this.users.map((user) => (
                <Request key={user._id}>
                  <UserProfileHeader
                    user={user}
                    small
                  />
                </Request>
              )) : (
                <div style={{ flex: 1, display: 'flex', justifyContent: 'center' }}>
                  <div>{noneFoundText}</div>
                </div>
              )}
            </NotificationWrapper>
          </Wrapper>
        </Scrollable>
      </Popup>
    );
  }
}
