/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-nested-ternary */
import React from 'react';
import styled from 'styled-components';
import { PropTypes } from 'prop-types';
import Constants from '../../submodules/logictry_config/constants';
import GrayOut from '../../services/TreeDisplay';
import RenderedHtmlToTextArea from '../../components/RenderedHtmlToTextArea/index';
import { CALENDAR_MAP } from '../../models/nodetypes';
// eslint-disable-next-line import/no-cycle
import CreateChildren from './CreateChildren';
import OpenStreetMapBox from './OpenStreetMapBox';
import { ExpandIcon } from '../../styledhtml/Icon';

const CalendarWrapper = styled.div`
  margin: 12px 0px 0px ${Constants.TreeContentIconIndent};
  position: relative;
  .leaflet-control-attribution {
    display: none;
  }
  > *:first-child {
    z-index: 0;
  }
`;
const Wrapper = styled.div`
  position: relative;
  > * {
    width: 100%;
  }
`;
const FullscreenWrapper = styled.div`
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 4;
  .leaflet-control-attribution {
    display: none;
  }
`;
const FullscreenButton = styled.div`
  position: absolute;
  top: 6px;
  right: 6px;
  padding: 6px;
  background-color: ${Constants.White};
  border-radius: 4px;
  box-shadow: 0 1px 5px rgba(0,0,0,0.65);
`;

export default class CalendarMapBox extends React.PureComponent {
  static propTypes = {
    node: PropTypes.object,
  }
  state = {
    fullscreen: false,
  }
  componentDidMount() {
    const { node } = this.props;
    node.onStateUpdate(this);
  }
  componentWillUnmount() {
    const { node } = this.props;
    node.offStateUpdate(this);
  }
  render() {
    const { node } = this.props;
    const { fullscreen } = this.state;
    const { calendarEventsSelected } = node;
    const { editing } = GrayOut;
    const height = 200;

    const markers = {};
    calendarEventsSelected.forEach((ce) => {
      const venue = ce.location;
      const lat = ce.latitude;
      const lng = ce.longitude;
      if (lat && lng) {
        const newMapKey = `${lat}_${lng}`;
        if (!markers[newMapKey]) markers[newMapKey] = { lat, lng };
        if (!markers[newMapKey].label) markers[newMapKey].label = `<div style="font-size: 16px;">${venue}</div>`;
        markers[newMapKey].label = `${markers[newMapKey].label}<div style="font-size: 12px;  margin-left: 16px; margin-top: 4px;"><strong style="color: ${ce.backgroundColor}; filter: brightness(0.8);">${ce.title}</strong></div>`;
      }
    });
    const iconStyle = { width: 16, height: 16 };

    if (!editing) {
      if (fullscreen) {
        return (
          <FullscreenWrapper>
            <OpenStreetMapBox
              markers={markers}
            />
            <FullscreenButton style={{ zIndex: 1000 }}>
              <ExpandIcon style={iconStyle} onClick={() => this.setState({ fullscreen: false })} />
            </FullscreenButton>
          </FullscreenWrapper>
        );
      }
      return (
        <CalendarWrapper style={{ height: height + 1 }}>
          <OpenStreetMapBox
            markers={markers}
          />
          <FullscreenButton>
            <ExpandIcon style={iconStyle} onClick={() => this.setState({ fullscreen: true })} />
          </FullscreenButton>
        </CalendarWrapper>
      );
    }

    return (
      <Wrapper>
        <RenderedHtmlToTextArea node={node} placeholderText={CALENDAR_MAP} />
        <CreateChildren node={node} />
      </Wrapper>
    );
  }
}
