/* eslint-disable react/no-danger */
import React from 'react';
import styled from 'styled-components';
import { PropTypes } from 'prop-types';
import Constants from '../../submodules/logictry_config/constants';
import InfoPopup from './InfoPopup';
import GrayOut from '../../services/TreeDisplay';
// eslint-disable-next-line import/no-cycle
import CreateChildren from './CreateChildren';
import RenderedHtmlToTextArea from '../../components/RenderedHtmlToTextArea/index';
import { CHECKLIST_ITEM } from '../../models/nodetypes';
import ClickableIcon from '../../components/ClickableIcon/index';
import RawHtml from '../../components/RawHtml/index';
import AddNote from './AddNote';
import { CheckedBoxIcon, UncheckedBoxIcon } from '../../styledhtml/Icon';

const Wrapper = styled.div`
  margin: 0px 0px 0px ${Constants.TreeContentHalfIndent};
`;
const AnswerWrapper = styled.div`
  display: flex;
  align-items: center;
  .question-box-hide-till-hover {
    opacity: 0;
  }
  &:hover {
    .question-box-hide-till-hover {
      opacity: 1 !Important;
    }
  }
`;
const MultipleChoiceAnswer = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
`;

export default class ItemBox extends React.PureComponent {
  static propTypes = {
    item: PropTypes.object,
  }
  componentDidMount() {
    const { item } = this.props;
    item.onStateUpdate(this);
    GrayOut.onStateUpdate(this);
  }
  componentWillUnmount() {
    const { item } = this.props;
    item.offStateUpdate(this);
    GrayOut.offStateUpdate(this);
  }
  getAnswerChildren = (checked, item) => {
    const { editing } = GrayOut;
    if (!checked && !editing) return null;
    return <CreateChildren node={item} />;
  }
  handleClick = (e, item) => {
    e.stopPropagation();
    e.preventDefault();
    const { editing } = GrayOut;
    if (editing) return;
    item.toggleUserVote();
    GrayOut.setExpanded(item, item.checked);
  }
  render() {
    const { item } = this.props;
    const { editing, tree } = GrayOut;
    const { root, isDisabled } = tree;
    const { showInlineNotes } = root;
    const expanded = GrayOut.isExpanded(item);
    /**
     * Render
     */
    const { info, checked, enabled, note } = item;
    const itemChildren = this.getAnswerChildren(checked, item);
    const itemText = item.formattedProjectText;
    const disabled = !editing && !enabled;
    const childrenMarginLeft = 12;
    const { primaryColor } = GrayOut.tree.root;
    const iconStyle = { width: 16, height: 16, margin: 12, backgroundColor: primaryColor };
    return (
      <Wrapper>
        <AnswerWrapper>
          <MultipleChoiceAnswer style={{ opacity: disabled ? 0.2 : 1, pointerEvents: disabled ? 'none' : null }}>
            {(checked ? (
              <CheckedBoxIcon
                style={iconStyle}
                onFocus={() => { GrayOut.addActiveObject(item); }}
                onClick={(e) => this.handleClick(e, item)} />
            ) : (
              <UncheckedBoxIcon
                style={iconStyle}
                onFocus={() => { GrayOut.addActiveObject(item); }}
                onClick={(e) => this.handleClick(e, item)} />
            ))}
            {((item.owner || !item.text) || editing) ? ( // eslint-disable-line
              <RenderedHtmlToTextArea node={item} placeholderText={CHECKLIST_ITEM} />
            ) : (
              <RawHtml html={itemText} />
            )}
            {info && <InfoPopup text={info.text} />}
            <div className="question-box-hide-till-hover">
              {showInlineNotes && !isDisabled && !editing && !note && checked && <AddNote node={item} />}
            </div>
          </MultipleChoiceAnswer>
        </AnswerWrapper>
        <div style={{ marginLeft: childrenMarginLeft }}>
          {((editing || expanded)) && <div>{itemChildren}</div>}
        </div>
      </Wrapper>
    );
  }
}
