// import React from 'react';
// import styled from 'styled-components';

import Constants from '../../submodules/logictry_config/constants';
import Navigation from '../../services/Navigation';
import { AccountProjectsOpen, AccountProjectsDeleted } from '../../services/Pagination/index';
import Box from './Box';
import ProjectTreeSettings from '../../components/ObjectSettings/ProjectTreeSettings';
import { SubmissionsIcon } from '../../styledhtml/Icon';


export default class TemplateProjectsBox extends Box {
  name = 'Submissions';
  missingTitleText = 'New Submission';
  paginationService = this.props.paginationService || this.props.showDeleted && AccountProjectsDeleted || AccountProjectsOpen;
  backgroundColor = Constants.PrimaryColor;
  boxIcon = <SubmissionsIcon style={{ width: 16, height: 16, filter: 'invert(1)' }} />;
  open = (_tree) => Navigation.push(`/apps/${_tree._id}`);
  objectSettings = ProjectTreeSettings;
}
