/* eslint-disable no-nested-ternary */
import React from 'react';
import styled from 'styled-components';
import { PropTypes } from 'prop-types';
import Constants from '../../submodules/logictry_config/constants';
import GrayOut from '../../services/TreeDisplay';
import WindowSize from '../../services/WindowSize';
// eslint-disable-next-line import/no-cycle
import CreateChild from './CreateChild';
import { CaretDownIcon, CaretLeftIcon, CaretRightIcon, CaretUpIcon } from '../../styledhtml/Icon';

const CollapseIcon = styled.i`
  cursor: pointer;
  height: 12px;
  width: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const SplitPane = styled.div`
  > .pane-box__collapse-icon {
    visibility: hidden;
  }
  &:hover {
    > .pane-box__collapse-icon {
      visibility: unset;
    }
  }

`;
const PaneDivider = styled.div`
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  > div {
    display: flex;
  }
`;
const HorizontalPaneDivider = styled(PaneDivider)`
  padding-left: ${Constants.TreeContentIconIndent};
  width: 100%;
  > div {
    transform: translateY(4.375px);
    flex-direction: column;
  }
`;
const VerticalPaneDivider = styled(PaneDivider)`
  height: 100%;
  > div {
    flex-direction: row;
  }
`;
const VerticalPaneName = styled.div`
  width: 40px;
  text-align: center;
  padding-left: ${Constants.TreeContentIconIndent};
  display: flex;
  align-items: center;
  justify-content: center;
  > div {
    transform: rotate(-90deg);
    white-space: nowrap;
  }
`;
const HorizontalPaneName = styled.div`
  height: 40px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: ${Constants.TreeContentIconIndent};
  > div {
    white-space: nowrap;
  }
`;

const CLOSED_SIZE = 30;

export default class SplitPaneBox extends React.PureComponent {
  static propTypes = {
    splitPane: PropTypes.object,
  }
  state = {
    sizes: {},
  }
  componentDidMount() {
    const { splitPane } = this.props;
    splitPane.onStateUpdate(this);
    GrayOut.onStateUpdate(this);
    WindowSize.onStateUpdate(this);
    window.addEventListener('resize', this.updateWidth);
  }
  componentWillUnmount() {
    const { splitPane } = this.props;
    splitPane.offStateUpdate(this);
    GrayOut.offStateUpdate(this);
    WindowSize.offStateUpdate(this);
    window.removeEventListener('resize', this.updateWidth);
  }
  grayOutUpdate = () => {
    this.forceUpdate();
  }
  changeValue = (value) => {
    const { splitPane } = this.props;
    splitPane.updateText(value);
  }
  updateWidth = (pane, up) => {
    const { sizes } = this.state;
    if (up) {
      if (sizes[pane] === CLOSED_SIZE) delete sizes[pane];
      else sizes[pane - 1] = CLOSED_SIZE;
    } else if (sizes[pane - 1] === CLOSED_SIZE) delete sizes[pane - 1];
    else sizes[pane] = CLOSED_SIZE;
    this.forceUpdate();
  }
  render() {
    const mobile = window.innerWidth < Constants.TabletWidth;
    const { splitPane } = this.props;
    const { sizes } = this.state;
    const { vertical, panes } = splitPane;
    const showVerticalPaneDivider = vertical && !mobile;
    const flexDirection = showVerticalPaneDivider ? 'row' : 'column';
    const display = mobile ? null : 'flex';
    const width = mobile ? null : '100%';
    const height = mobile ? null : '100%';
    const flex = mobile ? null : 1;
    const iconStyle = { width: 16, height: 16 };
    return (
      <SplitPane style={{ flexDirection, display, width, height, flex }}>
        {panes.map((_pane, i) => {
          const prevousSize = sizes[i - 1];
          const size = sizes[i];
          const { contentHeight } = _pane;
          const previousPane = panes[i - 1];
          // const nextPane = panes[i + 1]
          const showUpOrLeft = previousPane && previousPane.collapsible && prevousSize !== CLOSED_SIZE || _pane && _pane.collapsible && size === CLOSED_SIZE;
          const showDownOrRight = previousPane && previousPane.collapsible && prevousSize === CLOSED_SIZE || _pane && _pane.collapsible && size !== CLOSED_SIZE;
          let flex = size === CLOSED_SIZE ? 0 : 1;
          if (contentHeight) flex = null;
          return [
            (i > 0) && (showVerticalPaneDivider ? (
              <VerticalPaneDivider style={{ width: (showUpOrLeft || showDownOrRight) ? 20 : 2 }} key={`${_pane.key}_divider`}>
                <div style={{ transform: 'translateX(14px)' }}>
                  {showUpOrLeft && <CaretLeftIcon style={iconStyle} onClick={() => { this.updateWidth(i, true); }} />}
                  {showDownOrRight && <CaretRightIcon style={iconStyle} onClick={() => { this.updateWidth(i, false); }} />}
                </div>
              </VerticalPaneDivider>
            ) : (
              <HorizontalPaneDivider style={{ height: (showUpOrLeft || showDownOrRight) ? 20 : 2 }} key={`${_pane.key}_divider`}>
                <div>
                  {showUpOrLeft && <CaretUpIcon style={iconStyle} onClick={() => { this.updateWidth(i, true); }} />}
                  {showDownOrRight && <CaretDownIcon style={iconStyle} onClick={() => { this.updateWidth(i, false); }} />}
                </div>
              </HorizontalPaneDivider>
            )),
            (size === CLOSED_SIZE) ? (
              (showVerticalPaneDivider) ? (
                <VerticalPaneName key={`${_pane.key}_divider_text`}>
                  <div>{_pane.text || 'Expand'}</div>
                </VerticalPaneName>
              ) : (
                <HorizontalPaneName key={`${_pane.key}_divider_text`}>
                  {_pane.text || 'Expand'}
                </HorizontalPaneName>
              )
            ) : (
              <div key={`${_pane.key}`} style={{ flex, height: size, position: 'relative' }}>
                <CreateChild key={_pane.key} node={_pane} />
              </div>
            )
          ]
        })}
      </SplitPane>
    );
  }
}
