import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Constants from '../../submodules/logictry_config/constants';
import Popup from '../Popup/index';
import MenuList from '../Popup/MenuList';
import MenuItem from '../Popup/MenuItem';
import RawHtml from '../RawHtml/index';
import { AngleDownIcon } from '../../styledhtml/Icon';

const Wrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;

  select {
    appearance: none;
    cursor: pointer;
    border-radius: 8px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;
const DropDown = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;
  text-align: center;
  > * {
    padding: 9.5px 6px;
  }
`;
const DropDownNoPadding = styled(DropDown)`
  > * {
    padding: 0px;
  }
`;
const Label = styled.div`
  padding: 10px 32px 0px 11px;
`;


export default class SelectMenu extends React.PureComponent {
  static propTypes = {
    options: PropTypes.array.isRequired,
    value: PropTypes.any,
    label: PropTypes.string,
    onChange: PropTypes.func,
    style: PropTypes.object,
    mini: PropTypes.bool,
    anchorBottomRight: PropTypes.bool,
    noPadding: PropTypes.bool,
    hideCaret: PropTypes.bool,
    asciiVersion: PropTypes.bool
  }
  state = {
    open: false,
  }
  selectChanged = (_value) => {
    this.setState({ open: false });
    if (this.props.onChange) this.props.onChange(_value);
  }
  openMenu = () => this.setState({ open: true });
  closeMenu = () => this.setState({ open: false });

  render() {
    const { mini, anchorBottomRight, value, options, noPadding, hideCaret, asciiVersion } = this.props;
    const foundOption = options.find((o) => o.value === value);
    const selectText = (foundOption && foundOption.name) || value;
    const DropDownElement = noPadding && DropDownNoPadding || DropDown;
    const fontSize = mini ? Constants.SmallFontSize : 'unset';
    return (
      <Wrapper style={this.props.style}>
        {this.props.label && <Label>{this.props.label}</Label>}
        <DropDownElement role="button" onClick={this.openMenu} ref={(e) => { this.anchorEl = e; }}>
          <RawHtml style={{ fontSize }} html={selectText} />
          {!hideCaret && (asciiVersion ? <div style={{ marginTop: mini ? -8 : -10, fontSize: mini ? 14 : 18, fontWeight: 'bold' }}>⌄</div>
            : <AngleDownIcon style={{ width: mini ? 8: 10, height: mini ? 8: 10, padding: 0 }} />)}
        </DropDownElement>
        {this.state.open && <Popup
          allowBackgroundClose
          onClose={this.closeMenu}
          anchorEl={this.anchorEl}
          anchorBottomRight={anchorBottomRight}
        >
          <MenuList>
            {options.map((s) => (
              <MenuItem style={{ backgroundColor: s.checked ? 'rgba(0, 0, 0, 0.08)' : '' }} key={s.value} onClick={() => this.selectChanged(s.value)}>
                <RawHtml html={s.name} />
              </MenuItem>
            ))}
          </MenuList>
        </Popup>}
      </Wrapper>
    );
  }
}
