import Service from './Service';
import UserAccount from './UserAccount';

const DEFAULT_DRAWER_STATE = {
  personal: true,
  content: true,
  company: true,
};

const defaultState = {
  version: '0',
  drawerClosed: true,
  drawerState: { ...DEFAULT_DRAWER_STATE },
  listGridView: 'grid',
  contentView: 'Submissions',
  showWysiwyg: false,
  showNodeTypes: true,
};

const stateKey = 'logictry.state';
let _state = {};

class Storage extends Service {
  get state() {
    return _state;
  }

  LoadState() {
    const currentState = this.__getItem(stateKey) || {};
    _state = { ...defaultState, ...currentState };
  }
  setVersion(_version) {
    _state.version = _version;
    this.__setItem(stateKey, _state);
  }
  setDrawerClosed(_closed) {
    _state.drawerClosed = _closed;
    this.__setItem(stateKey, _state);
  }
  setListGridView(_type) {
    _state.listGridView = _type;
    this.__setItem(stateKey, _state);
  }
  setShowWysiwyg(_showWysiwyg) {
    _state.showWysiwyg = _showWysiwyg;
    this.__setItem(stateKey, _state);
  }
  setShowNodeTypes(_showNodeTypes) {
    _state.showNodeTypes = _showNodeTypes;
    this.__setItem(stateKey, _state);
  }
  setContentView(_contentView) {
    _state.contentView = _contentView;
    this.__setItem(stateKey, _state);
  }
  setDrawerState(_name, _open) {
    _state.drawerState[_name] = _open;
    this.__setItem(stateKey, _state);
  }

  __getItem(key) {
    try {
      return JSON.parse(localStorage.getItem(key));
    } catch (e) {
      return null;
    }
  }
  __setItem(key, value) {
    try {
      localStorage.setItem(key, JSON.stringify(value));
    } catch (e) {
      //
    }
  }
  __removeItem(key) {
    try {
      localStorage.removeItem(key);
    } catch (e) {
      //
    }
  }
}

const singleton = new Storage();
singleton.LoadState();
let _lastAccountState;
UserAccount.onStateUpdate(() => {
  if (UserAccount.state === 'loggedOut' && _lastAccountState !== 'loggedOut') {
    _state.drawerState = { ...DEFAULT_DRAWER_STATE };
    singleton.__setItem(stateKey, _state);
  }
  _lastAccountState = UserAccount.state;
});
export default singleton;
