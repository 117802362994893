/* eslint-disable no-nested-ternary */
import React from 'react';
import styled from 'styled-components';
import { PropTypes } from 'prop-types';
import Constants from '../../submodules/logictry_config/constants';
import TreeDisplay from '../../services/TreeDisplay';
import CreateProject from '../../services/CreateProject';
// eslint-disable-next-line import/no-cycle
import CreateChildren from './CreateChildren';
import goToReport from './actions/gotoreport';
import { recursivelyCheckIfRequiredNodesEmpty } from '../../models/tree';
import RawHtml from '../../components/RawHtml/index';
import LoadingIndicator from '../../components/LoadingIndicator';

const Wrapper = styled.div`
  margin: 0px 0px 0px ${Constants.TreeContentIconIndent};
`;
const ActionButton = styled.div`
  cursor: pointer;
  padding: ${Constants.defaultLinePadding} 0;
  > div {
    display: flex;
  }
`;

export default class ActionBox extends React.PureComponent {
  static propTypes = {
    node: PropTypes.object,
  }
  state = {
    showRequiredText: false,
  }
  componentDidMount() {
    CreateProject.onStateUpdate(this);
    const { node } = this.props;
    const { autoRun } = node;
    node.onStateUpdate(this);
    const { tree } = TreeDisplay;
    if (tree.template && autoRun) this.onClick();
    tree.onStateUpdate(this.treeUpdated);
  }
  componentWillUnmount() {
    CreateProject.offStateUpdate(this);
    const { node } = this.props;
    node.offStateUpdate(this);
    const { tree } = TreeDisplay;
    tree.offStateUpdate(this.treeUpdated);
  }
  treeUpdated = () => {
    const { tree } = TreeDisplay;
    if (!this.state.showRequiredText) return;
    const found = recursivelyCheckIfRequiredNodesEmpty(tree.root);
    if (!found) return this.setState({ showRequiredText: false });
  }
  onClick = () => {
    const { node } = this.props;
    const { isInsideWiki } = node;
    const { tree } = TreeDisplay;
    const { isDisabled } = tree;
    if (isDisabled) return;
    const found = !isInsideWiki && recursivelyCheckIfRequiredNodesEmpty(tree.root);
    if (found) return this.setState({ showRequiredText: true });
    return goToReport();
  }
  render() {
    const { isRegisteringAndCreatingProject, numberOfQueuedUploads } = CreateProject;
    const { node } = this.props;
    const { showRequiredText } = this.state;
    const { autoRun } = node;
    if (autoRun) return null;
    const showBusy = isRegisteringAndCreatingProject && numberOfQueuedUploads > 0;
    return (
      <Wrapper>
        {showBusy && <div style={{ height: '40px', display: 'flex', alignItems: 'center' }}>
          <LoadingIndicator style={{ width: 16, height: 16, marginRight: '0.5rem' }} />
          <div>{`${numberOfQueuedUploads} upload(s) remaining`}</div>
        </div> || <div>
          <ActionButton onClick={this.onClick}>{(node.text && <RawHtml html={node.text} />) || (<span style={{ textDecoration: 'underline' }}>Submit</span>)}</ActionButton>
        </div>}
        {showRequiredText && <div style={{ color: 'red' }}>* Answer(s) required above</div>}
        <CreateChildren node={node} />
      </Wrapper>
    );
  }
}
