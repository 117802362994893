/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
/**
 * app.js
 *
 * This is the entry file for the application, only setup and boilerplate
 * code.
 */

// Import all the third party stuff
import React from 'react';
import ReactDOM from 'react-dom/client';

// Import root app
import Application from './applications/Application';
import App from './applications/App';


/* eslint-disable no-extend-native */
if (typeof String.prototype.replaceAll == "undefined") {
  String.prototype.replaceAll = function(match, replace) {
    return this.replace(new RegExp(match, 'g'), () => replace);
  }
}
if (!Object.values) {
  Object.values = function(obj) {
    return Object.keys(obj).map(function(key) {
      return obj[key];
    });
  };
}

const MOUNT_NODE = document.getElementById('root');
document.body.setAttribute('notranslate', true);
MOUNT_NODE.setAttribute('notranslate', true);
const render = () => {
  ReactDOM.createRoot(MOUNT_NODE).render(<Application><App /></Application>)
};

/**
 * Determine what to render
 */
render();
