/* eslint-disable react/no-danger */
import React from 'react';
import styled from 'styled-components';
import { PropTypes } from 'prop-types';

import Constants from '../../submodules/logictry_config/constants';
import GrayOut from '../../services/TreeDisplay';
import Answer from '../../models/answer';
import ForEachAnswer from '../../models/foreachanswer';
// eslint-disable-next-line import/no-cycle
import QuestionAnswerDropdownBox from './QuestionAnswerDropdownBox';
// eslint-disable-next-line import/no-cycle
import CreateChild from './CreateChild';
import InfoPopup from './InfoPopup';
import KeywordCount from './KeywordCount';
import Comment from '../../models/comment';
import { AngleDownIcon, AngleUpIcon } from '../../styledhtml/Icon';

const Wrapper = styled.div`

`;

const ShowMore = styled.div`
  display: flex;
  margin-left: 15px;
  align-items: center;
  width: 100%;
`;
const StaticList = styled.div``;
const StaticListWithFlexWrap = styled.div`
  margin-top: calc(${Constants.defaultLinePadding} + ${Constants.defaultLinePadding} - ${Constants.TreeContentIconIndent});
  > * {
    margin-top: calc(${Constants.TreeContentIconIndent} - ${Constants.defaultLinePadding} - ${Constants.defaultLinePadding});
  }
`;

export default class QuestionAnswersBox extends React.PureComponent {
  static propTypes = {
    question: PropTypes.object,
    showAddNewAnswer: PropTypes.bool,
  }
  state = {
    showAllAnswers: false,
    showFilteredAnswers: false,
  }
  componentDidMount() {
    const { question } = this.props;
    question.onStateUpdate(this);
    GrayOut.onStateUpdate(this);
  }
  componentWillUnmount() {
    const { question } = this.props;
    question.offStateUpdate(this);
    GrayOut.offStateUpdate(this);
  }
  onClose = () => this.setState({ showAllAnswers: false });
  toggleShowAllAnswers = () => {
    const { showAllAnswers } = this.state;
    this.setState({ showAllAnswers: !showAllAnswers });
  }
  toggleShowFilteredAnswers = () => {
    const { showFilteredAnswers } = this.state;
    this.setState({ showFilteredAnswers: !showFilteredAnswers });
  }
  render() {
    const { question, showAddNewAnswer } = this.props;
    const { maxListSize, hideShowAll, hideFilteredChoices, justifyContentCenter, dropdown, horizontal, wrap } = question;
    const { showAllAnswers, showFilteredAnswers } = this.state;
    const { editing } = GrayOut;

    const showDropdown = !editing && dropdown;

    let { answersToDisplay, answersToHide, filteredKeywordsFound, showMoreKeywordsFound } = question.getFilteredAnswers();

    // DropDownList
    if (showDropdown) return <QuestionAnswerDropdownBox question={question} answers={answersToDisplay} />;

    const answersToDisplayBeforeMaxSizeSlice = answersToDisplay.length;
    if (maxListSize && !showAllAnswers) {
      answersToDisplay.slice(maxListSize).forEach((_answer) => {
        if (_answer.keywordsFound && _answer.keywordsFound.size > 0) [..._answer.keywordsFound].forEach((keyword) => showMoreKeywordsFound.add(keyword));
      });
      answersToHide = [...answersToDisplay.slice(maxListSize), ...answersToHide];
      answersToDisplay = answersToDisplay.slice(0, maxListSize);
    }

    // RadioButtonList
    const showMoreButton = maxListSize && !hideShowAll && answersToDisplayBeforeMaxSizeSlice > maxListSize;
    const showFilteredButton = !hideFilteredChoices && answersToHide.length > 0 && (!showMoreButton || showAllAnswers);

    // Handle multiple columns
    const style = {};
    let StaticListToDisplay = StaticList;
    if (!editing) {
      if (horizontal) {
        style.display = 'flex';
      }
      if (wrap) {
        style.flexWrap = 'wrap';
        StaticListToDisplay = StaticListWithFlexWrap;
      }
      if (justifyContentCenter) style.justifyContent = 'center';
    }
    const { primaryColor } = GrayOut.tree.root;
    const iconStyle = { width: 12, height: 12, margin: 14, backgroundColor: primaryColor };
    return (
      <Wrapper>
        <StaticListToDisplay style={style}>
          {answersToDisplay.map((_node) => {
            if (editing && _node instanceof Comment) return <CreateChild key={_node.key} node={_node} />;
            if (_node instanceof Answer) return <CreateChild showAddNewAnswer={!!(showAddNewAnswer && _node.isUserAnswer)} key={_node.key} node={_node} />;
            if (editing && _node instanceof ForEachAnswer) return <CreateChild key={_node.key} node={_node} />;
            return null;
          })}
          {showMoreButton && <ShowMore style={{ justifyContent: style.justifyContent, color: primaryColor }} role="button" onClick={this.toggleShowAllAnswers}>
            {showAllAnswers ? <AngleUpIcon style={iconStyle} /> : <AngleDownIcon style={iconStyle} />}
            <div>{showAllAnswers ? 'Hide All' : 'Show All'}</div>
            {!editing && showMoreKeywordsFound && showMoreKeywordsFound.size > 0 && <InfoPopup text={`<h3 style="text-decoration: underline;">Keywords Found</h3><p>${([...showMoreKeywordsFound]).join(', ')}</p>`}><KeywordCount style={{ backgroundColor: primaryColor }}>{showMoreKeywordsFound.size}</KeywordCount></InfoPopup>}
          </ShowMore>}
          {showFilteredButton && <ShowMore style={{ justifyContent: style.justifyContent, color: primaryColor }} role="button" onClick={this.toggleShowFilteredAnswers}>
            {showFilteredAnswers ? <AngleUpIcon style={iconStyle} /> : <AngleDownIcon style={iconStyle} />}
            <div>{showFilteredAnswers ? 'Hide Filtered Choices' : 'Show Filtered Choices'}</div>
            {!editing && filteredKeywordsFound && filteredKeywordsFound.size > 0 && <InfoPopup text={`<h3 style="text-decoration: underline;">Keywords Found</h3><p>${([...filteredKeywordsFound]).join(', ')}</p>`}><KeywordCount style={{ backgroundColor: primaryColor }}>{filteredKeywordsFound.size}</KeywordCount></InfoPopup>}
          </ShowMore>}
          {showFilteredAnswers && answersToHide.map((_node) => {
            if (_node instanceof Answer) return <CreateChild showAddNewAnswer={!!(showAddNewAnswer && _node.isUserAnswer)} key={_node.key} node={_node} />;
            if (editing && _node instanceof ForEachAnswer) return <CreateChild key={_node.key} node={_node} />;
            return null;
          })}
        </StaticListToDisplay>
      </Wrapper>
    );
  }
}
