import React from 'react';
import styled from 'styled-components';
import { PropTypes } from 'prop-types';
import Constants from '../../submodules/logictry_config/constants';
import WindowSize from '../../services/WindowSize';
import Popup from '../Popup';
import ClickableDiv from '../ClickableDiv/index';
import RawHtml from '../RawHtml/index';
import Service from '../../services/Service';
import Scrollable from '../Scrollable/index';

const Wrapper = styled.div`
  
`;
const InfoText = styled.div`
  padding: 12px 24px;
  max-width: 400px;
  max-height: 600px;
  @media (max-width: 800px) {
    max-width: 80vw;
  }
  @media (max-height: 800px) {
    ${Constants.PopupMaxHeight}
  }
  overflow: hidden;
  display: flex;
`;

let activeTooltip;
const activeTooltips = new Service();

export default class Tooltip extends React.PureComponent {
  static propTypes = {
    text: PropTypes.string,
    children: PropTypes.any,
  }
  state = {
    showInfo: false,
  }
  componentDidMount() {
    WindowSize.onStateUpdate(this);
    activeTooltips.onStateUpdate(this.activeTooltipUpdated);
  }
  componentWillUnmount() {
    WindowSize.offStateUpdate(this);
    activeTooltips.offStateUpdate(this.activeTooltipUpdated);
    this.onMouseLeave();
  }
  activeTooltipUpdated = () => {
    if (activeTooltip === this) return;
    this.onClose();
  }
  onClose = () => {
    if (activeTooltip === this) activeTooltip = null;
    this.setState({ showInfo: false });
  }
  onClick = () => this.onMouseLeave();
  onMouseEnter = () => {
    if (this.timeout) return;
    this.timeout = setTimeout(() => {
      this.timeout = null;
      this.setState({ showInfo: true });
    }, activeTooltip ? 1000 : 1000);
    activeTooltip = this;
    activeTooltips.emitStateUpdate();
  }
  onMouseLeave = () => {
    if (!this.timeout) return;
    clearTimeout(this.timeout);
    this.timeout = null;
  }
  render() {
    const { mobile } = WindowSize;
    const { text, children } = this.props;
    const { showInfo } = this.state;
    return (
      <Wrapper>
        {showInfo && text && <Popup allowClickThrough anchorEl={!mobile && this.anchorEl} allowMoveClose onClose={this.onClose}>
          <InfoText>
            <Scrollable
              vertical
            >
              <RawHtml html={text} />
            </Scrollable>
          </InfoText>
        </Popup>}
        <ClickableDiv
          onMouseEnter={this.onMouseEnter}
          onMouseLeave={this.onMouseLeave}
          innerRef={(e) => { this.anchorEl = e; }}
          onClick={this.onClick}
        >
          {children}
        </ClickableDiv>
      </Wrapper>
    );
  }
}
